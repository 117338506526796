import { ErrorDetails } from './types';

export function translateError(
  grpcStatus: string,
  grpcMessage: string,
  requestId: string | null
): ErrorDetails {
  const errorStatus: { [key: string]: string } = {
    '0': 'OK',
    '1': 'CANCELLED',
    '2': 'UNKNOWN',
    '3': 'INVALID_ARGUMENT',
    '4': 'DEADLINE_EXCEEDED',
    '5': 'NOT_FOUND',
    '6': 'ALREADY_EXISTS',
    '7': 'PERMISSION_DENIED',
    '8': 'RESOURCE_EXHAUSTED',
    '9': 'FAILED_PRECONDITION',
    '10': 'ABORTED',
    '11': 'OUT_OF_RANGE',
    '12': 'UNIMPLEMENTED',
    '13': 'INTERNAL',
    '14': 'UNAVAILABLE',
    '15': 'DATA_LOSS',
    '16': 'UNAUTHENTICATED',
  };
  return {
    status: errorStatus[grpcStatus] || grpcStatus,
    message: grpcMessage,
    requestId: requestId,
  };
}
