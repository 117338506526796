export const Twilio = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5 5C10.944 5 4 11.72 4 20C4 28.28 10.944 35 19.5 35C28.056 35 35 28.28 35 20C35 11.72 28.056 5 19.5 5ZM19.5 31.0389C17.2438 31.0389 15.0383 30.3914 13.1623 29.1784C11.2864 27.9653 9.82424 26.2412 8.96085 24.2239C8.09746 22.2067 7.87159 19.987 8.3118 17.8456C8.752 15.7041 9.83851 13.7371 11.4339 12.1932C13.0293 10.6493 15.062 9.59795 17.2749 9.17205C19.4877 8.74616 21.7814 8.96485 23.8658 9.80048C25.9503 10.6361 27.7318 12.0512 28.9852 13.8667C30.2386 15.6822 30.9076 17.8166 30.9074 20C30.9115 21.4508 30.6191 22.8881 30.0473 24.2292C29.4754 25.5703 28.6353 26.7889 27.5752 27.8147C26.5152 28.8406 25.256 29.6536 23.8702 30.207C22.4844 30.7605 20.9992 31.0428 19.5 31.0389Z'
        fill='#EE3247'
      />
      <path
        d='M23 19C24.6569 19 26 17.6569 26 16C26 14.3431 24.6569 13 23 13C21.3431 13 20 14.3431 20 16C20 17.6569 21.3431 19 23 19Z'
        fill='#EE3247'
      />
      <path
        d='M23 27C24.6569 27 26 25.6569 26 24C26 22.3431 24.6569 21 23 21C21.3431 21 20 22.3431 20 24C20 25.6569 21.3431 27 23 27Z'
        fill='#EE3247'
      />
      <path
        d='M16 27C17.6569 27 19 25.6569 19 24C19 22.3431 17.6569 21 16 21C14.3431 21 13 22.3431 13 24C13 25.6569 14.3431 27 16 27Z'
        fill='#EE3247'
      />
      <path
        d='M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z'
        fill='#EE3247'
      />
    </svg>
  );
};
