import { FieldValues } from 'react-hook-form';
import { Flexbox } from '../Flexbox';
import { Input } from '../Input/Input';
import { InputFieldProps } from './types';
import { Text } from '../Typography';
import { memo } from 'react';
import { useTheme } from '@morf/theming';

const InputField = <T extends FieldValues>({
  id,
  label,
  placeholder,
  register,
  required,
  ...props
}: InputFieldProps<T>) => {
  const theme = useTheme();

  return (
    <Flexbox direction='column' gap={0.5} height='auto'>
      <Flexbox direction='row' justifyContent='flex-start' gap={0.25}>
        <Text tag='h5'>{label}</Text>
        {required && (
          <Text tag='h5' color={theme.colors.support.red.darkest}>
            *
          </Text>
        )}
      </Flexbox>

      <Input
        height='2.25rem'
        id={id}
        placeholder={placeholder}
        {...props}
        {...register(id, { required })}
      />
    </Flexbox>
  );
};

export const MemoizedInputField = memo(InputField) as typeof InputField;
