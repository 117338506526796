import { Container } from '../Container';
import { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { InputProps } from './types';
import { StyledInput } from './Input.css';
import { Text } from '../Typography';
import { useHover } from '../Hooks/useHover';
import { useTheme } from '@morf/theming';

export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoFocus,
      borderColor: initialBorderColor,
      borderType = 'border',
      children,
      clearable,
      disabled,
      error,
      height,
      id,
      leftElement,
      onChange,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      p = 0.5,
      placeholder,
      readOnly,
      rightElement,
      tag = 'p2',
      type = 'text',
      value,
      width,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const containerRef = useRef<HTMLDivElement>(null);
    const {
      isHovered,
      handleMouseEnter: onHoverStart,
      handleMouseLeave: onHoverEnd,
    } = useHover();

    const [inputValue, setInputValue] = useState(value);
    const [isFocused, setIsFocused] = useState<boolean>();
    const isEmpty = inputValue === '';

    let borderColor = theme.colors.ui.divider;

    if (error) {
      borderColor = theme.colors.support.red.darkest;
    } else if (isFocused && !readOnly) {
      borderColor = theme.colors.main.primary.darker;
    } else if (initialBorderColor) {
      borderColor = initialBorderColor;
    }

    const handleBlur = () => setIsFocused(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      if (onChange) {
        onChange(event);
      }
    };

    const handleClear = () => {
      setInputValue('');
      if (onChange) {
        onChange({
          target: { value: '' },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    };

    const handleFocus = () => setIsFocused(true);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown && onKeyDown(event);
      if (event.key === 'Enter') {
        setIsFocused(false);
      }
    };

    const handleMouseEnter = (event: React.MouseEvent<HTMLInputElement>) => {
      onHoverStart();
      onMouseEnter && onMouseEnter(event);
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLInputElement>) => {
      onHoverEnd();
      onMouseLeave && onMouseLeave(event);
    };

    const enterKeyElement = isFocused ? (
      <Text
        tag='p3'
        color={theme.colors.text.muted}
        whiteSpace='nowrap'
        wordWrap='normal'
      >
        Press Ent to save
      </Text>
    ) : isHovered ? (
      <Text
        tag='p3'
        color={theme.colors.main.primary.darker}
        whiteSpace='nowrap'
        wordWrap='normal'
      >
        Click to edit
      </Text>
    ) : null;

    return (
      <Flexbox
        containerRef={containerRef}
        justifyContent='flex-start'
        alignItems='center'
        borderType={borderType}
        backgroundColor={theme.colors.ui.card}
        borderRadius={theme.input.borderRadius}
        height={height}
        width={width}
        borderColor={borderColor}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        p={p}
        gap={0.25}
      >
        {leftElement && (
          <Flexbox width='auto' justifyContent='center' alignItems='center'>
            {leftElement}
          </Flexbox>
        )}

        <StyledInput
          id={id}
          data-testid={id}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
          readOnly={readOnly}
          ref={ref}
          tag={tag}
          type={type}
          value={inputValue}
          autoFocus={autoFocus}
          data-1p-ignore
          {...props}
        />

        {!isEmpty && clearable && !readOnly && (
          <Flexbox
            width='auto'
            justifyContent='center'
            alignItems='center'
            cursor='pointer'
          >
            <Icon
              name='close'
              cursor='pointer'
              size={1.25}
              stroke={theme.colors.text.muted}
              onClick={handleClear}
            />
          </Flexbox>
        )}

        {rightElement && (
          <Flexbox width='auto' justifyContent='center' alignItems='center'>
            {rightElement}
          </Flexbox>
        )}

        {onKeyDown && !readOnly && (
          <Container width='auto' height='auto'>
            {enterKeyElement}
          </Container>
        )}
      </Flexbox>
    );
  }
);
