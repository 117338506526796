'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { tiktok as tiktok$1 } from '../../tiktok_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from '../../workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.fetchactions = (() => {
  const fetchactions = {};
  fetchactions.tiktok = (() => {
    const tiktok = {};
    tiktok.v1 = (() => {
      const v1 = {};
      v1.FetchAction = (() => {
        class FetchAction {
          get type() {
            for (const key of ['sendEvent']) {
              if (this[key] !== null && this[key] !== undefined) return key;
            }
          }

          set type(name) {
            for (const key of ['sendEvent']) {
              if (key !== name) delete this[key];
            }
          }

          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new FetchAction(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.sendEvent != null &&
              Object.hasOwnProperty.call(message, 'sendEvent')
            ) {
              $root.fetchactions.tiktok.v1.SendEvent.encode(
                message.sendEvent,
                writer.uint32(10).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new FetchAction();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.sendEvent =
                    $root.fetchactions.tiktok.v1.SendEvent.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.tiktok.v1.FetchAction) {
              return object;
            }
            const message = new $root.fetchactions.tiktok.v1.FetchAction();
            if (object.sendEvent != null) {
              if (typeof object.sendEvent !== 'object') {
                throw new TypeError(
                  '.fetchactions.tiktok.v1.FetchAction.sendEvent: object expected, but got ' +
                    typeof object.sendEvent
                );
              }
              message.sendEvent =
                $root.fetchactions.tiktok.v1.SendEvent.fromObject(
                  object.sendEvent
                );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            if (
              message.sendEvent != null &&
              message.hasOwnProperty('sendEvent')
            ) {
              object.sendEvent =
                $root.fetchactions.tiktok.v1.SendEvent.toObject(
                  message.sendEvent,
                  options
                );
              if (options.oneofs) {
                object.type = 'sendEvent';
              }
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.tiktok.v1.FetchAction';
          }
        }

        FetchAction.prototype.sendEvent = null;

        return FetchAction;
      })();

      v1.SendEvent = (() => {
        class SendEvent {
          constructor(properties) {
            this.params = [];
            this.configs = [];
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new SendEvent(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.params != null &&
              Object.hasOwnProperty.call(message, 'params')
            ) {
              for (const element of message.params) {
                workflow_parameters$1.v1.DestinationActionParameter.encode(
                  element,
                  writer.uint32(10).fork()
                ).ldelim();
              }
            }
            if (
              message.configs != null &&
              Object.hasOwnProperty.call(message, 'configs')
            ) {
              for (const element of message.configs) {
                workflow_parameters$1.v1.DestinationActionParameterConfig.encode(
                  element,
                  writer.uint32(18).fork()
                ).ldelim();
              }
            }
            if (
              message.result != null &&
              Object.hasOwnProperty.call(message, 'result')
            ) {
              tiktok$1.v1.SendEventResponse.encode(
                message.result,
                writer.uint32(34).fork()
              ).ldelim();
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new SendEvent();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  if (!message.params || !message.params.length) {
                    message.params = [];
                  }
                  message.params.push(
                    workflow_parameters$1.v1.DestinationActionParameter.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 2: {
                  if (!message.configs || !message.configs.length) {
                    message.configs = [];
                  }
                  message.configs.push(
                    workflow_parameters$1.v1.DestinationActionParameterConfig.decode(
                      reader,
                      reader.uint32()
                    )
                  );
                  break;
                }
                case 4: {
                  message.result = tiktok$1.v1.SendEventResponse.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (object instanceof $root.fetchactions.tiktok.v1.SendEvent) {
              return object;
            }
            const message = new $root.fetchactions.tiktok.v1.SendEvent();
            if (object.params) {
              if (!Array.isArray(object.params)) {
                throw new TypeError(
                  '.fetchactions.tiktok.v1.SendEvent.params: array type expected, but got ' +
                    typeof object.params
                );
              }
              message.params = new Array(object.params.length);
              for (let i = 0; i < object.params.length; ++i) {
                if (typeof object.params[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.tiktok.v1.SendEvent.params: object expected, but got ' +
                      typeof object.params[i]
                  );
                }
                message.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.fromObject(
                    object.params[i]
                  );
              }
            }
            if (object.configs) {
              if (!Array.isArray(object.configs)) {
                throw new TypeError(
                  '.fetchactions.tiktok.v1.SendEvent.configs: array type expected, but got ' +
                    typeof object.configs
                );
              }
              message.configs = new Array(object.configs.length);
              for (let i = 0; i < object.configs.length; ++i) {
                if (typeof object.configs[i] !== 'object') {
                  throw new TypeError(
                    '.fetchactions.tiktok.v1.SendEvent.configs: object expected, but got ' +
                      typeof object.configs[i]
                  );
                }
                message.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.fromObject(
                    object.configs[i]
                  );
              }
            }
            if (object.result != null) {
              if (typeof object.result !== 'object') {
                throw new TypeError(
                  '.fetchactions.tiktok.v1.SendEvent.result: object expected, but got ' +
                    typeof object.result
                );
              }
              message.result = tiktok$1.v1.SendEventResponse.fromObject(
                object.result
              );
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
              object.params = [];
              object.configs = [];
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.result = null;
            }
            let keys;
            if (message.params && message.params.length) {
              object.params = new Array(message.params.length);
              for (let i = 0; i < message.params.length; ++i) {
                object.params[i] =
                  workflow_parameters$1.v1.DestinationActionParameter.toObject(
                    message.params[i],
                    options
                  );
              }
            }
            if (message.configs && message.configs.length) {
              object.configs = new Array(message.configs.length);
              for (let i = 0; i < message.configs.length; ++i) {
                object.configs[i] =
                  workflow_parameters$1.v1.DestinationActionParameterConfig.toObject(
                    message.configs[i],
                    options
                  );
              }
            }
            if (message.result != null && message.hasOwnProperty('result')) {
              object.result = tiktok$1.v1.SendEventResponse.toObject(
                message.result,
                options
              );
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return typeUrlPrefix + 'fetchactions.tiktok.v1.SendEvent';
          }
        }

        SendEvent.prototype.params = $util.emptyArray;
        SendEvent.prototype.configs = $util.emptyArray;
        SendEvent.prototype.result = null;

        return SendEvent;
      })();

      return v1;
    })();

    return tiktok;
  })();

  return fetchactions;
})();

export const fetchactions = $root.fetchactions;
