import { AddOptionProps } from './types';
import { Button } from '../../Button';
import { Container } from '../../Container';
import { FC } from 'react';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { Text } from '../../Typography';

export const AddOption: FC<AddOptionProps> = ({ value, onClick }) => {
  const trimmedValue = value.trim();

  const handleClick = () => {
    const newOption = {
      label: trimmedValue,
      value: trimmedValue,
    };

    onClick(newOption);
  };

  return (
    <Container borderType='borderTop' pt={0.5}>
      <Button
        variant='tertiary'
        onClick={handleClick}
        isDisabled={!trimmedValue}
        size='xs'
        width='100%'
      >
        <Flexbox
          direction='row'
          gap={0.5}
          justifyContent='center'
          alignItems='center'
        >
          <Icon name='plus' size={1.25} />
          <Text color='inherit' tag='h5'>
            {`Add ${trimmedValue}`}
          </Text>
        </Flexbox>
      </Button>
    </Container>
  );
};
