import { FC, memo } from 'react';
import { TextProps } from './types';
import { tagMap } from './Text.css';

const Text: FC<TextProps> = ({
  tag = 'p1',
  children,
  textRef,
  onClick,
  ...props
}) => {
  const Tag = tagMap[tag];
  return (
    <Tag ref={textRef} onClick={onClick} {...props}>
      {children}
    </Tag>
  );
};

export const MemoizedText = memo(Text);
