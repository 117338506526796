import { Container } from '../../../Container';
import { EventStatus } from '../EventStatus';
import { EventToolipProps } from './types';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { Icon } from '../../../Icon';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { Text } from '../../../Typography';
import { formatSourceApplicationIcon } from '../../../../../apps/admin/components/helpers';
import { useTheme } from '@morf/theming';
import {
  convertTimestampToMoment,
  defaultDateTimeFormatWithSeconds,
} from '../../../Filters';

const EventToolip: FC<EventToolipProps> = ({
  eventTime,
  eventType,
  sourceApplication,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='event-tooltip'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      width='21.25rem'
      gap={0.25}
      p={0.75}
    >
      {eventTime && (
        <Flexbox
          data-testid='event-timestamp'
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          gap={0.25}
        >
          <Icon
            name='clock'
            size={1}
            stroke={theme.colors.ui.dark}
            strokeWidth={1.75}
          />
          <Text tag='p3'>
            {convertTimestampToMoment(eventTime).format(
              defaultDateTimeFormatWithSeconds
            )}
          </Text>
        </Flexbox>
      )}

      <Flexbox
        data-testid='event-type'
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        height='auto'
        gap={0.25}
      >
        <Container width='auto' height='auto'>
          <IntegrationIcon
            name={formatSourceApplicationIcon(sourceApplication)}
            size={1}
          />
        </Container>
        <Text tag='p3'>{eventType}</Text>
      </Flexbox>

      <EventStatus variant='icon-description' />
    </Flexbox>
  );
};

export const MemoizedEventToolip = memo(EventToolip);
