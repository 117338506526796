import { ErrorDetails } from '@morf/server/types';
import { MutationCache } from '@tanstack/react-query';
import { Toast } from '@morf/ui';
import { toast } from 'react-toastify';

const toastId = 'toast-id';

export const mutationCache = new MutationCache({
  onMutate: () => {
    if (!toast.isActive(toastId)) {
      toast.loading(
        <Toast
          icon='arrow-path'
          message='Request in progress...'
          type='inactive'
        />,
        { toastId }
      );
    }
  },

  onError: (error) => {
    let errorDetails: ErrorDetails;

    try {
      if (!(error instanceof Error)) {
        throw error;
      }

      errorDetails = JSON.parse(error.message);
    } catch {
      errorDetails = {
        status: '500',
        message: 'Failed to parse error details',
        requestId: null,
      };
    }

    toast.update(toastId, {
      render: (
        <Toast
          errorDetails={errorDetails}
          icon='x-circle'
          message='Request failed!'
          type='alert'
        />
      ),
      isLoading: false,
    });
  },

  onSuccess: () => {
    toast.update(toastId, {
      render: (
        <Toast
          message='Request was successful!'
          icon='check-circle'
          type='success'
        />
      ),
      isLoading: false,
      autoClose: 5000,
    });
  },
});
