import _ from 'lodash';
import { FC, memo, useState } from 'react';
import { OrganizationMenuProps } from './types';
import { Text } from '@morf/ui/Typography';
import { useTheme } from '@morf/theming';
import {
  Button,
  Container,
  Divider,
  Flexbox,
  Input,
  Link,
  Menu,
} from '@morf/ui';

const OrganizationMenu: FC<OrganizationMenuProps> = ({
  organizationId,
  onClose,
  onSelectOrganization,
  organizationPermissions,
}) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState<string>('');

  const selectedOrganizationPermission = organizationPermissions.find(
    ({ organization }) => organization.id === organizationId
  );

  const otherOrganizationPermissions =
    organizationPermissions.filter(
      (o) => o !== selectedOrganizationPermission
    ) || [];

  const multipleOrganizations =
    otherOrganizationPermissions && !!otherOrganizationPermissions.length;

  const filteredOrganizations = _.filter(otherOrganizationPermissions, (o) =>
    _.some([o.organization.name, o.organization.id], (value) =>
      value.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const usersLink = `/organizations/${selectedOrganizationPermission?.organization?.id}/users`;

  const handleSelectOrganization = (id: string) => {
    onSelectOrganization(id);
    onClose();
  };

  return (
    <Menu position='absolute' top='0' left='calc(100% + 1.25rem)'>
      <Flexbox
        direction='column'
        alignItems='center'
        height='auto'
        data-testid='organization-menu'
        p={0.75}
      >
        <Flexbox direction='column' alignItems='center' gap={0} pt={0.75}>
          <Text tag='h5' weight='bold' align='center'>
            {selectedOrganizationPermission?.organization?.name}
          </Text>
          <Text tag='p2'>{selectedOrganizationPermission?.role.name}</Text>
        </Flexbox>

        <Flexbox justifyContent='center' alignItems='center'>
          <Link href={usersLink} width='100%'>
            <Button
              size='sm'
              variant='secondary'
              onClick={onClose}
              width='100%'
            >
              <Text
                tag='p3'
                weight='bold'
                color={theme.colors.main.primary.darker}
              >
                Manage Organization
              </Text>
            </Button>
          </Link>
        </Flexbox>

        {multipleOrganizations && (
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Divider>
              <Text tag='p3' color={theme.colors.text.muted}>
                Switch organization
              </Text>
            </Divider>

            {otherOrganizationPermissions.length > 3 && (
              <Input
                id='search-organizations'
                placeholder='Search'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                height='2.25rem'
                autoFocus
              />
            )}

            <Container maxHeight='12.5rem' overflowY='scroll'>
              {filteredOrganizations.map(({ organization }, index) => (
                <Container
                  key={index}
                  onClick={() => handleSelectOrganization(organization.id)}
                  hoverBackgroundColor={theme.colors.ui.body}
                  borderRadius={0.5}
                  cursor='pointer'
                  p={0.5}
                >
                  <Text tag='p2' align='left'>
                    {organization.name}
                  </Text>
                </Container>
              ))}
            </Container>
          </Flexbox>
        )}
      </Flexbox>
    </Menu>
  );
};

export const MemoizedOrganizationMenu = memo(OrganizationMenu);
