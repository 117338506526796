export const Tiktok = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.7618 11.4163C29.5593 11.3116 29.3621 11.1969 29.1711 11.0725C28.6157 10.7053 28.1064 10.2726 27.6543 9.78375C26.523 8.48938 26.1005 7.17625 25.9449 6.25688H25.9511C25.8211 5.49375 25.8749 5 25.883 5H20.7305V24.9237C20.7305 25.1912 20.7305 25.4556 20.7193 25.7169C20.7193 25.7494 20.7161 25.7794 20.7143 25.8144C20.7143 25.8288 20.7143 25.8438 20.7111 25.8587C20.7111 25.8625 20.7111 25.8663 20.7111 25.87C20.6568 26.5849 20.4277 27.2754 20.0438 27.8809C19.66 28.4864 19.1332 28.9884 18.5099 29.3425C17.8602 29.7121 17.1254 29.906 16.378 29.905C13.9774 29.905 12.0318 27.9475 12.0318 25.53C12.0318 23.1125 13.9774 21.155 16.378 21.155C16.8324 21.1546 17.2841 21.2261 17.7161 21.3669L17.7224 16.1206C16.4107 15.9512 15.0782 16.0554 13.8089 16.4268C12.5395 16.7981 11.3609 17.4285 10.3474 18.2781C9.4593 19.0498 8.71268 19.9704 8.14113 20.9988C7.92363 21.3738 7.10301 22.8806 7.00363 25.3263C6.94113 26.7144 7.35801 28.1525 7.55676 28.7469V28.7594C7.68176 29.1094 8.16613 30.3037 8.95551 31.3106C9.59203 32.1183 10.3441 32.8278 11.1874 33.4163V33.4037L11.1999 33.4163C13.6943 35.1112 16.4599 35 16.4599 35C16.9386 34.9806 18.5424 35 20.3636 34.1369C22.3836 33.18 23.5336 31.7544 23.5336 31.7544C24.2683 30.9025 24.8525 29.9318 25.2611 28.8838C25.7274 27.6581 25.883 26.1881 25.883 25.6006V15.0306C25.9455 15.0681 26.778 15.6187 26.778 15.6187C26.778 15.6187 27.9774 16.3875 29.8486 16.8881C31.1911 17.2444 32.9999 17.3194 32.9999 17.3194V12.2044C32.3661 12.2731 31.0793 12.0731 29.7618 11.4163Z'
        fill='black'
      />
    </svg>
  );
};
