import _ from 'lodash';
import { CSSProperties } from 'react';

export const getHighlightedText = (
  text: string,
  highlightWords: string[],
  style: CSSProperties
) => {
  if (!highlightWords.length) return text;

  const regex = new RegExp(`(${highlightWords.join('|')})`, 'gi');
  return text.split(regex).map((part, index) =>
    highlightWords.some((word) => _.toLower(word) === _.toLower(part)) ? (
      <span key={index} style={style}>
        {part}
      </span>
    ) : (
      part
    )
  );
};
