import _ from 'lodash';
import { ActionExecutionRowProps } from './types';
import { ActionExecutionStatus } from '../../ActionExecutionStatus';
import { ActionExecutionSubRow } from './ActionExecutionSubRow';
import { ActionExecutionTooltip } from './ActionExecutionTooltip';
import { Container } from '../../Container';
import { Flexbox } from '../../Flexbox';
import { Icon } from '../../Icon';
import { IntegrationIcon } from '../../IntegrationIcon';
import { Text } from '../../Typography';
import { WrapperModal } from '../../WrapperModal';
import { convertTimestampToMoment } from '../../Filters';
import { convertToKebabCase } from '../../Helpers/convertToKebabCase';
import { defaultDateTimeFormatWithSeconds } from '../../Filters/TimeFilter/constants';
import { formatSourceApplicationIcon } from '../../../../apps/admin/components/helpers';
import { iconNameNodeTypeMap } from '../../Workflow/CustomNode/constants';
import { useHover } from '../../Hooks/useHover';
import { useTheme } from '@morf/theming';
import { useUpdateUrlParam } from '../../Hooks/useUpdateUrlParam';
import { workflow_destination_applications } from '@morf/proto/workflow_destination_applications_v1_ts_proto';
import {
  FC,
  MouseEvent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

const ActionExecutionRow: FC<ActionExecutionRowProps> = ({
  application,
  attempts,
  isRowSelected,
  name,
  nodeId,
  status,
  workflowExecutionId,
}) => {
  const theme = useTheme();
  const updateUrlParam = useUpdateUrlParam();

  const containerRef = useRef<HTMLDivElement>(null);
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();

  const [isExpanded, setIsExpanded] = useState(isRowSelected);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const latestAttempt = _.last(attempts);

  const backgroundColor = isHovered
    ? theme.colors.ui.body
    : theme.colors.ui.card;

  const borderColor = isRowSelected
    ? theme.colors.main.primary.darker
    : theme.colors.ui.card;

  const carrotIcon = isExpanded ? 'carrot-down' : 'carrot-right';

  const hasMultipleAttempts = attempts.length > 1;
  const hasApplication = !!application;
  const hasExpectedExecutionEnd = latestAttempt?.expectedExecutionEnd;
  const hasFilterOutcome = latestAttempt?.filterOutcome !== undefined;

  const nameTag = isRowSelected ? 'h5' : 'p2';

  const formattedTimestamp =
    latestAttempt &&
    latestAttempt.executionStart &&
    convertTimestampToMoment(latestAttempt.executionStart).format(
      defaultDateTimeFormatWithSeconds
    );

  const handleRowClick = () => {
    setIsExpanded(true);
    updateUrlParam({
      workflowExecutionId: workflowExecutionId,
      nodeId: nodeId,
      attemptNumber: _.toString(attempts.length),
    });
  };

  const handleCarrotIconClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!isExpanded) {
      handleRowClick();
    } else {
      setIsExpanded(false);
    }
  };

  const handleShowTooltip = useCallback(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const top =
        Math.max(1, Math.min(rect.top / 16, window.innerHeight / 16 - 10)) +
        'rem';
      const left = rect.right / 16 + 0.25 + 'rem';
      setTooltipPosition({ top, left });
      setShowTooltip(true);
    }
  }, []);

  const handleCloseTooltip = () => setShowTooltip(false);

  useEffect(() => {
    if (isHovered) {
      const timeoutId = setTimeout(handleShowTooltip, 500);
      return () => clearTimeout(timeoutId);
    } else {
      handleCloseTooltip();
    }
  }, [isHovered, handleShowTooltip]);

  useEffect(() => {
    setIsExpanded(isRowSelected);
  }, [isRowSelected]);

  return (
    <>
      <Flexbox
        key={nodeId}
        containerRef={containerRef}
        data-testid='action-execution-row'
        direction='column'
        justifyContent='flex-start'
        alignItems='flex-start'
        height='auto'
        gap={0}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        borderRadius={0.25}
        borderType='border'
        cursor='pointer'
        width='16.5rem'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleRowClick}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
          height='auto'
          p={0.5}
          gap={0.25}
        >
          <Container width='auto' height='auto'>
            {latestAttempt &&
              nodeId === 'trigger-node' &&
              latestAttempt.workflowExecutionSummary && (
                <IntegrationIcon
                  name={formatSourceApplicationIcon(
                    latestAttempt.workflowExecutionSummary.applicationType
                  )}
                  size={1.25}
                />
              )}

            {latestAttempt &&
              nodeId === 'profile-lookup-node' &&
              iconNameNodeTypeMap.profileLookupNode && (
                <Icon
                  name={iconNameNodeTypeMap.profileLookupNode}
                  stroke={theme.colors.ui.dark}
                  size={1.25}
                />
              )}

            {latestAttempt &&
              hasFilterOutcome &&
              iconNameNodeTypeMap.filterNode && (
                <Icon
                  name={iconNameNodeTypeMap.filterNode}
                  stroke={theme.colors.ui.dark}
                  size={1.25}
                />
              )}

            {latestAttempt &&
              hasExpectedExecutionEnd &&
              iconNameNodeTypeMap.pauseNode && (
                <Icon
                  name={iconNameNodeTypeMap.pauseNode}
                  stroke={theme.colors.ui.dark}
                  size={1.25}
                />
              )}

            {latestAttempt &&
              !hasFilterOutcome &&
              !hasExpectedExecutionEnd &&
              hasApplication && (
                <IntegrationIcon
                  name={convertToKebabCase(
                    workflow_destination_applications.v1.DestinationApplication
                      .Application[application]
                  )}
                  size={1.25}
                />
              )}
          </Container>

          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            height='auto'
            gap={0}
          >
            <Container width='11.5rem' height='auto'>
              <Text tag={nameTag} whiteSpace='nowrap'>
                {name}
              </Text>
            </Container>

            <Text tag='p3' color={theme.colors.text.muted} whiteSpace='nowrap'>
              {formattedTimestamp}
            </Text>
          </Flexbox>
          <ActionExecutionStatus
            variant='icon'
            status={status}
            expectedExecutionEnd={latestAttempt?.expectedExecutionEnd}
            error={latestAttempt?.error}
            retryNumber={latestAttempt?.attemptNumber}
          />

          <Flexbox justifyContent='center' alignItems='center'>
            {hasMultipleAttempts && (
              <Container
                height='auto'
                width='auto'
                onClick={handleCarrotIconClick}
              >
                <Icon
                  name={carrotIcon}
                  size={0.75}
                  fill={theme.colors.ui.dark}
                  stroke='none'
                />
              </Container>
            )}
          </Flexbox>
        </Flexbox>

        {hasMultipleAttempts && isExpanded && (
          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            onMouseEnter={handleMouseLeave}
            gap={0}
          >
            {attempts.map((a, i) => (
              <ActionExecutionSubRow
                key={i}
                {...a}
                isLastSubRow={i === attempts.length - 1}
              />
            ))}
          </Flexbox>
        )}
      </Flexbox>

      {showTooltip && latestAttempt && (
        <WrapperModal
          {...tooltipPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'default',
            height: 'auto',
            width: 'auto',
          }}
          backgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          p={0.25}
        >
          <ActionExecutionTooltip {...latestAttempt} />
        </WrapperModal>
      )}
    </>
  );
};

export const MemoizedActionExecutionRow = memo(ActionExecutionRow);
