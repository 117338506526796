import { Container } from '../../../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../../../Flexbox';
import { IntegrationIcon } from '../../../IntegrationIcon';
import { Text } from '../../../Typography';
import { WorkflowExecutionTooltipProps } from './types';
import { formatSourceApplicationIcon } from '../../../../../apps/admin/components/helpers';

const WorkflowExecutionTooltipName: FC<
  Pick<WorkflowExecutionTooltipProps, 'applicationType' | 'workflowName'>
> = ({ applicationType, workflowName }) => (
  <Flexbox
    data-testid='workflow-execution-name'
    direction='row'
    justifyContent='flex-start'
    alignItems='center'
    height='auto'
    gap={0.25}
  >
    <Container height='auto' width='auto'>
      <IntegrationIcon
        name={formatSourceApplicationIcon(applicationType)}
        size={1}
      />
    </Container>
    <Text tag='p3'>{workflowName}</Text>
  </Flexbox>
);

export const MemoizedWorkflowExecutionTooltipName = memo(
  WorkflowExecutionTooltipName
);
