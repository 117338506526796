export const validateIsWithinTimeRange = (
  value: string,
  isMorfInternalUser: boolean
): boolean | string => {
  if (isMorfInternalUser) {
    return true;
  }

  const parsedTimestamp = new Date(value).getTime();
  const now = Date.now();
  const thirtyDaysAgo = now - 30 * 24 * 60 * 60 * 1000;

  if (parsedTimestamp < thirtyDaysAgo) {
    return 'Specify range within the last 30 days';
  }

  return true;
};
