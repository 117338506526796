import { Container } from '../../Container';
import { FC, memo } from 'react';
import { FilterMenuOptionProps } from './types';
import { Text } from '../../Typography';
import { Tooltip } from '../../Tooltip';
import { useTheme } from '@morf/theming';

const FilterMenuOption: FC<FilterMenuOptionProps> = ({
  onClick,
  filterMenuOption,
}) => {
  const theme = useTheme();
  const { disabledTooltipText, isDisabled, label } = filterMenuOption;
  const cursor = isDisabled ? 'not-allowed' : 'pointer';

  const handleClick = () => {
    if (!isDisabled) {
      onClick(filterMenuOption);
    }
  };

  return (
    <Container
      key={label}
      borderRadius={0.25}
      cursor={cursor}
      hoverBackgroundColor={theme.colors.main.light.light}
      onClick={handleClick}
      p={0.25}
    >
      {isDisabled ? (
        <Tooltip tooltipText={disabledTooltipText} tooltipDirection='right'>
          <Text tag='p3' color={theme.colors.text.muted}>
            {label}
          </Text>
        </Tooltip>
      ) : (
        <Text tag='p3'>{label}</Text>
      )}
    </Container>
  );
};

export const MemoizedFilterMenuOption = memo(FilterMenuOption);
