import _ from 'lodash';
import { Container } from '../Container';
import { FC, memo, useRef, useState } from 'react';
import { FilterMenuOption, FilterMenuOptionType } from './FilterMenuOption';
import { FilterMenuProps } from './types';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { Tooltip } from '../Tooltip';
import { WrapperModal } from '../WrapperModal';
import { useTheme } from '@morf/theming';

const FilterMenu: FC<FilterMenuProps> = ({ filterMenuOptions, onClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const [showFilterMenuDropdown, setShowFilterMenuDropdown] =
    useState<boolean>(false);
  const [filterMenuPosition, setFilterMenuPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const hasFilterOptions = !_.isEmpty(filterMenuOptions);
  const tooltipText = hasFilterOptions ? 'Filter' : 'All filters are in use';
  const cursor = hasFilterOptions ? 'pointer' : 'default';
  const stroke = showFilterMenuDropdown
    ? theme.colors.main.primary.darker
    : theme.colors.ui.dark;

  const handleCloseFilterMenuDropdown = () => setShowFilterMenuDropdown(false);

  const handleOptionClick = (filterOption: FilterMenuOptionType) => {
    handleCloseFilterMenuDropdown();
    onClick(filterOption);
  };

  const handleShowFilterMenuDropdown = () => {
    if (hasFilterOptions) {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        setFilterMenuPosition({
          top: rect.top / 16 + 1.75 + 'rem',
          left: rect.left / 16 + 'rem',
        });
      }
      setShowFilterMenuDropdown(true);
    }
  };

  return (
    <Flexbox containerRef={containerRef} width='auto' height='auto'>
      <Tooltip
        tooltipText={tooltipText}
        tooltipDirection='bottom'
        arrowDirection='left'
      >
        <Container
          hoverBackgroundColor={theme.colors.main.light.light}
          borderRadius={0.25}
          width='auto'
          height='auto'
        >
          <Icon
            cursor={cursor}
            name='filter-lines'
            onClick={handleShowFilterMenuDropdown}
            size={1.25}
            stroke={stroke}
            strokeWidth={2}
          />
        </Container>
      </Tooltip>

      {showFilterMenuDropdown && (
        <WrapperModal
          {...filterMenuPosition}
          backdrop={{
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          width='7.5rem'
          onClose={handleCloseFilterMenuDropdown}
        >
          <Flexbox
            data-testid='filter-dropdown'
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            p={0.5}
            gap={0.5}
          >
            <Text tag='p3' color={theme.colors.text.muted}>
              Filter by:
            </Text>

            <Flexbox
              direction='column'
              justifyContent='flex-start'
              alignItems='flex-start'
              height='auto'
              gap={0}
            >
              {filterMenuOptions.map((filterMenuOption) => (
                <FilterMenuOption
                  filterMenuOption={filterMenuOption}
                  onClick={handleOptionClick}
                />
              ))}
            </Flexbox>
          </Flexbox>
        </WrapperModal>
      )}
    </Flexbox>
  );
};

export const MemoizedFilterMenu = memo(FilterMenu);
