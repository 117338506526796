import { EmptyTableBody } from './TableBody/EmptyTableBody';
import { ExtendedRowData, TableProps } from './types';
import { StyledTable } from './Table.css';
import { TableBody } from './TableBody/TableBody';
import { TableHead } from './TableHead/TableHead';
import { memo, useEffect, useMemo, useState } from 'react';
import {
  ColumnFiltersState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';

const TableContainer: <RowData>(
  props: TableProps<ExtendedRowData<RowData>>
) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  columns,
  data,
  emptyText,
  fetchNextPage,
  hasNextPage = false,
  isFetching = false,
  isLoading = false,
  onColumnClick,
  onRowAdd,
  onRowClick,
  onRowHover,
  onRowRemove,
  onSubRowAdd,
  onSubRowClick,
  onSubRowRemove,
  parentCellHeight,
  selectedRow,
  subCellHeight,
  subRowComponent,
}) => {
  let initialRowSelection: { [key: number]: boolean } = {};
  if (selectedRow !== undefined && selectedRow !== null) {
    initialRowSelection[selectedRow] = true;
  }

  const memoizedDataWithPlaceholder = useMemo(
    () => (isLoading ? Array(20).fill({}) : data),
    [isLoading, data]
  );

  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const { getHeaderGroups, getRowModel, resetRowSelection } = useReactTable({
    data: memoizedDataWithPlaceholder,
    columns,
    state: {
      columnFilters,
      rowSelection,
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.subRows,
    enableSubRowSelection: !!onSubRowClick,
    enableRowSelection: !!onRowClick,
    enableMultiRowSelection: false,
    enableExpanding: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      size: 200,
      minSize: 80,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  useEffect(() => {
    if (selectedRow === null) {
      resetRowSelection();
    }
  }, [selectedRow]);

  const headerGroups = getHeaderGroups();
  const rows = getRowModel().rows;

  return (
    <StyledTable data-testid='table' height={!rows.length ? '100%' : 'auto'}>
      <TableHead headerGroups={headerGroups} onRowAdd={onRowAdd} />
      {!rows.length ? (
        <EmptyTableBody numberOfColumns={columns.length} text={emptyText} />
      ) : (
        <TableBody
          cellBackgroundColor={cellBackgroundColor}
          cellHeight={cellHeight}
          cellHoveredBackgroundColor={cellHoveredBackgroundColor}
          cellHoveredBorderColor={cellHoveredBorderColor}
          cellSelectedBackgroundColor={cellSelectedBackgroundColor}
          cellSelectedBorderColor={cellSelectedBorderColor}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isLoading={isLoading}
          numberOfColumns={columns.length}
          onColumnClick={onColumnClick}
          onRowClick={onRowClick}
          onRowHover={onRowHover}
          onRowRemove={onRowRemove}
          onSubRowAdd={onSubRowAdd}
          onSubRowClick={onSubRowClick}
          onSubRowRemove={onSubRowRemove}
          parentCellHeight={parentCellHeight}
          rows={rows}
          subCellHeight={subCellHeight}
          subRowComponent={subRowComponent}
        />
      )}
    </StyledTable>
  );
};

export const Table = memo(TableContainer) as typeof TableContainer;
