import { Checklist } from '../Checklist';
import { Container } from '../Container';
import { FC } from 'react';
import { FlexItem, Flexbox } from '../Flexbox';
import { Image } from '../Image';
import { ProviderCardDetailsProps } from './types';
import { ProviderTooltip } from '../ProviderTooltip/ProviderTooltip';
import { Text } from '../Typography';
import { useRouter } from 'next/router';
import { useTheme } from '@morf/theming';

export const ProviderCardDetails: FC<ProviderCardDetailsProps> = ({
  id,
  name,
  imageUrl,
  shortBio,
  specialties,
  qualifications,
  detailedQualifications,
  to,
  onClick,
}) => {
  const theme = useTheme();
  const router = useRouter();

  const handleClick = () => {
    onClick && onClick();
    router.push(to);
  };

  return (
    <Flexbox direction='column' data-testid={`provider-card-${id}`} px={1.25}>
      <Image
        src={imageUrl}
        alt={name}
        width='5rem'
        height='5rem'
        radius='full'
      />
      <Flexbox direction='column' gap={0.25} zIndex={0}>
        <Text tag='h4'>{name}</Text>
        <ProviderTooltip
          tooltipDirection='right'
          qualifications={qualifications}
          detailedQualifications={detailedQualifications}
        />
      </Flexbox>

      {!!specialties.length && theme.contentOrder && (
        <FlexItem flexOrder={theme.contentOrder.specialties}>
          <Flexbox direction='column' gap={0.25}>
            <Text tag='h5'>Specialties</Text>
            <Checklist list={specialties} />
          </Flexbox>
        </FlexItem>
      )}
      {theme.contentOrder && (
        <FlexItem flexOrder={theme.contentOrder.bio}>
          <Flexbox direction='column' gap={0.25}>
            <Text tag='h5'>About</Text>
            <Text tag='p2'>{shortBio}</Text>
          </Flexbox>
        </FlexItem>
      )}
      <FlexItem flexOrder={3}>
        <Container
          onClick={handleClick}
          width='auto'
          height='auto'
          cursor='pointer'
        >
          <Text tag='link' color={theme.colors.main.primary.darker}>
            View Profile
          </Text>
        </Container>
      </FlexItem>
    </Flexbox>
  );
};
