import { NodeType } from '../types';
import { PauseNodeProps } from './types';
import { workflow_parameters } from '@morf/proto/workflow_parameters_v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const mockPause = new workflows.v1.WorkflowPause({
  timestamps: {
    sources: [
      new workflow_parameters.v1.DestinationActionParameterSource({
        eventPayloadFieldLookup:
          new workflow_parameters.v1.EventPayloadFieldLookup({
            celExpressionStr: 'created_at.add(duration("5m"))',
          }),
      }),
    ],
  },
  refreshEventPayload: true,
});

export const pauseNode: PauseNodeProps = {
  id: 'ed419936-842a-4479-87a2-19f6b70e3924',
  type: NodeType.PauseNode,
  data: {
    value: {
      configuration: mockPause,
      name: 'After 5 min',
      title: 'Wait until...',
    },
  },
  position: { x: 0, y: 0 },
};
