import { FieldValues } from 'react-hook-form';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { TextareaFieldProps } from './types';
import { memo } from 'react';
import { useTheme } from '@morf/theming';
import { Textarea } from '../Textarea';

const TextareaField = <T extends FieldValues>({
  id,
  label,
  placeholder,
  register,
  required,
  ...props
}: TextareaFieldProps<T>) => {
  const theme = useTheme();

  return (
    <Flexbox direction='column' gap={0.5} height='auto'>
      <Flexbox direction='row' justifyContent='flex-start' gap={0.25}>
        <Text tag='h5'>{label}</Text>
        {required && (
          <Text tag='h5' color={theme.colors.support.red.darkest}>
            *
          </Text>
        )}
      </Flexbox>

      <Textarea
        id={id}
        placeholder={placeholder}
        {...props}
        {...register(id, { required })}
      />
    </Flexbox>
  );
};

export const MemoizedTextareaField = memo(
  TextareaField
) as typeof TextareaField;
