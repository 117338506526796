export const Calendly = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.5629 24.4538C25.593 25.2988 24.3828 26.3505 22.1822 26.3505H20.8699C19.2792 26.3505 17.8329 25.7838 16.7979 24.7551C15.7868 23.7505 15.23 22.3752 15.23 20.8823V19.1178C15.23 17.6248 15.7868 16.2495 16.7979 15.2449C17.8329 14.2162 19.2792 13.6495 20.8699 13.6495H22.1822C24.3828 13.6495 25.5918 14.7012 26.5629 15.5462C27.5699 16.4226 28.44 17.1791 30.7576 17.1791C31.1112 17.1792 31.4641 17.1514 31.8132 17.0962C31.8132 17.0895 31.8086 17.0833 31.8057 17.0766C31.6668 16.7382 31.5038 16.4098 31.3179 16.0938L29.7683 13.4591C29.0696 12.2715 28.0648 11.2853 26.8547 10.5996C25.6447 9.91387 24.2721 9.55281 22.8748 9.55267H19.775C18.3778 9.55281 17.0052 9.91387 15.7951 10.5996C14.5851 11.2853 13.5803 12.2715 12.8816 13.4591L11.3319 16.0938C10.6334 17.2815 10.2656 18.6287 10.2656 20C10.2656 21.3713 10.6334 22.7185 11.3319 23.9062L12.8816 26.5409C13.5803 27.7284 14.5851 28.7146 15.7952 29.4002C17.0052 30.0858 18.3778 30.4468 19.775 30.4468H22.8748C24.2721 30.4468 25.6446 30.0858 26.8547 29.4002C28.0647 28.7146 29.0696 27.7284 29.7683 26.5409L31.3179 23.9062C31.5038 23.5902 31.6668 23.2618 31.8057 22.9234C31.8057 22.9167 31.8109 22.9105 31.8132 22.9038C31.4641 22.8486 31.1112 22.8208 30.7576 22.8209C28.44 22.8209 27.5699 23.5775 26.5629 24.4538Z'
        fill='#006BFF'
      />
      <path
        d='M22.1822 15.2477H20.8699C18.4496 15.2477 16.8589 16.9444 16.8589 19.1166V20.8811C16.8589 23.0533 18.4496 24.75 20.8699 24.75H22.1822C25.7093 24.75 25.4343 21.2221 30.7576 21.2221C31.2623 21.2217 31.7659 21.2669 32.2622 21.3571C32.4236 20.4595 32.4236 19.541 32.2622 18.6434C31.766 18.7341 31.2623 18.7794 30.7576 18.7789C25.4326 18.7784 25.7093 15.2477 22.1822 15.2477Z'
        fill='#006BFF'
      />
      <path
        d='M35.3214 22.6468C34.414 21.9958 33.368 21.5555 32.2621 21.3588C32.2621 21.3678 32.2592 21.3767 32.2575 21.3851C32.1626 21.9056 32.0137 22.4153 31.813 22.906C32.7267 23.0448 33.5937 23.3949 34.3423 23.9274C34.3423 23.9353 34.3378 23.9431 34.3349 23.9515C33.9106 25.3038 33.2694 26.5811 32.4355 27.7353C31.6111 28.8789 30.6102 29.8893 29.4686 30.7301C26.7053 32.7704 23.256 33.7056 19.8174 33.3469C16.3788 32.9881 13.207 31.3622 10.9424 28.7973C8.6778 26.2324 7.48918 22.9197 7.61654 19.528C7.74389 16.1364 9.17773 12.9186 11.6285 10.5242C13.2935 8.89136 15.36 7.7084 17.6279 7.09004C19.8957 6.47168 22.2878 6.4389 24.5724 6.99489C26.8569 7.55088 28.9563 8.67676 30.667 10.2634C32.3778 11.85 33.6418 13.8435 34.3366 16.0507C34.3395 16.0591 34.3417 16.067 34.344 16.0748C33.5949 16.6074 32.7273 16.9573 31.813 17.0956C32.0136 17.5869 32.1627 18.0968 32.2581 18.6177C32.2581 18.6261 32.2581 18.6345 32.2621 18.6423C33.3681 18.4462 34.4143 18.0057 35.3214 17.3544C36.1938 16.721 36.0249 16.0054 35.8919 15.582C33.9697 9.45299 28.1564 5 21.2829 5C12.8427 5 6 11.7159 6 19.9997C6 28.2836 12.8427 35 21.2829 35C28.1564 35 33.9697 30.547 35.8891 24.4197C36.0249 23.9963 36.1938 23.2807 35.3214 22.6468Z'
        fill='#006BFF'
      />
      <path
        d='M31.8132 17.0956C31.4641 17.1509 31.1112 17.1786 30.7576 17.1785C28.44 17.1785 27.5699 16.422 26.5629 15.5456C25.593 14.7006 24.3828 13.6489 22.1822 13.6489H20.8699C19.2792 13.6489 17.8329 14.2156 16.7979 15.2443C15.7868 16.2489 15.23 17.6242 15.23 19.1172V20.8817C15.23 22.3746 15.7868 23.7499 16.7979 24.7545C17.8329 25.7832 19.2792 26.3499 20.8699 26.3499H22.1822C24.3828 26.3499 25.5918 25.2983 26.5629 24.4533C27.5699 23.5769 28.44 22.8203 30.7576 22.8203C31.1112 22.8203 31.4641 22.848 31.8132 22.9032C32.0138 22.4124 32.1627 21.9028 32.2576 21.3823C32.2576 21.3739 32.261 21.3649 32.2622 21.356C31.7659 21.2658 31.2623 21.2206 30.7576 21.221C25.4326 21.221 25.7094 24.7489 22.1822 24.7489H20.8699C18.4496 24.7489 16.8589 23.0522 16.8589 20.88V19.1177C16.8589 16.9455 18.4496 15.2488 20.8699 15.2488H22.1822C25.7094 15.2488 25.4344 18.7767 30.7576 18.7767C31.2623 18.7772 31.766 18.7318 32.2622 18.6412C32.2622 18.6333 32.2622 18.6249 32.2582 18.6165C32.1627 18.0961 32.0136 17.5865 31.8132 17.0956Z'
        fill='#0AE9EF'
      />
      <path
        d='M31.8132 17.0956C31.4641 17.1509 31.1112 17.1786 30.7576 17.1785C28.44 17.1785 27.5699 16.422 26.5629 15.5456C25.593 14.7006 24.3828 13.6489 22.1822 13.6489H20.8699C19.2792 13.6489 17.8329 14.2156 16.7979 15.2443C15.7868 16.2489 15.23 17.6242 15.23 19.1172V20.8817C15.23 22.3746 15.7868 23.7499 16.7979 24.7545C17.8329 25.7832 19.2792 26.3499 20.8699 26.3499H22.1822C24.3828 26.3499 25.5918 25.2983 26.5629 24.4533C27.5699 23.5769 28.44 22.8203 30.7576 22.8203C31.1112 22.8203 31.4641 22.848 31.8132 22.9032C32.0138 22.4124 32.1627 21.9028 32.2576 21.3823C32.2576 21.3739 32.261 21.3649 32.2622 21.356C31.7659 21.2658 31.2623 21.2206 30.7576 21.221C25.4326 21.221 25.7094 24.7489 22.1822 24.7489H20.8699C18.4496 24.7489 16.8589 23.0522 16.8589 20.88V19.1177C16.8589 16.9455 18.4496 15.2488 20.8699 15.2488H22.1822C25.7094 15.2488 25.4344 18.7767 30.7576 18.7767C31.2623 18.7772 31.766 18.7318 32.2622 18.6412C32.2622 18.6333 32.2622 18.6249 32.2582 18.6165C32.1627 18.0961 32.0136 17.5865 31.8132 17.0956Z'
        fill='#0AE9EF'
      />
    </svg>
  );
};
