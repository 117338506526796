import { convertToTitleCase } from '@morf/ui';
import { source_application } from '@morf/proto/source_application_v2_ts_proto';
import { usePermissions } from '../context/permissions/usePermissions';

export const formatSourceApplication = (
  applicationType: source_application.v2.SourceApplication.Application
): string => {
  if (
    applicationType ===
    source_application.v2.SourceApplication.Application.CUSTOMER
  ) {
    const { organizationPermission } = usePermissions();
    return organizationPermission?.organization?.name || 'Customer';
  }

  return convertToTitleCase(
    source_application.v2.SourceApplication.Application[applicationType]
  );
};
