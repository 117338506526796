import Cookies from 'js-cookie';
import { MORF_ORG_ID } from '@morf/constants';
import { useRouter } from 'next/router';

const getQueryParam = (param: string): string | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (!value || typeof value !== 'string') {
    return undefined;
  }

  return value;
};

const getQueryParamArray = (param: string): string[] | undefined => {
  const router = useRouter();
  const value = router.query[param];

  if (Array.isArray(value)) {
    return value;
  }

  if (value) {
    return [value];
  }

  return undefined;
};

export const useUrlParams = (): {
  attemptNumber?: string;
  classes?: string[];
  errorDetailLike?: string;
  eventId?: string;
  eventTypes?: string[];
  from?: string;
  geographicLocale?: string;
  insurancePayer?: string;
  internalTags?: string[];
  issueErrorHash?: string;
  issueErrorType?: string;
  nodeId?: string;
  organizationId?: string;
  panelNodeId?: string;
  payloadTypes?: string[];
  providerId?: string;
  providerIdType?: string;
  providerName?: string;
  showCELPlayground?: string;
  sources?: string[];
  specialties?: string[];
  states?: string[];
  stytch_token_type?: string;
  tab?: string;
  thirdPartyId?: string;
  thirdPartyType?: string;
  time?: string;
  token?: string;
  workflowExecutionId?: string;
  workflowId?: string;
  workflowIds?: string[];
} => {
  const attemptNumber = getQueryParam('attemptNumber');
  const classes = getQueryParamArray('classes');
  const errorDetailLike = getQueryParam('errorDetailLike');
  const eventId = getQueryParam('eventId');
  const eventTypes = getQueryParamArray('eventTypes');
  const from = getQueryParam('from');
  const geographicLocale = getQueryParam('geographicLocale');
  const insurancePayer = getQueryParam('insurancePayer');
  const internalTags = getQueryParamArray('internalTags');
  const issueErrorHash = getQueryParam('issueErrorHash');
  const issueErrorType = getQueryParam('issueErrorType');
  const nodeId = getQueryParam('nodeId');
  const organizationId = getQueryParam('organizationId');
  const panelNodeId = getQueryParam('panelNodeId');
  const payloadTypes = getQueryParamArray('payloadTypes');
  const providerId = getQueryParam('providerId');
  const providerIdType = getQueryParam('providerIdType');
  const providerName = getQueryParam('providerName');
  const showCELPlayground = getQueryParam('showCELPlayground');
  const sources = getQueryParamArray('sources');
  const specialties = getQueryParamArray('specialties');
  const states = getQueryParamArray('states');
  const stytch_token_type = getQueryParam('stytch_token_type');
  const tab = getQueryParam('tab');
  const thirdPartyId = getQueryParam('thirdPartyId');
  const thirdPartyType = getQueryParam('thirdPartyType');
  const time = getQueryParam('time');
  const token = getQueryParam('token');
  const workflowExecutionId = getQueryParam('workflowExecutionId');
  const workflowId = getQueryParam('workflowId');
  const workflowIds = getQueryParamArray('workflowIds');

  if (organizationId && organizationId !== Cookies.get(MORF_ORG_ID)) {
    Cookies.set(MORF_ORG_ID, organizationId);
  }

  return {
    attemptNumber,
    classes,
    errorDetailLike,
    eventId,
    eventTypes,
    from,
    geographicLocale,
    insurancePayer,
    internalTags,
    issueErrorHash,
    issueErrorType,
    nodeId,
    organizationId,
    panelNodeId,
    payloadTypes,
    providerId,
    providerIdType,
    providerName,
    showCELPlayground,
    sources,
    specialties,
    states,
    stytch_token_type,
    tab,
    thirdPartyId,
    thirdPartyType,
    time,
    token,
    workflowExecutionId,
    workflowId,
    workflowIds,
  };
};
