'use strict';

import Long from 'long';
import * as $protobuf from 'protobufjs/minimal';
import { google as google$1 } from './struct_ts_proto';
import { thirdparties as thirdparties$1 } from './thirdparties_v1_ts_proto';
import { timing as timing$1 } from './timing_v1_ts_proto';
import { workflow_destination_applications as workflow_destination_applications$1 } from './workflow_destination_applications_v1_ts_proto';
import { workflow_extra_fetched_data as workflow_extra_fetched_data$1 } from './workflow_extra_fetched_data_v1_ts_proto';
import { workflow_parameters as workflow_parameters$1 } from './workflow_parameters_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.workflow_evaluation_results = (() => {
  const workflow_evaluation_results = {};
  workflow_evaluation_results.v1 = (() => {
    const v1 = {};
    v1.WorkflowKnownError = (() => {
      class WorkflowKnownError {
        get type() {
          for (const key of [
            'destinationActionParametersError',
            'thirdPartyIdMissingError',
            'profileIndexConflictError',
            'noActiveWorkflowError',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set type(name) {
          for (const key of [
            'destinationActionParametersError',
            'thirdPartyIdMissingError',
            'profileIndexConflictError',
            'noActiveWorkflowError',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowKnownError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.outcome != null &&
            Object.hasOwnProperty.call(message, 'outcome')
          ) {
            writer.uint32(8).int32(message.outcome);
          }
          if (
            message.destinationActionParametersError != null &&
            Object.hasOwnProperty.call(
              message,
              'destinationActionParametersError'
            )
          ) {
            $root.workflow_evaluation_results.v1.DestinationActionParametersError.encode(
              message.destinationActionParametersError,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.thirdPartyIdMissingError != null &&
            Object.hasOwnProperty.call(message, 'thirdPartyIdMissingError')
          ) {
            $root.workflow_evaluation_results.v1.ThirdPartyIdMissingError.encode(
              message.thirdPartyIdMissingError,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.profileIndexConflictError != null &&
            Object.hasOwnProperty.call(message, 'profileIndexConflictError')
          ) {
            writer.uint32(34).string(message.profileIndexConflictError);
          }
          if (
            message.noActiveWorkflowError != null &&
            Object.hasOwnProperty.call(message, 'noActiveWorkflowError')
          ) {
            $root.workflow_evaluation_results.v1.NoActiveWorkflowError.encode(
              message.noActiveWorkflowError,
              writer.uint32(42).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowKnownError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.outcome = reader.int32();
                break;
              }
              case 2: {
                message.destinationActionParametersError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.thirdPartyIdMissingError =
                  $root.workflow_evaluation_results.v1.ThirdPartyIdMissingError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.profileIndexConflictError = reader.string();
                break;
              }
              case 5: {
                message.noActiveWorkflowError =
                  $root.workflow_evaluation_results.v1.NoActiveWorkflowError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.WorkflowKnownError
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.WorkflowKnownError();
          if (object.outcome != null) {
            switch (object.outcome) {
              case 'UNDEFINED_OUTCOME':
              case 0: {
                message.outcome = 0;
                break;
              }
              case 'TERMINATE_WORKFLOW':
              case 1: {
                message.outcome = 1;
                break;
              }
              case 'TERMINATE_BRANCH':
              case 2: {
                message.outcome = 2;
                break;
              }
              case 'CANCEL_ACTIVITY':
              case 3: {
                message.outcome = 3;
                break;
              }
              case 'RETRY_ACTIVITY':
              case 4: {
                message.outcome = 4;
                break;
              }
              case 'WARN_ACTIVITY':
              case 5: {
                message.outcome = 5;
                break;
              }
              default: {
                if (typeof object.outcome == 'number') {
                  message.outcome = object.outcome;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationActionParametersError != null) {
            if (typeof object.destinationActionParametersError !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowKnownError.destinationActionParametersError: object expected, but got ' +
                  typeof object.destinationActionParametersError
              );
            }
            message.destinationActionParametersError =
              $root.workflow_evaluation_results.v1.DestinationActionParametersError.fromObject(
                object.destinationActionParametersError
              );
          }
          if (object.thirdPartyIdMissingError != null) {
            if (typeof object.thirdPartyIdMissingError !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowKnownError.thirdPartyIdMissingError: object expected, but got ' +
                  typeof object.thirdPartyIdMissingError
              );
            }
            message.thirdPartyIdMissingError =
              $root.workflow_evaluation_results.v1.ThirdPartyIdMissingError.fromObject(
                object.thirdPartyIdMissingError
              );
          }
          if (object.profileIndexConflictError != null) {
            message.profileIndexConflictError = String(
              object.profileIndexConflictError
            );
          }
          if (object.noActiveWorkflowError != null) {
            if (typeof object.noActiveWorkflowError !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowKnownError.noActiveWorkflowError: object expected, but got ' +
                  typeof object.noActiveWorkflowError
              );
            }
            message.noActiveWorkflowError =
              $root.workflow_evaluation_results.v1.NoActiveWorkflowError.fromObject(
                object.noActiveWorkflowError
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.outcome = options.enums === String ? 'UNDEFINED_OUTCOME' : 0;
          }
          let keys;
          if (message.outcome != null && message.hasOwnProperty('outcome')) {
            object.outcome =
              options.enums === String
                ? $root.workflow_evaluation_results.v1.WorkflowKnownError
                    .Outcome[message.outcome] === undefined
                  ? message.outcome
                  : $root.workflow_evaluation_results.v1.WorkflowKnownError
                      .Outcome[message.outcome]
                : message.outcome;
          }
          if (
            message.destinationActionParametersError != null &&
            message.hasOwnProperty('destinationActionParametersError')
          ) {
            object.destinationActionParametersError =
              $root.workflow_evaluation_results.v1.DestinationActionParametersError.toObject(
                message.destinationActionParametersError,
                options
              );
            if (options.oneofs) {
              object.type = 'destinationActionParametersError';
            }
          }
          if (
            message.thirdPartyIdMissingError != null &&
            message.hasOwnProperty('thirdPartyIdMissingError')
          ) {
            object.thirdPartyIdMissingError =
              $root.workflow_evaluation_results.v1.ThirdPartyIdMissingError.toObject(
                message.thirdPartyIdMissingError,
                options
              );
            if (options.oneofs) {
              object.type = 'thirdPartyIdMissingError';
            }
          }
          if (
            message.profileIndexConflictError != null &&
            message.hasOwnProperty('profileIndexConflictError')
          ) {
            object.profileIndexConflictError =
              message.profileIndexConflictError;
            if (options.oneofs) {
              object.type = 'profileIndexConflictError';
            }
          }
          if (
            message.noActiveWorkflowError != null &&
            message.hasOwnProperty('noActiveWorkflowError')
          ) {
            object.noActiveWorkflowError =
              $root.workflow_evaluation_results.v1.NoActiveWorkflowError.toObject(
                message.noActiveWorkflowError,
                options
              );
            if (options.oneofs) {
              object.type = 'noActiveWorkflowError';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflow_evaluation_results.v1.WorkflowKnownError'
          );
        }
      }

      WorkflowKnownError.prototype.outcome = 0;
      WorkflowKnownError.prototype.destinationActionParametersError = null;
      WorkflowKnownError.prototype.thirdPartyIdMissingError = null;
      WorkflowKnownError.prototype.profileIndexConflictError = '';
      WorkflowKnownError.prototype.noActiveWorkflowError = null;
      WorkflowKnownError.Outcome = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNDEFINED_OUTCOME')] = 0;
        values[(valuesById[1] = 'TERMINATE_WORKFLOW')] = 1;
        values[(valuesById[2] = 'TERMINATE_BRANCH')] = 2;
        values[(valuesById[3] = 'CANCEL_ACTIVITY')] = 3;
        values[(valuesById[4] = 'RETRY_ACTIVITY')] = 4;
        values[(valuesById[5] = 'WARN_ACTIVITY')] = 5;
        return values;
      })();

      return WorkflowKnownError;
    })();

    v1.NoActiveWorkflowError = (() => {
      class NoActiveWorkflowError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new NoActiveWorkflowError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.workflowId != null &&
            Object.hasOwnProperty.call(message, 'workflowId')
          ) {
            writer.uint32(10).string(message.workflowId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new NoActiveWorkflowError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.workflowId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.NoActiveWorkflowError
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.NoActiveWorkflowError();
          if (object.workflowId != null) {
            message.workflowId = String(object.workflowId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.workflowId = '';
          }
          let keys;
          if (
            message.workflowId != null &&
            message.hasOwnProperty('workflowId')
          ) {
            object.workflowId = message.workflowId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflow_evaluation_results.v1.NoActiveWorkflowError'
          );
        }
      }

      NoActiveWorkflowError.prototype.workflowId = '';

      return NoActiveWorkflowError;
    })();

    v1.ThirdPartyIdMissingError = (() => {
      class ThirdPartyIdMissingError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ThirdPartyIdMissingError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.idType != null &&
            Object.hasOwnProperty.call(message, 'idType')
          ) {
            writer.uint32(8).int32(message.idType);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ThirdPartyIdMissingError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.idType = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.ThirdPartyIdMissingError
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.ThirdPartyIdMissingError();
          if (object.idType != null) {
            switch (object.idType) {
              case 'UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION':
              case 0: {
                message.idType = 0;
                break;
              }
              case 'CUSTOMER':
              case 1: {
                message.idType = 1;
                break;
              }
              case 'FORMSORT':
              case 2: {
                message.idType = 2;
                break;
              }
              case 'HEALTHIE':
              case 3: {
                message.idType = 3;
                break;
              }
              case 'AXLE_HEALTH':
              case 4: {
                message.idType = 4;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 5: {
                message.idType = 5;
                break;
              }
              case 'RECURLY':
              case 6: {
                message.idType = 6;
                break;
              }
              case 'INTERCOM':
              case 7: {
                message.idType = 7;
                break;
              }
              case 'SANA_BENEFITS':
              case 8: {
                message.idType = 8;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 9: {
                message.idType = 9;
                break;
              }
              case 'VITAL':
              case 10: {
                message.idType = 10;
                break;
              }
              case 'SEGMENT':
              case 11: {
                message.idType = 11;
                break;
              }
              case 'INTAKEQ':
              case 12: {
                message.idType = 12;
                break;
              }
              case 'CUSTOMER_IO':
              case 13: {
                message.idType = 13;
                break;
              }
              case 'FRESHDESK':
              case 14: {
                message.idType = 14;
                break;
              }
              case 'HUBSPOT':
              case 15: {
                message.idType = 15;
                break;
              }
              default: {
                if (typeof object.idType == 'number') {
                  message.idType = object.idType;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.idType =
              options.enums === String
                ? 'UNSPECIFIED_THIRD_PARTY_PROFILE_APPLICATION'
                : 0;
          }
          let keys;
          if (message.idType != null && message.hasOwnProperty('idType')) {
            object.idType =
              options.enums === String
                ? thirdparties$1.v1.ThirdPartyIdType[message.idType] ===
                  undefined
                  ? message.idType
                  : thirdparties$1.v1.ThirdPartyIdType[message.idType]
                : message.idType;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflow_evaluation_results.v1.ThirdPartyIdMissingError'
          );
        }
      }

      ThirdPartyIdMissingError.prototype.idType = 0;

      return ThirdPartyIdMissingError;
    })();

    v1.DestinationActionParametersError = (() => {
      class DestinationActionParametersError {
        constructor(properties) {
          this.errors = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionParametersError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errors != null &&
            Object.hasOwnProperty.call(message, 'errors')
          ) {
            for (const element of message.errors) {
              $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.encode(
                element,
                writer.uint32(10).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionParametersError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.errors || !message.errors.length) {
                  message.errors = [];
                }
                message.errors.push(
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1
              .DestinationActionParametersError
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.DestinationActionParametersError();
          if (object.errors) {
            if (!Array.isArray(object.errors)) {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionParametersError.errors: array type expected, but got ' +
                  typeof object.errors
              );
            }
            message.errors = new Array(object.errors.length);
            for (let i = 0; i < object.errors.length; ++i) {
              if (typeof object.errors[i] !== 'object') {
                throw new TypeError(
                  '.workflow_evaluation_results.v1.DestinationActionParametersError.errors: object expected, but got ' +
                    typeof object.errors[i]
                );
              }
              message.errors[i] =
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.fromObject(
                  object.errors[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.errors = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.errors && message.errors.length) {
            object.errors = new Array(message.errors.length);
            for (let i = 0; i < message.errors.length; ++i) {
              object.errors[i] =
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.toObject(
                  message.errors[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflow_evaluation_results.v1.DestinationActionParametersError'
          );
        }
      }

      DestinationActionParametersError.prototype.errors = $util.emptyArray;
      DestinationActionParametersError.DestinationActionParameterError =
        (() => {
          class DestinationActionParameterError {
            get type() {
              for (const key of [
                'parameterMissingError',
                'parameterConfigurationMissingError',
                'parameterCastError',
                'parameterUnknownError',
                'parameterParsingError',
              ]) {
                if (this[key] !== null && this[key] !== undefined) return key;
              }
            }

            set type(name) {
              for (const key of [
                'parameterMissingError',
                'parameterConfigurationMissingError',
                'parameterCastError',
                'parameterUnknownError',
                'parameterParsingError',
              ]) {
                if (key !== name) delete this[key];
              }
            }

            constructor(properties) {
              if (properties) {
                for (let key of Object.keys(properties)) {
                  if (properties[key] != null) this[key] = properties[key];
                }
              }
            }

            static create(properties) {
              return new DestinationActionParameterError(properties);
            }

            static encode(message, writer) {
              if (!writer) writer = $Writer.create();
              if (
                message.isRequired != null &&
                Object.hasOwnProperty.call(message, 'isRequired')
              ) {
                writer.uint32(8).bool(message.isRequired);
              }
              if (
                message.parameterName != null &&
                Object.hasOwnProperty.call(message, 'parameterName')
              ) {
                writer.uint32(18).string(message.parameterName);
              }
              if (
                message.parameterMissingError != null &&
                Object.hasOwnProperty.call(message, 'parameterMissingError')
              ) {
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError.encode(
                  message.parameterMissingError,
                  writer.uint32(26).fork()
                ).ldelim();
              }
              if (
                message.parameterConfigurationMissingError != null &&
                Object.hasOwnProperty.call(
                  message,
                  'parameterConfigurationMissingError'
                )
              ) {
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterConfigurationMissingError.encode(
                  message.parameterConfigurationMissingError,
                  writer.uint32(34).fork()
                ).ldelim();
              }
              if (
                message.parameterCastError != null &&
                Object.hasOwnProperty.call(message, 'parameterCastError')
              ) {
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError.encode(
                  message.parameterCastError,
                  writer.uint32(42).fork()
                ).ldelim();
              }
              if (
                message.parameterUnknownError != null &&
                Object.hasOwnProperty.call(message, 'parameterUnknownError')
              ) {
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError.encode(
                  message.parameterUnknownError,
                  writer.uint32(50).fork()
                ).ldelim();
              }
              if (
                message.parameterParsingError != null &&
                Object.hasOwnProperty.call(message, 'parameterParsingError')
              ) {
                $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError.encode(
                  message.parameterParsingError,
                  writer.uint32(58).fork()
                ).ldelim();
              }
              return writer;
            }

            static decode(reader, length) {
              if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
              let end = length === undefined ? reader.len : reader.pos + length;
              let message = new DestinationActionParameterError();
              let key, value;
              while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                  case 1: {
                    message.isRequired = reader.bool();
                    break;
                  }
                  case 2: {
                    message.parameterName = reader.string();
                    break;
                  }
                  case 3: {
                    message.parameterMissingError =
                      $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 4: {
                    message.parameterConfigurationMissingError =
                      $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterConfigurationMissingError.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 5: {
                    message.parameterCastError =
                      $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 6: {
                    message.parameterUnknownError =
                      $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  case 7: {
                    message.parameterParsingError =
                      $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError.decode(
                        reader,
                        reader.uint32()
                      );
                    break;
                  }
                  default: {
                    reader.skipType(tag & 7);
                    break;
                  }
                }
              }
              return message;
            }

            static fromObject(object) {
              if (
                object instanceof
                $root.workflow_evaluation_results.v1
                  .DestinationActionParametersError
                  .DestinationActionParameterError
              ) {
                return object;
              }
              const message =
                new $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError();
              if (object.isRequired != null) {
                message.isRequired = Boolean(object.isRequired);
              }
              if (object.parameterName != null) {
                message.parameterName = String(object.parameterName);
              }
              if (object.parameterMissingError != null) {
                if (typeof object.parameterMissingError !== 'object') {
                  throw new TypeError(
                    '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.parameterMissingError: object expected, but got ' +
                      typeof object.parameterMissingError
                  );
                }
                message.parameterMissingError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError.fromObject(
                    object.parameterMissingError
                  );
              }
              if (object.parameterConfigurationMissingError != null) {
                if (
                  typeof object.parameterConfigurationMissingError !== 'object'
                ) {
                  throw new TypeError(
                    '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.parameterConfigurationMissingError: object expected, but got ' +
                      typeof object.parameterConfigurationMissingError
                  );
                }
                message.parameterConfigurationMissingError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterConfigurationMissingError.fromObject(
                    object.parameterConfigurationMissingError
                  );
              }
              if (object.parameterCastError != null) {
                if (typeof object.parameterCastError !== 'object') {
                  throw new TypeError(
                    '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.parameterCastError: object expected, but got ' +
                      typeof object.parameterCastError
                  );
                }
                message.parameterCastError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError.fromObject(
                    object.parameterCastError
                  );
              }
              if (object.parameterUnknownError != null) {
                if (typeof object.parameterUnknownError !== 'object') {
                  throw new TypeError(
                    '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.parameterUnknownError: object expected, but got ' +
                      typeof object.parameterUnknownError
                  );
                }
                message.parameterUnknownError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError.fromObject(
                    object.parameterUnknownError
                  );
              }
              if (object.parameterParsingError != null) {
                if (typeof object.parameterParsingError !== 'object') {
                  throw new TypeError(
                    '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.parameterParsingError: object expected, but got ' +
                      typeof object.parameterParsingError
                  );
                }
                message.parameterParsingError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError.fromObject(
                    object.parameterParsingError
                  );
              }
              return message;
            }

            static toObject(message, options = {}) {
              const object = {};
              if (options.arrays || options.defaults) {
              }
              if (options.objects || options.defaults) {
              }
              if (options.defaults) {
                object.isRequired = false;
                object.parameterName = '';
              }
              let keys;
              if (
                message.isRequired != null &&
                message.hasOwnProperty('isRequired')
              ) {
                object.isRequired = message.isRequired;
              }
              if (
                message.parameterName != null &&
                message.hasOwnProperty('parameterName')
              ) {
                object.parameterName = message.parameterName;
              }
              if (
                message.parameterMissingError != null &&
                message.hasOwnProperty('parameterMissingError')
              ) {
                object.parameterMissingError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError.toObject(
                    message.parameterMissingError,
                    options
                  );
                if (options.oneofs) {
                  object.type = 'parameterMissingError';
                }
              }
              if (
                message.parameterConfigurationMissingError != null &&
                message.hasOwnProperty('parameterConfigurationMissingError')
              ) {
                object.parameterConfigurationMissingError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterConfigurationMissingError.toObject(
                    message.parameterConfigurationMissingError,
                    options
                  );
                if (options.oneofs) {
                  object.type = 'parameterConfigurationMissingError';
                }
              }
              if (
                message.parameterCastError != null &&
                message.hasOwnProperty('parameterCastError')
              ) {
                object.parameterCastError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError.toObject(
                    message.parameterCastError,
                    options
                  );
                if (options.oneofs) {
                  object.type = 'parameterCastError';
                }
              }
              if (
                message.parameterUnknownError != null &&
                message.hasOwnProperty('parameterUnknownError')
              ) {
                object.parameterUnknownError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError.toObject(
                    message.parameterUnknownError,
                    options
                  );
                if (options.oneofs) {
                  object.type = 'parameterUnknownError';
                }
              }
              if (
                message.parameterParsingError != null &&
                message.hasOwnProperty('parameterParsingError')
              ) {
                object.parameterParsingError =
                  $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError.toObject(
                    message.parameterParsingError,
                    options
                  );
                if (options.oneofs) {
                  object.type = 'parameterParsingError';
                }
              }
              return object;
            }

            static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
              return (
                typeUrlPrefix +
                'workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError'
              );
            }
          }

          DestinationActionParameterError.prototype.isRequired = false;
          DestinationActionParameterError.prototype.parameterName = '';
          DestinationActionParameterError.prototype.parameterMissingError =
            null;
          DestinationActionParameterError.prototype.parameterConfigurationMissingError =
            null;
          DestinationActionParameterError.prototype.parameterCastError = null;
          DestinationActionParameterError.prototype.parameterUnknownError =
            null;
          DestinationActionParameterError.prototype.parameterParsingError =
            null;
          DestinationActionParameterError.ParameterConfigurationMissingError =
            (() => {
              class ParameterConfigurationMissingError {
                constructor(properties) {
                  if (properties) {
                    for (let key of Object.keys(properties)) {
                      if (properties[key] != null) this[key] = properties[key];
                    }
                  }
                }

                static create(properties) {
                  return new ParameterConfigurationMissingError(properties);
                }

                static encode(message, writer) {
                  if (!writer) writer = $Writer.create();
                  return writer;
                }

                static decode(reader, length) {
                  if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                  let end =
                    length === undefined ? reader.len : reader.pos + length;
                  let message = new ParameterConfigurationMissingError();
                  let key, value;
                  while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                      default: {
                        reader.skipType(tag & 7);
                        break;
                      }
                    }
                  }
                  return message;
                }

                static fromObject(object) {
                  if (
                    object instanceof
                    $root.workflow_evaluation_results.v1
                      .DestinationActionParametersError
                      .DestinationActionParameterError
                      .ParameterConfigurationMissingError
                  ) {
                    return object;
                  }
                  const message =
                    new $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterConfigurationMissingError();
                  return message;
                }

                static toObject(message, options = {}) {
                  const object = {};
                  if (options.arrays || options.defaults) {
                  }
                  if (options.objects || options.defaults) {
                  }
                  if (options.defaults) {
                  }
                  let keys;
                  return object;
                }

                static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                  return (
                    typeUrlPrefix +
                    'workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterConfigurationMissingError'
                  );
                }
              }

              return ParameterConfigurationMissingError;
            })();

          DestinationActionParameterError.ParameterMissingError = (() => {
            class ParameterMissingError {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new ParameterMissingError(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.source != null &&
                  Object.hasOwnProperty.call(message, 'source')
                ) {
                  workflow_parameters$1.v1.DestinationActionParameterSource.encode(
                    message.source,
                    writer.uint32(10).fork()
                  ).ldelim();
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new ParameterMissingError();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.source =
                        workflow_parameters$1.v1.DestinationActionParameterSource.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.workflow_evaluation_results.v1
                    .DestinationActionParametersError
                    .DestinationActionParameterError.ParameterMissingError
                ) {
                  return object;
                }
                const message =
                  new $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError();
                if (object.source != null) {
                  if (typeof object.source !== 'object') {
                    throw new TypeError(
                      '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError.source: object expected, but got ' +
                        typeof object.source
                    );
                  }
                  message.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(
                      object.source
                    );
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.source = null;
                }
                let keys;
                if (
                  message.source != null &&
                  message.hasOwnProperty('source')
                ) {
                  object.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.toObject(
                      message.source,
                      options
                    );
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix +
                  'workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterMissingError'
                );
              }
            }

            ParameterMissingError.prototype.source = null;

            return ParameterMissingError;
          })();

          DestinationActionParameterError.ParameterCastError = (() => {
            class ParameterCastError {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new ParameterCastError(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.targetType != null &&
                  Object.hasOwnProperty.call(message, 'targetType')
                ) {
                  writer.uint32(10).string(message.targetType);
                }
                if (
                  message.source != null &&
                  Object.hasOwnProperty.call(message, 'source')
                ) {
                  workflow_parameters$1.v1.DestinationActionParameterSource.encode(
                    message.source,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                if (
                  message.sourceType != null &&
                  Object.hasOwnProperty.call(message, 'sourceType')
                ) {
                  writer.uint32(26).string(message.sourceType);
                }
                if (
                  message.details != null &&
                  Object.hasOwnProperty.call(message, 'details')
                ) {
                  writer.uint32(34).string(message.details);
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new ParameterCastError();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.targetType = reader.string();
                      break;
                    }
                    case 2: {
                      message.source =
                        workflow_parameters$1.v1.DestinationActionParameterSource.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 3: {
                      message.sourceType = reader.string();
                      break;
                    }
                    case 4: {
                      message.details = reader.string();
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.workflow_evaluation_results.v1
                    .DestinationActionParametersError
                    .DestinationActionParameterError.ParameterCastError
                ) {
                  return object;
                }
                const message =
                  new $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError();
                if (object.targetType != null) {
                  message.targetType = String(object.targetType);
                }
                if (object.source != null) {
                  if (typeof object.source !== 'object') {
                    throw new TypeError(
                      '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError.source: object expected, but got ' +
                        typeof object.source
                    );
                  }
                  message.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(
                      object.source
                    );
                }
                if (object.sourceType != null) {
                  message.sourceType = String(object.sourceType);
                }
                if (object.details != null) {
                  message.details = String(object.details);
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.targetType = '';
                  object.source = null;
                  object.sourceType = '';
                }
                let keys;
                if (
                  message.targetType != null &&
                  message.hasOwnProperty('targetType')
                ) {
                  object.targetType = message.targetType;
                }
                if (
                  message.source != null &&
                  message.hasOwnProperty('source')
                ) {
                  object.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.toObject(
                      message.source,
                      options
                    );
                }
                if (
                  message.sourceType != null &&
                  message.hasOwnProperty('sourceType')
                ) {
                  object.sourceType = message.sourceType;
                }
                if (
                  message.details != null &&
                  message.hasOwnProperty('details')
                ) {
                  object.details = message.details;
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix +
                  'workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterCastError'
                );
              }
            }

            ParameterCastError.prototype.targetType = '';
            ParameterCastError.prototype.source = null;
            ParameterCastError.prototype.sourceType = '';
            ParameterCastError.prototype.details = null;

            return ParameterCastError;
          })();

          DestinationActionParameterError.ParameterParsingError = (() => {
            class ParameterParsingError {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new ParameterParsingError(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.targetType != null &&
                  Object.hasOwnProperty.call(message, 'targetType')
                ) {
                  writer.uint32(10).string(message.targetType);
                }
                if (
                  message.source != null &&
                  Object.hasOwnProperty.call(message, 'source')
                ) {
                  workflow_parameters$1.v1.DestinationActionParameterSource.encode(
                    message.source,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                if (
                  message.error != null &&
                  Object.hasOwnProperty.call(message, 'error')
                ) {
                  writer.uint32(26).string(message.error);
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new ParameterParsingError();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.targetType = reader.string();
                      break;
                    }
                    case 2: {
                      message.source =
                        workflow_parameters$1.v1.DestinationActionParameterSource.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    case 3: {
                      message.error = reader.string();
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.workflow_evaluation_results.v1
                    .DestinationActionParametersError
                    .DestinationActionParameterError.ParameterParsingError
                ) {
                  return object;
                }
                const message =
                  new $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError();
                if (object.targetType != null) {
                  message.targetType = String(object.targetType);
                }
                if (object.source != null) {
                  if (typeof object.source !== 'object') {
                    throw new TypeError(
                      '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError.source: object expected, but got ' +
                        typeof object.source
                    );
                  }
                  message.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(
                      object.source
                    );
                }
                if (object.error != null) {
                  message.error = String(object.error);
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.targetType = '';
                  object.source = null;
                  object.error = '';
                }
                let keys;
                if (
                  message.targetType != null &&
                  message.hasOwnProperty('targetType')
                ) {
                  object.targetType = message.targetType;
                }
                if (
                  message.source != null &&
                  message.hasOwnProperty('source')
                ) {
                  object.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.toObject(
                      message.source,
                      options
                    );
                }
                if (message.error != null && message.hasOwnProperty('error')) {
                  object.error = message.error;
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix +
                  'workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterParsingError'
                );
              }
            }

            ParameterParsingError.prototype.targetType = '';
            ParameterParsingError.prototype.source = null;
            ParameterParsingError.prototype.error = '';

            return ParameterParsingError;
          })();

          DestinationActionParameterError.ParameterUnknownError = (() => {
            class ParameterUnknownError {
              constructor(properties) {
                if (properties) {
                  for (let key of Object.keys(properties)) {
                    if (properties[key] != null) this[key] = properties[key];
                  }
                }
              }

              static create(properties) {
                return new ParameterUnknownError(properties);
              }

              static encode(message, writer) {
                if (!writer) writer = $Writer.create();
                if (
                  message.error != null &&
                  Object.hasOwnProperty.call(message, 'error')
                ) {
                  writer.uint32(10).string(message.error);
                }
                if (
                  message.source != null &&
                  Object.hasOwnProperty.call(message, 'source')
                ) {
                  workflow_parameters$1.v1.DestinationActionParameterSource.encode(
                    message.source,
                    writer.uint32(18).fork()
                  ).ldelim();
                }
                return writer;
              }

              static decode(reader, length) {
                if (!(reader instanceof $Reader))
                  reader = $Reader.create(reader);
                let end =
                  length === undefined ? reader.len : reader.pos + length;
                let message = new ParameterUnknownError();
                let key, value;
                while (reader.pos < end) {
                  let tag = reader.uint32();
                  switch (tag >>> 3) {
                    case 1: {
                      message.error = reader.string();
                      break;
                    }
                    case 2: {
                      message.source =
                        workflow_parameters$1.v1.DestinationActionParameterSource.decode(
                          reader,
                          reader.uint32()
                        );
                      break;
                    }
                    default: {
                      reader.skipType(tag & 7);
                      break;
                    }
                  }
                }
                return message;
              }

              static fromObject(object) {
                if (
                  object instanceof
                  $root.workflow_evaluation_results.v1
                    .DestinationActionParametersError
                    .DestinationActionParameterError.ParameterUnknownError
                ) {
                  return object;
                }
                const message =
                  new $root.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError();
                if (object.error != null) {
                  message.error = String(object.error);
                }
                if (object.source != null) {
                  if (typeof object.source !== 'object') {
                    throw new TypeError(
                      '.workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError.source: object expected, but got ' +
                        typeof object.source
                    );
                  }
                  message.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.fromObject(
                      object.source
                    );
                }
                return message;
              }

              static toObject(message, options = {}) {
                const object = {};
                if (options.arrays || options.defaults) {
                }
                if (options.objects || options.defaults) {
                }
                if (options.defaults) {
                  object.error = '';
                  object.source = null;
                }
                let keys;
                if (message.error != null && message.hasOwnProperty('error')) {
                  object.error = message.error;
                }
                if (
                  message.source != null &&
                  message.hasOwnProperty('source')
                ) {
                  object.source =
                    workflow_parameters$1.v1.DestinationActionParameterSource.toObject(
                      message.source,
                      options
                    );
                }
                return object;
              }

              static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
                return (
                  typeUrlPrefix +
                  'workflow_evaluation_results.v1.DestinationActionParametersError.DestinationActionParameterError.ParameterUnknownError'
                );
              }
            }

            ParameterUnknownError.prototype.error = '';
            ParameterUnknownError.prototype.source = null;

            return ParameterUnknownError;
          })();

          return DestinationActionParameterError;
        })();

      return DestinationActionParametersError;
    })();

    v1.WorkflowEvent = (() => {
      class WorkflowEvent {
        get event() {
          for (const key of [
            'running',
            'matchedProfile',
            'failed',
            'triggerEvaluationFailed',
            'executionComplete',
            'skipped',
            'cancelled',
            'restarted',
          ]) {
            if (this[key] !== null && this[key] !== undefined) return key;
          }
        }

        set event(name) {
          for (const key of [
            'running',
            'matchedProfile',
            'failed',
            'triggerEvaluationFailed',
            'executionComplete',
            'skipped',
            'cancelled',
            'restarted',
          ]) {
            if (key !== name) delete this[key];
          }
        }

        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new WorkflowEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.running != null &&
            Object.hasOwnProperty.call(message, 'running')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.Running.encode(
              message.running,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.matchedProfile != null &&
            Object.hasOwnProperty.call(message, 'matchedProfile')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile.encode(
              message.matchedProfile,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.failed != null &&
            Object.hasOwnProperty.call(message, 'failed')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.Failed.encode(
              message.failed,
              writer.uint32(26).fork()
            ).ldelim();
          }
          if (
            message.triggerEvaluationFailed != null &&
            Object.hasOwnProperty.call(message, 'triggerEvaluationFailed')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.TriggerEvaluationFailed.encode(
              message.triggerEvaluationFailed,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.executionComplete != null &&
            Object.hasOwnProperty.call(message, 'executionComplete')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.ExecutionComplete.encode(
              message.executionComplete,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.skipped != null &&
            Object.hasOwnProperty.call(message, 'skipped')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped.encode(
              message.skipped,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.cancelled != null &&
            Object.hasOwnProperty.call(message, 'cancelled')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled.encode(
              message.cancelled,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.restarted != null &&
            Object.hasOwnProperty.call(message, 'restarted')
          ) {
            $root.workflow_evaluation_results.v1.WorkflowEvent.Restarted.encode(
              message.restarted,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new WorkflowEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.running =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.Running.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 2: {
                message.matchedProfile =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 3: {
                message.failed =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.Failed.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 4: {
                message.triggerEvaluationFailed =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.TriggerEvaluationFailed.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 5: {
                message.executionComplete =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.ExecutionComplete.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                message.skipped =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.cancelled =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.restarted =
                  $root.workflow_evaluation_results.v1.WorkflowEvent.Restarted.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflow_evaluation_results.v1.WorkflowEvent
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.WorkflowEvent();
          if (object.running != null) {
            if (typeof object.running !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.running: object expected, but got ' +
                  typeof object.running
              );
            }
            message.running =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Running.fromObject(
                object.running
              );
          }
          if (object.matchedProfile != null) {
            if (typeof object.matchedProfile !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.matchedProfile: object expected, but got ' +
                  typeof object.matchedProfile
              );
            }
            message.matchedProfile =
              $root.workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile.fromObject(
                object.matchedProfile
              );
          }
          if (object.failed != null) {
            if (typeof object.failed !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.failed: object expected, but got ' +
                  typeof object.failed
              );
            }
            message.failed =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Failed.fromObject(
                object.failed
              );
          }
          if (object.triggerEvaluationFailed != null) {
            if (typeof object.triggerEvaluationFailed !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.triggerEvaluationFailed: object expected, but got ' +
                  typeof object.triggerEvaluationFailed
              );
            }
            message.triggerEvaluationFailed =
              $root.workflow_evaluation_results.v1.WorkflowEvent.TriggerEvaluationFailed.fromObject(
                object.triggerEvaluationFailed
              );
          }
          if (object.executionComplete != null) {
            if (typeof object.executionComplete !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.executionComplete: object expected, but got ' +
                  typeof object.executionComplete
              );
            }
            message.executionComplete =
              $root.workflow_evaluation_results.v1.WorkflowEvent.ExecutionComplete.fromObject(
                object.executionComplete
              );
          }
          if (object.skipped != null) {
            if (typeof object.skipped !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.skipped: object expected, but got ' +
                  typeof object.skipped
              );
            }
            message.skipped =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped.fromObject(
                object.skipped
              );
          }
          if (object.cancelled != null) {
            if (typeof object.cancelled !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.cancelled: object expected, but got ' +
                  typeof object.cancelled
              );
            }
            message.cancelled =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled.fromObject(
                object.cancelled
              );
          }
          if (object.restarted != null) {
            if (typeof object.restarted !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.WorkflowEvent.restarted: object expected, but got ' +
                  typeof object.restarted
              );
            }
            message.restarted =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Restarted.fromObject(
                object.restarted
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.running != null && message.hasOwnProperty('running')) {
            object.running =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Running.toObject(
                message.running,
                options
              );
            if (options.oneofs) {
              object.event = 'running';
            }
          }
          if (
            message.matchedProfile != null &&
            message.hasOwnProperty('matchedProfile')
          ) {
            object.matchedProfile =
              $root.workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile.toObject(
                message.matchedProfile,
                options
              );
            if (options.oneofs) {
              object.event = 'matchedProfile';
            }
          }
          if (message.failed != null && message.hasOwnProperty('failed')) {
            object.failed =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Failed.toObject(
                message.failed,
                options
              );
            if (options.oneofs) {
              object.event = 'failed';
            }
          }
          if (
            message.triggerEvaluationFailed != null &&
            message.hasOwnProperty('triggerEvaluationFailed')
          ) {
            object.triggerEvaluationFailed =
              $root.workflow_evaluation_results.v1.WorkflowEvent.TriggerEvaluationFailed.toObject(
                message.triggerEvaluationFailed,
                options
              );
            if (options.oneofs) {
              object.event = 'triggerEvaluationFailed';
            }
          }
          if (
            message.executionComplete != null &&
            message.hasOwnProperty('executionComplete')
          ) {
            object.executionComplete =
              $root.workflow_evaluation_results.v1.WorkflowEvent.ExecutionComplete.toObject(
                message.executionComplete,
                options
              );
            if (options.oneofs) {
              object.event = 'executionComplete';
            }
          }
          if (message.skipped != null && message.hasOwnProperty('skipped')) {
            object.skipped =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped.toObject(
                message.skipped,
                options
              );
            if (options.oneofs) {
              object.event = 'skipped';
            }
          }
          if (
            message.cancelled != null &&
            message.hasOwnProperty('cancelled')
          ) {
            object.cancelled =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled.toObject(
                message.cancelled,
                options
              );
            if (options.oneofs) {
              object.event = 'cancelled';
            }
          }
          if (
            message.restarted != null &&
            message.hasOwnProperty('restarted')
          ) {
            object.restarted =
              $root.workflow_evaluation_results.v1.WorkflowEvent.Restarted.toObject(
                message.restarted,
                options
              );
            if (options.oneofs) {
              object.event = 'restarted';
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflow_evaluation_results.v1.WorkflowEvent';
        }
      }

      WorkflowEvent.prototype.running = null;
      WorkflowEvent.prototype.matchedProfile = null;
      WorkflowEvent.prototype.failed = null;
      WorkflowEvent.prototype.triggerEvaluationFailed = null;
      WorkflowEvent.prototype.executionComplete = null;
      WorkflowEvent.prototype.skipped = null;
      WorkflowEvent.prototype.cancelled = null;
      WorkflowEvent.prototype.restarted = null;
      WorkflowEvent.TriggerEvaluationFailed = (() => {
        class TriggerEvaluationFailed {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new TriggerEvaluationFailed(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new TriggerEvaluationFailed();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent
                .TriggerEvaluationFailed
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.TriggerEvaluationFailed();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.TriggerEvaluationFailed'
            );
          }
        }

        return TriggerEvaluationFailed;
      })();

      WorkflowEvent.Running = (() => {
        class Running {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Running(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Running();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent.Running
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.Running();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.Running'
            );
          }
        }

        return Running;
      })();

      WorkflowEvent.Skipped = (() => {
        class Skipped {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Skipped(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.reason != null &&
              Object.hasOwnProperty.call(message, 'reason')
            ) {
              writer.uint32(8).int32(message.reason);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Skipped();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.reason = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped();
            if (object.reason != null) {
              switch (object.reason) {
                case 'UNSPECIFIED_SKIP_REASON':
                case 0: {
                  message.reason = 0;
                  break;
                }
                case 'UNIQUENESS_CONFLICT_RESOLUTION_SKIP_NEW':
                case 1: {
                  message.reason = 1;
                  break;
                }
                default: {
                  if (typeof object.reason == 'number') {
                    message.reason = object.reason;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.reason =
                options.enums === String ? 'UNSPECIFIED_SKIP_REASON' : 0;
            }
            let keys;
            if (message.reason != null && message.hasOwnProperty('reason')) {
              object.reason =
                options.enums === String
                  ? $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped
                      .SkipReason[message.reason] === undefined
                    ? message.reason
                    : $root.workflow_evaluation_results.v1.WorkflowEvent.Skipped
                        .SkipReason[message.reason]
                  : message.reason;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.Skipped'
            );
          }
        }

        Skipped.prototype.reason = 0;
        Skipped.SkipReason = (function () {
          const valuesById = {};
          const values = Object.create(valuesById);
          values[(valuesById[0] = 'UNSPECIFIED_SKIP_REASON')] = 0;
          values[
            (valuesById[1] = 'UNIQUENESS_CONFLICT_RESOLUTION_SKIP_NEW')
          ] = 1;
          return values;
        })();

        return Skipped;
      })();

      WorkflowEvent.Cancelled = (() => {
        class Cancelled {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Cancelled(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.reason != null &&
              Object.hasOwnProperty.call(message, 'reason')
            ) {
              writer.uint32(8).int32(message.reason);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Cancelled();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.reason = reader.int32();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled();
            if (object.reason != null) {
              switch (object.reason) {
                case 'UNSPECIFIED_CANCEL_REASON':
                case 0: {
                  message.reason = 0;
                  break;
                }
                case 'UNIQUENESS_CONFLICT_RESOLUTION_SUPERSEDED':
                case 1: {
                  message.reason = 1;
                  break;
                }
                case 'UNKNOWN_CANCEL_REASON':
                case 2: {
                  message.reason = 2;
                  break;
                }
                default: {
                  if (typeof object.reason == 'number') {
                    message.reason = object.reason;
                    break;
                  }
                  break;
                }
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.reason =
                options.enums === String ? 'UNSPECIFIED_CANCEL_REASON' : 0;
            }
            let keys;
            if (message.reason != null && message.hasOwnProperty('reason')) {
              object.reason =
                options.enums === String
                  ? $root.workflow_evaluation_results.v1.WorkflowEvent.Cancelled
                      .CancelReason[message.reason] === undefined
                    ? message.reason
                    : $root.workflow_evaluation_results.v1.WorkflowEvent
                        .Cancelled.CancelReason[message.reason]
                  : message.reason;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.Cancelled'
            );
          }
        }

        Cancelled.prototype.reason = 0;
        Cancelled.CancelReason = (function () {
          const valuesById = {};
          const values = Object.create(valuesById);
          values[(valuesById[0] = 'UNSPECIFIED_CANCEL_REASON')] = 0;
          values[
            (valuesById[1] = 'UNIQUENESS_CONFLICT_RESOLUTION_SUPERSEDED')
          ] = 1;
          values[(valuesById[2] = 'UNKNOWN_CANCEL_REASON')] = 2;
          return values;
        })();

        return Cancelled;
      })();

      WorkflowEvent.MatchedProfile = (() => {
        class MatchedProfile {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new MatchedProfile(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new MatchedProfile();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.MatchedProfile'
            );
          }
        }

        return MatchedProfile;
      })();

      WorkflowEvent.Failed = (() => {
        class Failed {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Failed(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Failed();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent.Failed
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.Failed();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.Failed'
            );
          }
        }

        return Failed;
      })();

      WorkflowEvent.ExecutionComplete = (() => {
        class ExecutionComplete {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new ExecutionComplete(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new ExecutionComplete();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent
                .ExecutionComplete
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.ExecutionComplete();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.ExecutionComplete'
            );
          }
        }

        return ExecutionComplete;
      })();

      WorkflowEvent.Restarted = (() => {
        class Restarted {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Restarted(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Restarted();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.WorkflowEvent.Restarted
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.WorkflowEvent.Restarted();
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
            }
            let keys;
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.WorkflowEvent.Restarted'
            );
          }
        }

        return Restarted;
      })();

      return WorkflowEvent;
    })();

    v1.FormattedError = (() => {
      class FormattedError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new FormattedError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.errorClass != null &&
            Object.hasOwnProperty.call(message, 'errorClass')
          ) {
            writer.uint32(8).int32(message.errorClass);
          }
          if (
            message.errorString != null &&
            Object.hasOwnProperty.call(message, 'errorString')
          ) {
            writer.uint32(18).string(message.errorString);
          }
          if (
            message.errorDetails != null &&
            Object.hasOwnProperty.call(message, 'errorDetails')
          ) {
            writer.uint32(26).string(message.errorDetails);
          }
          if (
            message.errorDecision != null &&
            Object.hasOwnProperty.call(message, 'errorDecision')
          ) {
            writer.uint32(32).int32(message.errorDecision);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new FormattedError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.errorClass = reader.int32();
                break;
              }
              case 2: {
                message.errorString = reader.string();
                break;
              }
              case 3: {
                message.errorDetails = reader.string();
                break;
              }
              case 4: {
                message.errorDecision = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.FormattedError
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.FormattedError();
          if (object.errorClass != null) {
            switch (object.errorClass) {
              case 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS':
              case 0: {
                message.errorClass = 0;
                break;
              }
              case 'ERROR':
              case 1: {
                message.errorClass = 1;
                break;
              }
              case 'WARNING':
              case 2: {
                message.errorClass = 2;
                break;
              }
              case 'INTERNAL_ERROR':
              case 4: {
                message.errorClass = 4;
                break;
              }
              default: {
                if (typeof object.errorClass == 'number') {
                  message.errorClass = object.errorClass;
                  break;
                }
                break;
              }
            }
          }
          if (object.errorString != null) {
            message.errorString = String(object.errorString);
          }
          if (object.errorDetails != null) {
            message.errorDetails = String(object.errorDetails);
          }
          if (object.errorDecision != null) {
            switch (object.errorDecision) {
              case 'UNSPECIFIED_ERROR_DECISION':
              case 0: {
                message.errorDecision = 0;
                break;
              }
              case 'ERROR_DECISION_CONTINUE_WORKFLOW':
              case 1: {
                message.errorDecision = 1;
                break;
              }
              case 'ERROR_DECISION_TERMINATE_BRANCH':
              case 2: {
                message.errorDecision = 2;
                break;
              }
              case 'ERROR_DECISION_TERMINATE_WORKFLOW':
              case 3: {
                message.errorDecision = 3;
                break;
              }
              default: {
                if (typeof object.errorDecision == 'number') {
                  message.errorDecision = object.errorDecision;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.errorClass =
              options.enums === String
                ? 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS'
                : 0;
            object.errorString = '';
            object.errorDetails = '';
            object.errorDecision =
              options.enums === String ? 'UNSPECIFIED_ERROR_DECISION' : 0;
          }
          let keys;
          if (
            message.errorClass != null &&
            message.hasOwnProperty('errorClass')
          ) {
            object.errorClass =
              options.enums === String
                ? $root.workflow_evaluation_results.v1.ErrorClass[
                    message.errorClass
                  ] === undefined
                  ? message.errorClass
                  : $root.workflow_evaluation_results.v1.ErrorClass[
                      message.errorClass
                    ]
                : message.errorClass;
          }
          if (
            message.errorString != null &&
            message.hasOwnProperty('errorString')
          ) {
            object.errorString = message.errorString;
          }
          if (
            message.errorDetails != null &&
            message.hasOwnProperty('errorDetails')
          ) {
            object.errorDetails = message.errorDetails;
          }
          if (
            message.errorDecision != null &&
            message.hasOwnProperty('errorDecision')
          ) {
            object.errorDecision =
              options.enums === String
                ? $root.workflow_evaluation_results.v1.ErrorDecision[
                    message.errorDecision
                  ] === undefined
                  ? message.errorDecision
                  : $root.workflow_evaluation_results.v1.ErrorDecision[
                      message.errorDecision
                    ]
                : message.errorDecision;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflow_evaluation_results.v1.FormattedError'
          );
        }
      }

      FormattedError.prototype.errorClass = 0;
      FormattedError.prototype.errorString = '';
      FormattedError.prototype.errorDetails = '';
      FormattedError.prototype.errorDecision = 0;

      return FormattedError;
    })();

    v1.ActionError = (() => {
      class ActionError {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ActionError(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.issue != null &&
            Object.hasOwnProperty.call(message, 'issue')
          ) {
            writer.uint32(10).string(message.issue);
          }
          if (
            message.details != null &&
            Object.hasOwnProperty.call(message, 'details')
          ) {
            writer.uint32(18).string(message.details);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ActionError();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.issue = reader.string();
                break;
              }
              case 2: {
                message.details = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof $root.workflow_evaluation_results.v1.ActionError
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.ActionError();
          if (object.issue != null) {
            message.issue = String(object.issue);
          }
          if (object.details != null) {
            message.details = String(object.details);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.issue = '';
            object.details = '';
          }
          let keys;
          if (message.issue != null && message.hasOwnProperty('issue')) {
            object.issue = message.issue;
          }
          if (message.details != null && message.hasOwnProperty('details')) {
            object.details = message.details;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'workflow_evaluation_results.v1.ActionError';
        }
      }

      ActionError.prototype.issue = '';
      ActionError.prototype.details = '';

      return ActionError;
    })();

    v1.ErrorBucketEvents = (() => {
      class ErrorBucketEvents {
        constructor(properties) {
          this.events = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ErrorBucketEvents(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.organizationId != null &&
            Object.hasOwnProperty.call(message, 'organizationId')
          ) {
            writer.uint32(10).string(message.organizationId);
          }
          if (
            message.events != null &&
            Object.hasOwnProperty.call(message, 'events')
          ) {
            for (const element of message.events) {
              $root.workflow_evaluation_results.v1.ErrorBucketEvents.Event.encode(
                element,
                writer.uint32(18).fork()
              ).ldelim();
            }
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ErrorBucketEvents();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.organizationId = reader.string();
                break;
              }
              case 2: {
                if (!message.events || !message.events.length) {
                  message.events = [];
                }
                message.events.push(
                  $root.workflow_evaluation_results.v1.ErrorBucketEvents.Event.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.ErrorBucketEvents
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.ErrorBucketEvents();
          if (object.organizationId != null) {
            message.organizationId = String(object.organizationId);
          }
          if (object.events) {
            if (!Array.isArray(object.events)) {
              throw new TypeError(
                '.workflow_evaluation_results.v1.ErrorBucketEvents.events: array type expected, but got ' +
                  typeof object.events
              );
            }
            message.events = new Array(object.events.length);
            for (let i = 0; i < object.events.length; ++i) {
              if (typeof object.events[i] !== 'object') {
                throw new TypeError(
                  '.workflow_evaluation_results.v1.ErrorBucketEvents.events: object expected, but got ' +
                    typeof object.events[i]
                );
              }
              message.events[i] =
                $root.workflow_evaluation_results.v1.ErrorBucketEvents.Event.fromObject(
                  object.events[i]
                );
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.events = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.organizationId = '';
          }
          let keys;
          if (
            message.organizationId != null &&
            message.hasOwnProperty('organizationId')
          ) {
            object.organizationId = message.organizationId;
          }
          if (message.events && message.events.length) {
            object.events = new Array(message.events.length);
            for (let i = 0; i < message.events.length; ++i) {
              object.events[i] =
                $root.workflow_evaluation_results.v1.ErrorBucketEvents.Event.toObject(
                  message.events[i],
                  options
                );
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix + 'workflow_evaluation_results.v1.ErrorBucketEvents'
          );
        }
      }

      ErrorBucketEvents.prototype.organizationId = '';
      ErrorBucketEvents.prototype.events = $util.emptyArray;
      ErrorBucketEvents.Event = (() => {
        class Event {
          constructor(properties) {
            if (properties) {
              for (let key of Object.keys(properties)) {
                if (properties[key] != null) this[key] = properties[key];
              }
            }
          }

          static create(properties) {
            return new Event(properties);
          }

          static encode(message, writer) {
            if (!writer) writer = $Writer.create();
            if (
              message.workflowId != null &&
              Object.hasOwnProperty.call(message, 'workflowId')
            ) {
              writer.uint32(10).string(message.workflowId);
            }
            if (
              message.thirdPartyProfileId != null &&
              Object.hasOwnProperty.call(message, 'thirdPartyProfileId')
            ) {
              writer.uint32(16).int64(message.thirdPartyProfileId);
            }
            if (
              message.bucketStartTime != null &&
              Object.hasOwnProperty.call(message, 'bucketStartTime')
            ) {
              timing$1.v1.Timestamp.encode(
                message.bucketStartTime,
                writer.uint32(26).fork()
              ).ldelim();
            }
            if (
              message.errorClass != null &&
              Object.hasOwnProperty.call(message, 'errorClass')
            ) {
              writer.uint32(32).int32(message.errorClass);
            }
            if (
              message.errorString != null &&
              Object.hasOwnProperty.call(message, 'errorString')
            ) {
              writer.uint32(42).string(message.errorString);
            }
            if (
              message.groupingName != null &&
              Object.hasOwnProperty.call(message, 'groupingName')
            ) {
              writer.uint32(50).string(message.groupingName);
            }
            if (
              message.destinationApplicationType != null &&
              Object.hasOwnProperty.call(message, 'destinationApplicationType')
            ) {
              workflow_destination_applications$1.v1.DestinationApplication.encode(
                message.destinationApplicationType,
                writer.uint32(58).fork()
              ).ldelim();
            }
            if (
              message.errorTimeHash != null &&
              Object.hasOwnProperty.call(message, 'errorTimeHash')
            ) {
              writer.uint32(64).int64(message.errorTimeHash);
            }
            if (
              message.errorHash != null &&
              Object.hasOwnProperty.call(message, 'errorHash')
            ) {
              writer.uint32(72).int64(message.errorHash);
            }
            return writer;
          }

          static decode(reader, length) {
            if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length;
            let message = new Event();
            let key, value;
            while (reader.pos < end) {
              let tag = reader.uint32();
              switch (tag >>> 3) {
                case 1: {
                  message.workflowId = reader.string();
                  break;
                }
                case 2: {
                  message.thirdPartyProfileId = reader.int64();
                  break;
                }
                case 3: {
                  message.bucketStartTime = timing$1.v1.Timestamp.decode(
                    reader,
                    reader.uint32()
                  );
                  break;
                }
                case 4: {
                  message.errorClass = reader.int32();
                  break;
                }
                case 5: {
                  message.errorString = reader.string();
                  break;
                }
                case 6: {
                  message.groupingName = reader.string();
                  break;
                }
                case 7: {
                  message.destinationApplicationType =
                    workflow_destination_applications$1.v1.DestinationApplication.decode(
                      reader,
                      reader.uint32()
                    );
                  break;
                }
                case 8: {
                  message.errorTimeHash = reader.int64();
                  break;
                }
                case 9: {
                  message.errorHash = reader.int64();
                  break;
                }
                default: {
                  reader.skipType(tag & 7);
                  break;
                }
              }
            }
            return message;
          }

          static fromObject(object) {
            if (
              object instanceof
              $root.workflow_evaluation_results.v1.ErrorBucketEvents.Event
            ) {
              return object;
            }
            const message =
              new $root.workflow_evaluation_results.v1.ErrorBucketEvents.Event();
            if (object.workflowId != null) {
              message.workflowId = String(object.workflowId);
            }
            if (object.thirdPartyProfileId != null) {
              if ($util.Long) {
                (message.thirdPartyProfileId = $util.Long.fromValue(
                  object.thirdPartyProfileId
                )).unsigned = false;
              } else if (typeof object.thirdPartyProfileId === 'string') {
                message.thirdPartyProfileId = parseInt(
                  object.thirdPartyProfileId,
                  10
                );
              } else if (typeof object.thirdPartyProfileId === 'number') {
                message.thirdPartyProfileId = object.thirdPartyProfileId;
              } else if (typeof object.thirdPartyProfileId === 'object') {
                message.thirdPartyProfileId = new $util.LongBits(
                  object.thirdPartyProfileId.low >>> 0,
                  object.thirdPartyProfileId.high >>> 0
                ).toNumber();
              }
            }
            if (object.bucketStartTime != null) {
              if (typeof object.bucketStartTime !== 'object') {
                throw new TypeError(
                  '.workflow_evaluation_results.v1.ErrorBucketEvents.Event.bucketStartTime: object expected, but got ' +
                    typeof object.bucketStartTime
                );
              }
              message.bucketStartTime = timing$1.v1.Timestamp.fromObject(
                object.bucketStartTime
              );
            }
            if (object.errorClass != null) {
              switch (object.errorClass) {
                case 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS':
                case 0: {
                  message.errorClass = 0;
                  break;
                }
                case 'ERROR':
                case 1: {
                  message.errorClass = 1;
                  break;
                }
                case 'WARNING':
                case 2: {
                  message.errorClass = 2;
                  break;
                }
                case 'INTERNAL_ERROR':
                case 4: {
                  message.errorClass = 4;
                  break;
                }
                default: {
                  if (typeof object.errorClass == 'number') {
                    message.errorClass = object.errorClass;
                    break;
                  }
                  break;
                }
              }
            }
            if (object.errorString != null) {
              message.errorString = String(object.errorString);
            }
            if (object.groupingName != null) {
              message.groupingName = String(object.groupingName);
            }
            if (object.destinationApplicationType != null) {
              if (typeof object.destinationApplicationType !== 'object') {
                throw new TypeError(
                  '.workflow_evaluation_results.v1.ErrorBucketEvents.Event.destinationApplicationType: object expected, but got ' +
                    typeof object.destinationApplicationType
                );
              }
              message.destinationApplicationType =
                workflow_destination_applications$1.v1.DestinationApplication.fromObject(
                  object.destinationApplicationType
                );
            }
            if (object.errorTimeHash != null) {
              if ($util.Long) {
                (message.errorTimeHash = $util.Long.fromValue(
                  object.errorTimeHash
                )).unsigned = false;
              } else if (typeof object.errorTimeHash === 'string') {
                message.errorTimeHash = parseInt(object.errorTimeHash, 10);
              } else if (typeof object.errorTimeHash === 'number') {
                message.errorTimeHash = object.errorTimeHash;
              } else if (typeof object.errorTimeHash === 'object') {
                message.errorTimeHash = new $util.LongBits(
                  object.errorTimeHash.low >>> 0,
                  object.errorTimeHash.high >>> 0
                ).toNumber();
              }
            }
            if (object.errorHash != null) {
              if ($util.Long) {
                (message.errorHash = $util.Long.fromValue(
                  object.errorHash
                )).unsigned = false;
              } else if (typeof object.errorHash === 'string') {
                message.errorHash = parseInt(object.errorHash, 10);
              } else if (typeof object.errorHash === 'number') {
                message.errorHash = object.errorHash;
              } else if (typeof object.errorHash === 'object') {
                message.errorHash = new $util.LongBits(
                  object.errorHash.low >>> 0,
                  object.errorHash.high >>> 0
                ).toNumber();
              }
            }
            return message;
          }

          static toObject(message, options = {}) {
            const object = {};
            if (options.arrays || options.defaults) {
            }
            if (options.objects || options.defaults) {
            }
            if (options.defaults) {
              object.workflowId = '';
              object.bucketStartTime = null;
              object.errorClass =
                options.enums === String
                  ? 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS'
                  : 0;
              object.errorString = '';
              object.groupingName = '';
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.errorTimeHash =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.errorTimeHash = options.longs === String ? '0' : 0;
              }
              if ($util.Long) {
                let long = new $util.Long(0, 0, false);
                object.errorHash =
                  options.longs === String
                    ? long.toString()
                    : options.longs === Number
                    ? long.toNumber()
                    : long;
              } else {
                object.errorHash = options.longs === String ? '0' : 0;
              }
            }
            let keys;
            if (
              message.workflowId != null &&
              message.hasOwnProperty('workflowId')
            ) {
              object.workflowId = message.workflowId;
            }
            if (
              message.thirdPartyProfileId != null &&
              message.hasOwnProperty('thirdPartyProfileId')
            ) {
              object.thirdPartyProfileId =
                typeof message.thirdPartyProfileId === 'number'
                  ? options.longs === String
                    ? String(message.thirdPartyProfileId)
                    : message.thirdPartyProfileId
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(
                      message.thirdPartyProfileId
                    )
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.thirdPartyProfileId.low >>> 0,
                      message.thirdPartyProfileId.high >>> 0
                    ).toNumber()
                  : message.thirdPartyProfileId;
            }
            if (
              message.bucketStartTime != null &&
              message.hasOwnProperty('bucketStartTime')
            ) {
              object.bucketStartTime = timing$1.v1.Timestamp.toObject(
                message.bucketStartTime,
                options
              );
            }
            if (
              message.errorClass != null &&
              message.hasOwnProperty('errorClass')
            ) {
              object.errorClass =
                options.enums === String
                  ? $root.workflow_evaluation_results.v1.ErrorClass[
                      message.errorClass
                    ] === undefined
                    ? message.errorClass
                    : $root.workflow_evaluation_results.v1.ErrorClass[
                        message.errorClass
                      ]
                  : message.errorClass;
            }
            if (
              message.errorString != null &&
              message.hasOwnProperty('errorString')
            ) {
              object.errorString = message.errorString;
            }
            if (
              message.groupingName != null &&
              message.hasOwnProperty('groupingName')
            ) {
              object.groupingName = message.groupingName;
            }
            if (
              message.destinationApplicationType != null &&
              message.hasOwnProperty('destinationApplicationType')
            ) {
              object.destinationApplicationType =
                workflow_destination_applications$1.v1.DestinationApplication.toObject(
                  message.destinationApplicationType,
                  options
                );
            }
            if (
              message.errorTimeHash != null &&
              message.hasOwnProperty('errorTimeHash')
            ) {
              object.errorTimeHash =
                typeof message.errorTimeHash === 'number'
                  ? options.longs === String
                    ? String(message.errorTimeHash)
                    : message.errorTimeHash
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.errorTimeHash)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.errorTimeHash.low >>> 0,
                      message.errorTimeHash.high >>> 0
                    ).toNumber()
                  : message.errorTimeHash;
            }
            if (
              message.errorHash != null &&
              message.hasOwnProperty('errorHash')
            ) {
              object.errorHash =
                typeof message.errorHash === 'number'
                  ? options.longs === String
                    ? String(message.errorHash)
                    : message.errorHash
                  : options.longs === String
                  ? $util.Long.prototype.toString.call(message.errorHash)
                  : options.longs === Number
                  ? new $util.LongBits(
                      message.errorHash.low >>> 0,
                      message.errorHash.high >>> 0
                    ).toNumber()
                  : message.errorHash;
            }
            return object;
          }

          static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
            return (
              typeUrlPrefix +
              'workflow_evaluation_results.v1.ErrorBucketEvents.Event'
            );
          }
        }

        Event.prototype.workflowId = '';
        Event.prototype.thirdPartyProfileId = null;
        Event.prototype.bucketStartTime = null;
        Event.prototype.errorClass = 0;
        Event.prototype.errorString = '';
        Event.prototype.groupingName = '';
        Event.prototype.destinationApplicationType = null;
        Event.prototype.errorTimeHash = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;
        Event.prototype.errorHash = $util.Long
          ? $util.Long.fromBits(0, 0, false)
          : 0;

        return Event;
      })();

      return ErrorBucketEvents;
    })();

    v1.DestinationActionResult = (() => {
      class DestinationActionResult {
        constructor(properties) {
          this.warnings = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionResult(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.evaluatedParameters != null &&
            Object.hasOwnProperty.call(message, 'evaluatedParameters')
          ) {
            google$1.protobuf.Struct.encode(
              message.evaluatedParameters,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.additionalInformation != null &&
            Object.hasOwnProperty.call(message, 'additionalInformation')
          ) {
            google$1.protobuf.Struct.encode(
              message.additionalInformation,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.attemptNumber != null &&
            Object.hasOwnProperty.call(message, 'attemptNumber')
          ) {
            writer.uint32(24).int64(message.attemptNumber);
          }
          if (
            message.serializedFetchExtraDataResult != null &&
            Object.hasOwnProperty.call(
              message,
              'serializedFetchExtraDataResult'
            )
          ) {
            workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.encode(
              message.serializedFetchExtraDataResult,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.warnings != null &&
            Object.hasOwnProperty.call(message, 'warnings')
          ) {
            for (const element of message.warnings) {
              $root.workflow_evaluation_results.v1.FormattedError.encode(
                element,
                writer.uint32(50).fork()
              ).ldelim();
            }
          }
          if (
            message.refreshedEventSnapshotId != null &&
            Object.hasOwnProperty.call(message, 'refreshedEventSnapshotId')
          ) {
            writer.uint32(58).string(message.refreshedEventSnapshotId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionResult();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.evaluatedParameters = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.additionalInformation = google$1.protobuf.Struct.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.attemptNumber = reader.int64();
                break;
              }
              case 4: {
                message.serializedFetchExtraDataResult =
                  workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 6: {
                if (!message.warnings || !message.warnings.length) {
                  message.warnings = [];
                }
                message.warnings.push(
                  $root.workflow_evaluation_results.v1.FormattedError.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 7: {
                message.refreshedEventSnapshotId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.DestinationActionResult
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.DestinationActionResult();
          if (object.evaluatedParameters != null) {
            if (typeof object.evaluatedParameters !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionResult.evaluatedParameters: object expected, but got ' +
                  typeof object.evaluatedParameters
              );
            }
            message.evaluatedParameters = google$1.protobuf.Struct.fromObject(
              object.evaluatedParameters
            );
          }
          if (object.additionalInformation != null) {
            if (typeof object.additionalInformation !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionResult.additionalInformation: object expected, but got ' +
                  typeof object.additionalInformation
              );
            }
            message.additionalInformation = google$1.protobuf.Struct.fromObject(
              object.additionalInformation
            );
          }
          if (object.attemptNumber != null) {
            if ($util.Long) {
              (message.attemptNumber = $util.Long.fromValue(
                object.attemptNumber
              )).unsigned = false;
            } else if (typeof object.attemptNumber === 'string') {
              message.attemptNumber = parseInt(object.attemptNumber, 10);
            } else if (typeof object.attemptNumber === 'number') {
              message.attemptNumber = object.attemptNumber;
            } else if (typeof object.attemptNumber === 'object') {
              message.attemptNumber = new $util.LongBits(
                object.attemptNumber.low >>> 0,
                object.attemptNumber.high >>> 0
              ).toNumber();
            }
          }
          if (object.serializedFetchExtraDataResult != null) {
            if (typeof object.serializedFetchExtraDataResult !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionResult.serializedFetchExtraDataResult: object expected, but got ' +
                  typeof object.serializedFetchExtraDataResult
              );
            }
            message.serializedFetchExtraDataResult =
              workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.fromObject(
                object.serializedFetchExtraDataResult
              );
          }
          if (object.warnings) {
            if (!Array.isArray(object.warnings)) {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionResult.warnings: array type expected, but got ' +
                  typeof object.warnings
              );
            }
            message.warnings = new Array(object.warnings.length);
            for (let i = 0; i < object.warnings.length; ++i) {
              if (typeof object.warnings[i] !== 'object') {
                throw new TypeError(
                  '.workflow_evaluation_results.v1.DestinationActionResult.warnings: object expected, but got ' +
                    typeof object.warnings[i]
                );
              }
              message.warnings[i] =
                $root.workflow_evaluation_results.v1.FormattedError.fromObject(
                  object.warnings[i]
                );
            }
          }
          if (object.refreshedEventSnapshotId != null) {
            message.refreshedEventSnapshotId = String(
              object.refreshedEventSnapshotId
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.warnings = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.evaluatedParameters = null;
            object.additionalInformation = null;
            if ($util.Long) {
              let long = new $util.Long(0, 0, false);
              object.attemptNumber =
                options.longs === String
                  ? long.toString()
                  : options.longs === Number
                  ? long.toNumber()
                  : long;
            } else {
              object.attemptNumber = options.longs === String ? '0' : 0;
            }
          }
          let keys;
          if (
            message.evaluatedParameters != null &&
            message.hasOwnProperty('evaluatedParameters')
          ) {
            object.evaluatedParameters = google$1.protobuf.Struct.toObject(
              message.evaluatedParameters,
              options
            );
          }
          if (
            message.additionalInformation != null &&
            message.hasOwnProperty('additionalInformation')
          ) {
            object.additionalInformation = google$1.protobuf.Struct.toObject(
              message.additionalInformation,
              options
            );
          }
          if (
            message.attemptNumber != null &&
            message.hasOwnProperty('attemptNumber')
          ) {
            object.attemptNumber =
              typeof message.attemptNumber === 'number'
                ? options.longs === String
                  ? String(message.attemptNumber)
                  : message.attemptNumber
                : options.longs === String
                ? $util.Long.prototype.toString.call(message.attemptNumber)
                : options.longs === Number
                ? new $util.LongBits(
                    message.attemptNumber.low >>> 0,
                    message.attemptNumber.high >>> 0
                  ).toNumber()
                : message.attemptNumber;
          }
          if (
            message.serializedFetchExtraDataResult != null &&
            message.hasOwnProperty('serializedFetchExtraDataResult')
          ) {
            object.serializedFetchExtraDataResult =
              workflow_extra_fetched_data$1.v1.SerializedFetchExtraDataResult.toObject(
                message.serializedFetchExtraDataResult,
                options
              );
          }
          if (message.warnings && message.warnings.length) {
            object.warnings = new Array(message.warnings.length);
            for (let i = 0; i < message.warnings.length; ++i) {
              object.warnings[i] =
                $root.workflow_evaluation_results.v1.FormattedError.toObject(
                  message.warnings[i],
                  options
                );
            }
          }
          if (
            message.refreshedEventSnapshotId != null &&
            message.hasOwnProperty('refreshedEventSnapshotId')
          ) {
            object.refreshedEventSnapshotId = message.refreshedEventSnapshotId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflow_evaluation_results.v1.DestinationActionResult'
          );
        }
      }

      DestinationActionResult.prototype.evaluatedParameters = null;
      DestinationActionResult.prototype.additionalInformation = null;
      DestinationActionResult.prototype.attemptNumber = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;
      DestinationActionResult.prototype.serializedFetchExtraDataResult = null;
      DestinationActionResult.prototype.warnings = $util.emptyArray;
      DestinationActionResult.prototype.refreshedEventSnapshotId = null;

      return DestinationActionResult;
    })();

    v1.DestinationActionOutcome = (() => {
      class DestinationActionOutcome {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new DestinationActionOutcome(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.activityDestinationApplicationType != null &&
            Object.hasOwnProperty.call(
              message,
              'activityDestinationApplicationType'
            )
          ) {
            writer.uint32(8).int32(message.activityDestinationApplicationType);
          }
          if (
            message.destinationActionName != null &&
            Object.hasOwnProperty.call(message, 'destinationActionName')
          ) {
            writer.uint32(18).string(message.destinationActionName);
          }
          if (
            message.workflowNodeId != null &&
            Object.hasOwnProperty.call(message, 'workflowNodeId')
          ) {
            writer.uint32(26).string(message.workflowNodeId);
          }
          if (
            message.activityStartTime != null &&
            Object.hasOwnProperty.call(message, 'activityStartTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.activityStartTime,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.activityEndTime != null &&
            Object.hasOwnProperty.call(message, 'activityEndTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.activityEndTime,
              writer.uint32(42).fork()
            ).ldelim();
          }
          if (
            message.result != null &&
            Object.hasOwnProperty.call(message, 'result')
          ) {
            $root.workflow_evaluation_results.v1.DestinationActionResult.encode(
              message.result,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.error != null &&
            Object.hasOwnProperty.call(message, 'error')
          ) {
            $root.workflow_evaluation_results.v1.FormattedError.encode(
              message.error,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.filterOutcome != null &&
            Object.hasOwnProperty.call(message, 'filterOutcome')
          ) {
            writer.uint32(64).bool(message.filterOutcome);
          }
          if (
            message.maximumAttempts != null &&
            Object.hasOwnProperty.call(message, 'maximumAttempts')
          ) {
            writer.uint32(72).int32(message.maximumAttempts);
          }
          if (
            message.isLastAttempt != null &&
            Object.hasOwnProperty.call(message, 'isLastAttempt')
          ) {
            writer.uint32(80).bool(message.isLastAttempt);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new DestinationActionOutcome();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.activityDestinationApplicationType = reader.int32();
                break;
              }
              case 2: {
                message.destinationActionName = reader.string();
                break;
              }
              case 3: {
                message.workflowNodeId = reader.string();
                break;
              }
              case 4: {
                message.activityStartTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.activityEndTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 6: {
                message.result =
                  $root.workflow_evaluation_results.v1.DestinationActionResult.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 7: {
                message.error =
                  $root.workflow_evaluation_results.v1.FormattedError.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 8: {
                message.filterOutcome = reader.bool();
                break;
              }
              case 9: {
                message.maximumAttempts = reader.int32();
                break;
              }
              case 10: {
                message.isLastAttempt = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (
            object instanceof
            $root.workflow_evaluation_results.v1.DestinationActionOutcome
          ) {
            return object;
          }
          const message =
            new $root.workflow_evaluation_results.v1.DestinationActionOutcome();
          if (object.activityDestinationApplicationType != null) {
            switch (object.activityDestinationApplicationType) {
              case 'UNSPECIFIED_DESTINATION_APPLICATION':
              case 0: {
                message.activityDestinationApplicationType = 0;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 1: {
                message.activityDestinationApplicationType = 1;
                break;
              }
              case 'CUSTOMER_IO':
              case 2: {
                message.activityDestinationApplicationType = 2;
                break;
              }
              case 'FACEBOOK':
              case 3: {
                message.activityDestinationApplicationType = 3;
                break;
              }
              case 'GOOGLE':
              case 4: {
                message.activityDestinationApplicationType = 4;
                break;
              }
              case 'HEALTHIE':
              case 5: {
                message.activityDestinationApplicationType = 5;
                break;
              }
              case 'MORF':
              case 6: {
                message.activityDestinationApplicationType = 6;
                break;
              }
              case 'INTAKEQ':
              case 7: {
                message.activityDestinationApplicationType = 7;
                break;
              }
              case 'SEGMENT':
              case 8: {
                message.activityDestinationApplicationType = 8;
                break;
              }
              case 'SPRUCE':
              case 9: {
                message.activityDestinationApplicationType = 9;
                break;
              }
              case 'SLACK':
              case 10: {
                message.activityDestinationApplicationType = 10;
                break;
              }
              case 'FRESHDESK':
              case 11: {
                message.activityDestinationApplicationType = 11;
                break;
              }
              case 'HUBSPOT':
              case 12: {
                message.activityDestinationApplicationType = 12;
                break;
              }
              case 'INTERCOM':
              case 13: {
                message.activityDestinationApplicationType = 13;
                break;
              }
              case 'TIKTOK':
              case 14: {
                message.activityDestinationApplicationType = 14;
                break;
              }
              default: {
                if (
                  typeof object.activityDestinationApplicationType == 'number'
                ) {
                  message.activityDestinationApplicationType =
                    object.activityDestinationApplicationType;
                  break;
                }
                break;
              }
            }
          }
          if (object.destinationActionName != null) {
            message.destinationActionName = String(
              object.destinationActionName
            );
          }
          if (object.workflowNodeId != null) {
            message.workflowNodeId = String(object.workflowNodeId);
          }
          if (object.activityStartTime != null) {
            if (typeof object.activityStartTime !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionOutcome.activityStartTime: object expected, but got ' +
                  typeof object.activityStartTime
              );
            }
            message.activityStartTime = timing$1.v1.Timestamp.fromObject(
              object.activityStartTime
            );
          }
          if (object.activityEndTime != null) {
            if (typeof object.activityEndTime !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionOutcome.activityEndTime: object expected, but got ' +
                  typeof object.activityEndTime
              );
            }
            message.activityEndTime = timing$1.v1.Timestamp.fromObject(
              object.activityEndTime
            );
          }
          if (object.result != null) {
            if (typeof object.result !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionOutcome.result: object expected, but got ' +
                  typeof object.result
              );
            }
            message.result =
              $root.workflow_evaluation_results.v1.DestinationActionResult.fromObject(
                object.result
              );
          }
          if (object.error != null) {
            if (typeof object.error !== 'object') {
              throw new TypeError(
                '.workflow_evaluation_results.v1.DestinationActionOutcome.error: object expected, but got ' +
                  typeof object.error
              );
            }
            message.error =
              $root.workflow_evaluation_results.v1.FormattedError.fromObject(
                object.error
              );
          }
          if (object.filterOutcome != null) {
            message.filterOutcome = Boolean(object.filterOutcome);
          }
          if (object.maximumAttempts != null) {
            message.maximumAttempts = object.maximumAttempts | 0;
          }
          if (object.isLastAttempt != null) {
            message.isLastAttempt = Boolean(object.isLastAttempt);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.activityDestinationApplicationType =
              options.enums === String
                ? 'UNSPECIFIED_DESTINATION_APPLICATION'
                : 0;
            object.destinationActionName = '';
            object.workflowNodeId = '';
            object.activityStartTime = null;
            object.result = null;
            object.isLastAttempt = false;
          }
          let keys;
          if (
            message.activityDestinationApplicationType != null &&
            message.hasOwnProperty('activityDestinationApplicationType')
          ) {
            object.activityDestinationApplicationType =
              options.enums === String
                ? workflow_destination_applications$1.v1.DestinationApplication
                    .Application[message.activityDestinationApplicationType] ===
                  undefined
                  ? message.activityDestinationApplicationType
                  : workflow_destination_applications$1.v1
                      .DestinationApplication.Application[
                      message.activityDestinationApplicationType
                    ]
                : message.activityDestinationApplicationType;
          }
          if (
            message.destinationActionName != null &&
            message.hasOwnProperty('destinationActionName')
          ) {
            object.destinationActionName = message.destinationActionName;
          }
          if (
            message.workflowNodeId != null &&
            message.hasOwnProperty('workflowNodeId')
          ) {
            object.workflowNodeId = message.workflowNodeId;
          }
          if (
            message.activityStartTime != null &&
            message.hasOwnProperty('activityStartTime')
          ) {
            object.activityStartTime = timing$1.v1.Timestamp.toObject(
              message.activityStartTime,
              options
            );
          }
          if (
            message.activityEndTime != null &&
            message.hasOwnProperty('activityEndTime')
          ) {
            object.activityEndTime = timing$1.v1.Timestamp.toObject(
              message.activityEndTime,
              options
            );
          }
          if (message.result != null && message.hasOwnProperty('result')) {
            object.result =
              $root.workflow_evaluation_results.v1.DestinationActionResult.toObject(
                message.result,
                options
              );
          }
          if (message.error != null && message.hasOwnProperty('error')) {
            object.error =
              $root.workflow_evaluation_results.v1.FormattedError.toObject(
                message.error,
                options
              );
          }
          if (
            message.filterOutcome != null &&
            message.hasOwnProperty('filterOutcome')
          ) {
            object.filterOutcome = message.filterOutcome;
          }
          if (
            message.maximumAttempts != null &&
            message.hasOwnProperty('maximumAttempts')
          ) {
            object.maximumAttempts = message.maximumAttempts;
          }
          if (
            message.isLastAttempt != null &&
            message.hasOwnProperty('isLastAttempt')
          ) {
            object.isLastAttempt = message.isLastAttempt;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return (
            typeUrlPrefix +
            'workflow_evaluation_results.v1.DestinationActionOutcome'
          );
        }
      }

      DestinationActionOutcome.prototype.activityDestinationApplicationType = 0;
      DestinationActionOutcome.prototype.destinationActionName = '';
      DestinationActionOutcome.prototype.workflowNodeId = '';
      DestinationActionOutcome.prototype.activityStartTime = null;
      DestinationActionOutcome.prototype.activityEndTime = null;
      DestinationActionOutcome.prototype.result = null;
      DestinationActionOutcome.prototype.error = null;
      DestinationActionOutcome.prototype.filterOutcome = null;
      DestinationActionOutcome.prototype.maximumAttempts = null;
      DestinationActionOutcome.prototype.isLastAttempt = false;

      return DestinationActionOutcome;
    })();

    v1.ErrorClass = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ILLEGAL_WORKFLOW_ERROR_CLASS')] = 0;
      values[(valuesById[1] = 'ERROR')] = 1;
      values[(valuesById[2] = 'WARNING')] = 2;
      values[(valuesById[4] = 'INTERNAL_ERROR')] = 4;
      return values;
    })();

    v1.KnownError = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_KNOWN_ERROR')] = 0;
      values[(valuesById[1] = 'KNOWN_ERROR_PROFILE_LOOKUP')] = 1;
      values[(valuesById[2] = 'KNOWN_ERROR_PARAMETERS_EVALUATION')] = 2;
      values[(valuesById[3] = 'KNOWN_ERROR_THIRD_PARTY_API')] = 3;
      values[(valuesById[4] = 'KNOWN_ERROR_FILTER_EVALUATION')] = 4;
      values[
        (valuesById[5] = 'KNOWN_ERROR_ASSOCIATE_THIRD_PARTY_IDS_FAILED')
      ] = 5;
      values[(valuesById[6] = 'KNOWN_ERROR_INVALID_PHONE_NUMBER')] = 6;
      values[(valuesById[7] = 'KNOWN_ERROR_THIRD_PARTY_ID_MISSING')] = 7;
      values[(valuesById[8] = 'KNOWN_ERROR_PROFILE_INDEX_CONFLICT')] = 8;
      values[(valuesById[9] = 'KNOWN_ERROR_EVENT_PAYLOAD_REFRESH_FAILED')] = 9;
      values[
        (valuesById[10] = 'KNOWN_ERROR_FETCH_ACTION_RESULT_SERIALIZATION_ERROR')
      ] = 10;
      values[
        (valuesById[11] = 'KNOWN_ERROR_NOT_AN_ACTIVE_WORKFLOW_ERROR')
      ] = 11;
      values[(valuesById[13] = 'KNOWN_ERROR_EVENT_SNAPSHOT_FETCH_FAILED')] = 13;
      values[(valuesById[14] = 'KNOWN_ERROR_ALREADY_EXISTS')] = 14;
      values[(valuesById[15] = 'KNOWN_ERROR_NO_CHANGES')] = 15;
      return values;
    })();

    v1.ErrorDecision = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ERROR_DECISION')] = 0;
      values[(valuesById[1] = 'ERROR_DECISION_CONTINUE_WORKFLOW')] = 1;
      values[(valuesById[2] = 'ERROR_DECISION_TERMINATE_BRANCH')] = 2;
      values[(valuesById[3] = 'ERROR_DECISION_TERMINATE_WORKFLOW')] = 3;
      return values;
    })();

    return v1;
  })();

  return workflow_evaluation_results;
})();

export const workflow_evaluation_results = $root.workflow_evaluation_results;
