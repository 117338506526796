import { Container } from '../Container';
import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Icon } from '../Icon';
import { Text } from '../Typography';
import { ToastProps } from './types';
import { ToastStyle } from './Toast.css';
import { convertToTitleCase } from '../Helpers/convertToTitleCase';
import { toast } from 'react-toastify';
import { useTheme } from '@morf/theming';

const Toast: FC<ToastProps> = ({ errorDetails, icon, message, type }) => {
  const theme = useTheme();
  const title = errorDetails
    ? convertToTitleCase(errorDetails.status)
    : message;

  let strokeColor;

  switch (type) {
    case 'inactive':
      strokeColor = theme.colors.ui.dark;
      break;
    case 'alert':
      strokeColor = theme.colors.support.red.darkest;
      break;
    case 'warning':
      strokeColor = theme.colors.support.yellow.darkest;
      break;
    case 'success':
      strokeColor = theme.colors.support.green.darkest;
      break;
    default:
      strokeColor = theme.colors.ui.dark;
      break;
  }

  const handleClose = () => toast.dismiss();

  return (
    <>
      <ToastStyle />
      <Flexbox
        data-testid='toast'
        direction='row'
        justifyContent='flex-start'
        alignItems='flex-start'
        shadow='base'
        height='auto'
        width='auto'
        maxWidth='25rem'
        backgroundColor={theme.colors.ui.card}
        borderRadius={1}
        p={1}
      >
        <Flexbox
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Container width='auto' height='auto'>
            <Icon name={icon} stroke={strokeColor} />
          </Container>

          <Flexbox
            direction='column'
            justifyContent='flex-start'
            alignItems='flex-start'
            gap={0}
          >
            <Text tag='p2'>{title}</Text>

            {errorDetails && (
              <Text tag='p3' color={theme.colors.text.muted}>
                {errorDetails.message}
              </Text>
            )}

            {errorDetails && errorDetails.requestId && (
              <Flexbox
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                pt={1}
                gap={0.25}
              >
                <Text tag='p3'>Request ID:</Text>
                <Text tag='p3' color={theme.colors.text.muted}>
                  {errorDetails.requestId}
                </Text>
              </Flexbox>
            )}
          </Flexbox>
        </Flexbox>

        {type === 'alert' && (
          <Container width='auto' height='auto' cursor='pointer'>
            <Icon
              name='close'
              stroke={theme.colors.ui.dark}
              onClick={handleClose}
              size={1.25}
            />
          </Container>
        )}
      </Flexbox>
    </>
  );
};

export const MemoizedToast = memo(Toast);
