'use strict';

import * as $protobuf from 'protobufjs/minimal';
import { timing as timing$1 } from './timing_v1_ts_proto';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.calendly = (() => {
  const calendly = {};
  calendly.v1 = (() => {
    const v1 = {};
    v1.SubscribedEventTypes = (() => {
      class SubscribedEventTypes {
        constructor(properties) {
          this.eventTypes = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SubscribedEventTypes(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.eventTypes != null &&
            Object.hasOwnProperty.call(message, 'eventTypes')
          ) {
            writer.uint32(10).fork();
            for (const element of message.eventTypes) {
              writer.int32(element);
            }
            writer.ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SubscribedEventTypes();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                if (!message.eventTypes || !message.eventTypes.length) {
                  message.eventTypes = [];
                }
                if ((tag & 7) === 2) {
                  let end2 = reader.uint32() + reader.pos;
                  while (reader.pos < end2)
                    message.eventTypes.push(reader.int32());
                } else {
                  message.eventTypes.push(reader.int32());
                }
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.SubscribedEventTypes) {
            return object;
          }
          const message = new $root.calendly.v1.SubscribedEventTypes();
          if (object.eventTypes) {
            if (!Array.isArray(object.eventTypes)) {
              throw new TypeError(
                '.calendly.v1.SubscribedEventTypes.eventTypes: array type expected, but got ' +
                  typeof object.eventTypes
              );
            }
            message.eventTypes = new Array(object.eventTypes.length);
            for (let i = 0; i < object.eventTypes.length; ++i) {
              switch (object.eventTypes[i]) {
                case 'UNSPECIFIED_ILLEGAL_CALENDLY_EVENT_TYPE':
                case 0: {
                  message.eventTypes[i] = 0;
                  break;
                }
                case 'CALENDLY_INVITEE_CREATED':
                case 1: {
                  message.eventTypes[i] = 1;
                  break;
                }
                case 'CALENDLY_INVITEE_CANCELED':
                case 2: {
                  message.eventTypes[i] = 2;
                  break;
                }
                case 'CALENDLY_INVITEE_NO_SHOW_CREATED':
                case 3: {
                  message.eventTypes[i] = 3;
                  break;
                }
                case 'CALENDLY_INVITEE_NO_SHOW_DELETED':
                case 4: {
                  message.eventTypes[i] = 4;
                  break;
                }
                case 'CALENDLY_ROUTING_FORM_SUBMISSION_CREATED':
                case 5: {
                  message.eventTypes[i] = 5;
                  break;
                }
                default: {
                  if (typeof object.eventTypes[i] == 'number') {
                    message.eventTypes[i] = object.eventTypes[i];
                    break;
                  }
                  message.eventTypes[i] = 0;
                  break;
                }
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventTypes = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (message.eventTypes && message.eventTypes.length) {
            object.eventTypes = new Array(message.eventTypes.length);
            for (let i = 0; i < message.eventTypes.length; ++i) {
              object.eventTypes[i] =
                options.enums === String
                  ? $root.calendly.v1.CalendlyEventType[
                      message.eventTypes[i]
                    ] === undefined
                    ? message.eventTypes[i]
                    : $root.calendly.v1.CalendlyEventType[message.eventTypes[i]]
                  : message.eventTypes[i];
            }
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.SubscribedEventTypes';
        }
      }

      SubscribedEventTypes.prototype.eventTypes = $util.emptyArray;

      return SubscribedEventTypes;
    })();

    v1.Invitee = (() => {
      class Invitee {
        constructor(properties) {
          this.questionsAndAnswers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Invitee(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.uri != null &&
            Object.hasOwnProperty.call(message, 'uri')
          ) {
            writer.uint32(10).string(message.uri);
          }
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(18).string(message.email);
          }
          if (
            message.firstName != null &&
            Object.hasOwnProperty.call(message, 'firstName')
          ) {
            writer.uint32(26).string(message.firstName);
          }
          if (
            message.lastName != null &&
            Object.hasOwnProperty.call(message, 'lastName')
          ) {
            writer.uint32(34).string(message.lastName);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(42).string(message.name);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(50).string(message.status);
          }
          if (
            message.questionsAndAnswers != null &&
            Object.hasOwnProperty.call(message, 'questionsAndAnswers')
          ) {
            for (const element of message.questionsAndAnswers) {
              $root.calendly.v1.QuestionAndAnswer.encode(
                element,
                writer.uint32(58).fork()
              ).ldelim();
            }
          }
          if (
            message.timezone != null &&
            Object.hasOwnProperty.call(message, 'timezone')
          ) {
            writer.uint32(66).string(message.timezone);
          }
          if (
            message.event != null &&
            Object.hasOwnProperty.call(message, 'event')
          ) {
            writer.uint32(74).string(message.event);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.tracking != null &&
            Object.hasOwnProperty.call(message, 'tracking')
          ) {
            $root.calendly.v1.InviteeTracking.encode(
              message.tracking,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.textReminderNumber != null &&
            Object.hasOwnProperty.call(message, 'textReminderNumber')
          ) {
            writer.uint32(106).string(message.textReminderNumber);
          }
          if (
            message.rescheduled != null &&
            Object.hasOwnProperty.call(message, 'rescheduled')
          ) {
            writer.uint32(112).bool(message.rescheduled);
          }
          if (
            message.oldInvitee != null &&
            Object.hasOwnProperty.call(message, 'oldInvitee')
          ) {
            writer.uint32(122).string(message.oldInvitee);
          }
          if (
            message.newInvitee != null &&
            Object.hasOwnProperty.call(message, 'newInvitee')
          ) {
            writer.uint32(130).string(message.newInvitee);
          }
          if (
            message.cancelUrl != null &&
            Object.hasOwnProperty.call(message, 'cancelUrl')
          ) {
            writer.uint32(138).string(message.cancelUrl);
          }
          if (
            message.rescheduleUrl != null &&
            Object.hasOwnProperty.call(message, 'rescheduleUrl')
          ) {
            writer.uint32(146).string(message.rescheduleUrl);
          }
          if (
            message.routingFormSubmission != null &&
            Object.hasOwnProperty.call(message, 'routingFormSubmission')
          ) {
            writer.uint32(154).string(message.routingFormSubmission);
          }
          if (
            message.cancellation != null &&
            Object.hasOwnProperty.call(message, 'cancellation')
          ) {
            $root.calendly.v1.Cancellation.encode(
              message.cancellation,
              writer.uint32(162).fork()
            ).ldelim();
          }
          if (
            message.payment != null &&
            Object.hasOwnProperty.call(message, 'payment')
          ) {
            $root.calendly.v1.Payment.encode(
              message.payment,
              writer.uint32(170).fork()
            ).ldelim();
          }
          if (
            message.noShow != null &&
            Object.hasOwnProperty.call(message, 'noShow')
          ) {
            $root.calendly.v1.NoShow.encode(
              message.noShow,
              writer.uint32(178).fork()
            ).ldelim();
          }
          if (
            message.reconfirmation != null &&
            Object.hasOwnProperty.call(message, 'reconfirmation')
          ) {
            $root.calendly.v1.Reconfirmation.encode(
              message.reconfirmation,
              writer.uint32(186).fork()
            ).ldelim();
          }
          if (
            message.schedulingMethod != null &&
            Object.hasOwnProperty.call(message, 'schedulingMethod')
          ) {
            writer.uint32(194).string(message.schedulingMethod);
          }
          if (
            message.inviteeScheduledBy != null &&
            Object.hasOwnProperty.call(message, 'inviteeScheduledBy')
          ) {
            writer.uint32(202).string(message.inviteeScheduledBy);
          }
          if (
            message.scheduledEvent != null &&
            Object.hasOwnProperty.call(message, 'scheduledEvent')
          ) {
            $root.calendly.v1.ScheduledEvent.encode(
              message.scheduledEvent,
              writer.uint32(210).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Invitee();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.uri = reader.string();
                break;
              }
              case 2: {
                message.email = reader.string();
                break;
              }
              case 3: {
                message.firstName = reader.string();
                break;
              }
              case 4: {
                message.lastName = reader.string();
                break;
              }
              case 5: {
                message.name = reader.string();
                break;
              }
              case 6: {
                message.status = reader.string();
                break;
              }
              case 7: {
                if (
                  !message.questionsAndAnswers ||
                  !message.questionsAndAnswers.length
                ) {
                  message.questionsAndAnswers = [];
                }
                message.questionsAndAnswers.push(
                  $root.calendly.v1.QuestionAndAnswer.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 8: {
                message.timezone = reader.string();
                break;
              }
              case 9: {
                message.event = reader.string();
                break;
              }
              case 10: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 11: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 12: {
                message.tracking = $root.calendly.v1.InviteeTracking.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 13: {
                message.textReminderNumber = reader.string();
                break;
              }
              case 14: {
                message.rescheduled = reader.bool();
                break;
              }
              case 15: {
                message.oldInvitee = reader.string();
                break;
              }
              case 16: {
                message.newInvitee = reader.string();
                break;
              }
              case 17: {
                message.cancelUrl = reader.string();
                break;
              }
              case 18: {
                message.rescheduleUrl = reader.string();
                break;
              }
              case 19: {
                message.routingFormSubmission = reader.string();
                break;
              }
              case 20: {
                message.cancellation = $root.calendly.v1.Cancellation.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 21: {
                message.payment = $root.calendly.v1.Payment.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 22: {
                message.noShow = $root.calendly.v1.NoShow.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 23: {
                message.reconfirmation =
                  $root.calendly.v1.Reconfirmation.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 24: {
                message.schedulingMethod = reader.string();
                break;
              }
              case 25: {
                message.inviteeScheduledBy = reader.string();
                break;
              }
              case 26: {
                message.scheduledEvent =
                  $root.calendly.v1.ScheduledEvent.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.Invitee) {
            return object;
          }
          const message = new $root.calendly.v1.Invitee();
          if (object.uri != null) {
            message.uri = String(object.uri);
          }
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.firstName != null) {
            message.firstName = String(object.firstName);
          }
          if (object.lastName != null) {
            message.lastName = String(object.lastName);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.questionsAndAnswers) {
            if (!Array.isArray(object.questionsAndAnswers)) {
              throw new TypeError(
                '.calendly.v1.Invitee.questionsAndAnswers: array type expected, but got ' +
                  typeof object.questionsAndAnswers
              );
            }
            message.questionsAndAnswers = new Array(
              object.questionsAndAnswers.length
            );
            for (let i = 0; i < object.questionsAndAnswers.length; ++i) {
              if (typeof object.questionsAndAnswers[i] !== 'object') {
                throw new TypeError(
                  '.calendly.v1.Invitee.questionsAndAnswers: object expected, but got ' +
                    typeof object.questionsAndAnswers[i]
                );
              }
              message.questionsAndAnswers[i] =
                $root.calendly.v1.QuestionAndAnswer.fromObject(
                  object.questionsAndAnswers[i]
                );
            }
          }
          if (object.timezone != null) {
            message.timezone = String(object.timezone);
          }
          if (object.event != null) {
            message.event = String(object.event);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.tracking != null) {
            if (typeof object.tracking !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.tracking: object expected, but got ' +
                  typeof object.tracking
              );
            }
            message.tracking = $root.calendly.v1.InviteeTracking.fromObject(
              object.tracking
            );
          }
          if (object.textReminderNumber != null) {
            message.textReminderNumber = String(object.textReminderNumber);
          }
          if (object.rescheduled != null) {
            message.rescheduled = Boolean(object.rescheduled);
          }
          if (object.oldInvitee != null) {
            message.oldInvitee = String(object.oldInvitee);
          }
          if (object.newInvitee != null) {
            message.newInvitee = String(object.newInvitee);
          }
          if (object.cancelUrl != null) {
            message.cancelUrl = String(object.cancelUrl);
          }
          if (object.rescheduleUrl != null) {
            message.rescheduleUrl = String(object.rescheduleUrl);
          }
          if (object.routingFormSubmission != null) {
            message.routingFormSubmission = String(
              object.routingFormSubmission
            );
          }
          if (object.cancellation != null) {
            if (typeof object.cancellation !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.cancellation: object expected, but got ' +
                  typeof object.cancellation
              );
            }
            message.cancellation = $root.calendly.v1.Cancellation.fromObject(
              object.cancellation
            );
          }
          if (object.payment != null) {
            if (typeof object.payment !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.payment: object expected, but got ' +
                  typeof object.payment
              );
            }
            message.payment = $root.calendly.v1.Payment.fromObject(
              object.payment
            );
          }
          if (object.noShow != null) {
            if (typeof object.noShow !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.noShow: object expected, but got ' +
                  typeof object.noShow
              );
            }
            message.noShow = $root.calendly.v1.NoShow.fromObject(object.noShow);
          }
          if (object.reconfirmation != null) {
            if (typeof object.reconfirmation !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.reconfirmation: object expected, but got ' +
                  typeof object.reconfirmation
              );
            }
            message.reconfirmation =
              $root.calendly.v1.Reconfirmation.fromObject(
                object.reconfirmation
              );
          }
          if (object.schedulingMethod != null) {
            message.schedulingMethod = String(object.schedulingMethod);
          }
          if (object.inviteeScheduledBy != null) {
            message.inviteeScheduledBy = String(object.inviteeScheduledBy);
          }
          if (object.scheduledEvent != null) {
            if (typeof object.scheduledEvent !== 'object') {
              throw new TypeError(
                '.calendly.v1.Invitee.scheduledEvent: object expected, but got ' +
                  typeof object.scheduledEvent
              );
            }
            message.scheduledEvent =
              $root.calendly.v1.ScheduledEvent.fromObject(
                object.scheduledEvent
              );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.questionsAndAnswers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.uri = '';
            object.email = '';
            object.name = '';
            object.status = '';
            object.event = '';
            object.createdAt = null;
            object.updatedAt = null;
            object.tracking = null;
            object.rescheduled = false;
            object.cancelUrl = '';
            object.rescheduleUrl = '';
            object.scheduledEvent = null;
          }
          let keys;
          if (message.uri != null && message.hasOwnProperty('uri')) {
            object.uri = message.uri;
          }
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (
            message.firstName != null &&
            message.hasOwnProperty('firstName')
          ) {
            object.firstName = message.firstName;
          }
          if (message.lastName != null && message.hasOwnProperty('lastName')) {
            object.lastName = message.lastName;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (
            message.questionsAndAnswers &&
            message.questionsAndAnswers.length
          ) {
            object.questionsAndAnswers = new Array(
              message.questionsAndAnswers.length
            );
            for (let i = 0; i < message.questionsAndAnswers.length; ++i) {
              object.questionsAndAnswers[i] =
                $root.calendly.v1.QuestionAndAnswer.toObject(
                  message.questionsAndAnswers[i],
                  options
                );
            }
          }
          if (message.timezone != null && message.hasOwnProperty('timezone')) {
            object.timezone = message.timezone;
          }
          if (message.event != null && message.hasOwnProperty('event')) {
            object.event = message.event;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (message.tracking != null && message.hasOwnProperty('tracking')) {
            object.tracking = $root.calendly.v1.InviteeTracking.toObject(
              message.tracking,
              options
            );
          }
          if (
            message.textReminderNumber != null &&
            message.hasOwnProperty('textReminderNumber')
          ) {
            object.textReminderNumber = message.textReminderNumber;
          }
          if (
            message.rescheduled != null &&
            message.hasOwnProperty('rescheduled')
          ) {
            object.rescheduled = message.rescheduled;
          }
          if (
            message.oldInvitee != null &&
            message.hasOwnProperty('oldInvitee')
          ) {
            object.oldInvitee = message.oldInvitee;
          }
          if (
            message.newInvitee != null &&
            message.hasOwnProperty('newInvitee')
          ) {
            object.newInvitee = message.newInvitee;
          }
          if (
            message.cancelUrl != null &&
            message.hasOwnProperty('cancelUrl')
          ) {
            object.cancelUrl = message.cancelUrl;
          }
          if (
            message.rescheduleUrl != null &&
            message.hasOwnProperty('rescheduleUrl')
          ) {
            object.rescheduleUrl = message.rescheduleUrl;
          }
          if (
            message.routingFormSubmission != null &&
            message.hasOwnProperty('routingFormSubmission')
          ) {
            object.routingFormSubmission = message.routingFormSubmission;
          }
          if (
            message.cancellation != null &&
            message.hasOwnProperty('cancellation')
          ) {
            object.cancellation = $root.calendly.v1.Cancellation.toObject(
              message.cancellation,
              options
            );
          }
          if (message.payment != null && message.hasOwnProperty('payment')) {
            object.payment = $root.calendly.v1.Payment.toObject(
              message.payment,
              options
            );
          }
          if (message.noShow != null && message.hasOwnProperty('noShow')) {
            object.noShow = $root.calendly.v1.NoShow.toObject(
              message.noShow,
              options
            );
          }
          if (
            message.reconfirmation != null &&
            message.hasOwnProperty('reconfirmation')
          ) {
            object.reconfirmation = $root.calendly.v1.Reconfirmation.toObject(
              message.reconfirmation,
              options
            );
          }
          if (
            message.schedulingMethod != null &&
            message.hasOwnProperty('schedulingMethod')
          ) {
            object.schedulingMethod = message.schedulingMethod;
          }
          if (
            message.inviteeScheduledBy != null &&
            message.hasOwnProperty('inviteeScheduledBy')
          ) {
            object.inviteeScheduledBy = message.inviteeScheduledBy;
          }
          if (
            message.scheduledEvent != null &&
            message.hasOwnProperty('scheduledEvent')
          ) {
            object.scheduledEvent = $root.calendly.v1.ScheduledEvent.toObject(
              message.scheduledEvent,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.Invitee';
        }
      }

      Invitee.prototype.uri = '';
      Invitee.prototype.email = '';
      Invitee.prototype.firstName = null;
      Invitee.prototype.lastName = null;
      Invitee.prototype.name = '';
      Invitee.prototype.status = '';
      Invitee.prototype.questionsAndAnswers = $util.emptyArray;
      Invitee.prototype.timezone = null;
      Invitee.prototype.event = '';
      Invitee.prototype.createdAt = null;
      Invitee.prototype.updatedAt = null;
      Invitee.prototype.tracking = null;
      Invitee.prototype.textReminderNumber = null;
      Invitee.prototype.rescheduled = false;
      Invitee.prototype.oldInvitee = null;
      Invitee.prototype.newInvitee = null;
      Invitee.prototype.cancelUrl = '';
      Invitee.prototype.rescheduleUrl = '';
      Invitee.prototype.routingFormSubmission = null;
      Invitee.prototype.cancellation = null;
      Invitee.prototype.payment = null;
      Invitee.prototype.noShow = null;
      Invitee.prototype.reconfirmation = null;
      Invitee.prototype.schedulingMethod = null;
      Invitee.prototype.inviteeScheduledBy = null;
      Invitee.prototype.scheduledEvent = null;

      return Invitee;
    })();

    v1.RoutingFormSubmission = (() => {
      class RoutingFormSubmission {
        constructor(properties) {
          this.questionsAndAnswers = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new RoutingFormSubmission(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.uri != null &&
            Object.hasOwnProperty.call(message, 'uri')
          ) {
            writer.uint32(10).string(message.uri);
          }
          if (
            message.routingForm != null &&
            Object.hasOwnProperty.call(message, 'routingForm')
          ) {
            writer.uint32(18).string(message.routingForm);
          }
          if (
            message.questionsAndAnswers != null &&
            Object.hasOwnProperty.call(message, 'questionsAndAnswers')
          ) {
            for (const element of message.questionsAndAnswers) {
              $root.calendly.v1.QuestionAndAnswer.encode(
                element,
                writer.uint32(26).fork()
              ).ldelim();
            }
          }
          if (
            message.tracking != null &&
            Object.hasOwnProperty.call(message, 'tracking')
          ) {
            $root.calendly.v1.InviteeTracking.encode(
              message.tracking,
              writer.uint32(34).fork()
            ).ldelim();
          }
          if (
            message.submitter != null &&
            Object.hasOwnProperty.call(message, 'submitter')
          ) {
            writer.uint32(42).string(message.submitter);
          }
          if (
            message.submitterType != null &&
            Object.hasOwnProperty.call(message, 'submitterType')
          ) {
            writer.uint32(50).string(message.submitterType);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(66).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new RoutingFormSubmission();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.uri = reader.string();
                break;
              }
              case 2: {
                message.routingForm = reader.string();
                break;
              }
              case 3: {
                if (
                  !message.questionsAndAnswers ||
                  !message.questionsAndAnswers.length
                ) {
                  message.questionsAndAnswers = [];
                }
                message.questionsAndAnswers.push(
                  $root.calendly.v1.QuestionAndAnswer.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 4: {
                message.tracking = $root.calendly.v1.InviteeTracking.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 5: {
                message.submitter = reader.string();
                break;
              }
              case 6: {
                message.submitterType = reader.string();
                break;
              }
              case 7: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.RoutingFormSubmission) {
            return object;
          }
          const message = new $root.calendly.v1.RoutingFormSubmission();
          if (object.uri != null) {
            message.uri = String(object.uri);
          }
          if (object.routingForm != null) {
            message.routingForm = String(object.routingForm);
          }
          if (object.questionsAndAnswers) {
            if (!Array.isArray(object.questionsAndAnswers)) {
              throw new TypeError(
                '.calendly.v1.RoutingFormSubmission.questionsAndAnswers: array type expected, but got ' +
                  typeof object.questionsAndAnswers
              );
            }
            message.questionsAndAnswers = new Array(
              object.questionsAndAnswers.length
            );
            for (let i = 0; i < object.questionsAndAnswers.length; ++i) {
              if (typeof object.questionsAndAnswers[i] !== 'object') {
                throw new TypeError(
                  '.calendly.v1.RoutingFormSubmission.questionsAndAnswers: object expected, but got ' +
                    typeof object.questionsAndAnswers[i]
                );
              }
              message.questionsAndAnswers[i] =
                $root.calendly.v1.QuestionAndAnswer.fromObject(
                  object.questionsAndAnswers[i]
                );
            }
          }
          if (object.tracking != null) {
            if (typeof object.tracking !== 'object') {
              throw new TypeError(
                '.calendly.v1.RoutingFormSubmission.tracking: object expected, but got ' +
                  typeof object.tracking
              );
            }
            message.tracking = $root.calendly.v1.InviteeTracking.fromObject(
              object.tracking
            );
          }
          if (object.submitter != null) {
            message.submitter = String(object.submitter);
          }
          if (object.submitterType != null) {
            message.submitterType = String(object.submitterType);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.RoutingFormSubmission.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.RoutingFormSubmission.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.questionsAndAnswers = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.uri = '';
            object.routingForm = '';
            object.tracking = null;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.uri != null && message.hasOwnProperty('uri')) {
            object.uri = message.uri;
          }
          if (
            message.routingForm != null &&
            message.hasOwnProperty('routingForm')
          ) {
            object.routingForm = message.routingForm;
          }
          if (
            message.questionsAndAnswers &&
            message.questionsAndAnswers.length
          ) {
            object.questionsAndAnswers = new Array(
              message.questionsAndAnswers.length
            );
            for (let i = 0; i < message.questionsAndAnswers.length; ++i) {
              object.questionsAndAnswers[i] =
                $root.calendly.v1.QuestionAndAnswer.toObject(
                  message.questionsAndAnswers[i],
                  options
                );
            }
          }
          if (message.tracking != null && message.hasOwnProperty('tracking')) {
            object.tracking = $root.calendly.v1.InviteeTracking.toObject(
              message.tracking,
              options
            );
          }
          if (
            message.submitter != null &&
            message.hasOwnProperty('submitter')
          ) {
            object.submitter = message.submitter;
          }
          if (
            message.submitterType != null &&
            message.hasOwnProperty('submitterType')
          ) {
            object.submitterType = message.submitterType;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.RoutingFormSubmission';
        }
      }

      RoutingFormSubmission.prototype.uri = '';
      RoutingFormSubmission.prototype.routingForm = '';
      RoutingFormSubmission.prototype.questionsAndAnswers = $util.emptyArray;
      RoutingFormSubmission.prototype.tracking = null;
      RoutingFormSubmission.prototype.submitter = null;
      RoutingFormSubmission.prototype.submitterType = null;
      RoutingFormSubmission.prototype.createdAt = null;
      RoutingFormSubmission.prototype.updatedAt = null;

      return RoutingFormSubmission;
    })();

    v1.QuestionAndAnswer = (() => {
      class QuestionAndAnswer {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new QuestionAndAnswer(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.question != null &&
            Object.hasOwnProperty.call(message, 'question')
          ) {
            writer.uint32(10).string(message.question);
          }
          if (
            message.answer != null &&
            Object.hasOwnProperty.call(message, 'answer')
          ) {
            writer.uint32(18).string(message.answer);
          }
          if (
            message.position != null &&
            Object.hasOwnProperty.call(message, 'position')
          ) {
            writer.uint32(25).double(message.position);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new QuestionAndAnswer();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.question = reader.string();
                break;
              }
              case 2: {
                message.answer = reader.string();
                break;
              }
              case 3: {
                message.position = reader.double();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.QuestionAndAnswer) {
            return object;
          }
          const message = new $root.calendly.v1.QuestionAndAnswer();
          if (object.question != null) {
            message.question = String(object.question);
          }
          if (object.answer != null) {
            message.answer = String(object.answer);
          }
          if (object.position != null) {
            message.position = Number(object.position);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.question = '';
            object.answer = '';
            object.position = 0;
          }
          let keys;
          if (message.question != null && message.hasOwnProperty('question')) {
            object.question = message.question;
          }
          if (message.answer != null && message.hasOwnProperty('answer')) {
            object.answer = message.answer;
          }
          if (message.position != null && message.hasOwnProperty('position')) {
            object.position =
              options.json && !isFinite(message.position)
                ? String(message.position)
                : message.position;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.QuestionAndAnswer';
        }
      }

      QuestionAndAnswer.prototype.question = '';
      QuestionAndAnswer.prototype.answer = '';
      QuestionAndAnswer.prototype.position = 0;

      return QuestionAndAnswer;
    })();

    v1.InviteeTracking = (() => {
      class InviteeTracking {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InviteeTracking(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.utmCampaign != null &&
            Object.hasOwnProperty.call(message, 'utmCampaign')
          ) {
            writer.uint32(10).string(message.utmCampaign);
          }
          if (
            message.utmSource != null &&
            Object.hasOwnProperty.call(message, 'utmSource')
          ) {
            writer.uint32(18).string(message.utmSource);
          }
          if (
            message.utmMedium != null &&
            Object.hasOwnProperty.call(message, 'utmMedium')
          ) {
            writer.uint32(26).string(message.utmMedium);
          }
          if (
            message.utmContent != null &&
            Object.hasOwnProperty.call(message, 'utmContent')
          ) {
            writer.uint32(34).string(message.utmContent);
          }
          if (
            message.utmTerm != null &&
            Object.hasOwnProperty.call(message, 'utmTerm')
          ) {
            writer.uint32(42).string(message.utmTerm);
          }
          if (
            message.salesforceUuid != null &&
            Object.hasOwnProperty.call(message, 'salesforceUuid')
          ) {
            writer.uint32(50).string(message.salesforceUuid);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InviteeTracking();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.utmCampaign = reader.string();
                break;
              }
              case 2: {
                message.utmSource = reader.string();
                break;
              }
              case 3: {
                message.utmMedium = reader.string();
                break;
              }
              case 4: {
                message.utmContent = reader.string();
                break;
              }
              case 5: {
                message.utmTerm = reader.string();
                break;
              }
              case 6: {
                message.salesforceUuid = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.InviteeTracking) {
            return object;
          }
          const message = new $root.calendly.v1.InviteeTracking();
          if (object.utmCampaign != null) {
            message.utmCampaign = String(object.utmCampaign);
          }
          if (object.utmSource != null) {
            message.utmSource = String(object.utmSource);
          }
          if (object.utmMedium != null) {
            message.utmMedium = String(object.utmMedium);
          }
          if (object.utmContent != null) {
            message.utmContent = String(object.utmContent);
          }
          if (object.utmTerm != null) {
            message.utmTerm = String(object.utmTerm);
          }
          if (object.salesforceUuid != null) {
            message.salesforceUuid = String(object.salesforceUuid);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
          }
          let keys;
          if (
            message.utmCampaign != null &&
            message.hasOwnProperty('utmCampaign')
          ) {
            object.utmCampaign = message.utmCampaign;
          }
          if (
            message.utmSource != null &&
            message.hasOwnProperty('utmSource')
          ) {
            object.utmSource = message.utmSource;
          }
          if (
            message.utmMedium != null &&
            message.hasOwnProperty('utmMedium')
          ) {
            object.utmMedium = message.utmMedium;
          }
          if (
            message.utmContent != null &&
            message.hasOwnProperty('utmContent')
          ) {
            object.utmContent = message.utmContent;
          }
          if (message.utmTerm != null && message.hasOwnProperty('utmTerm')) {
            object.utmTerm = message.utmTerm;
          }
          if (
            message.salesforceUuid != null &&
            message.hasOwnProperty('salesforceUuid')
          ) {
            object.salesforceUuid = message.salesforceUuid;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.InviteeTracking';
        }
      }

      InviteeTracking.prototype.utmCampaign = null;
      InviteeTracking.prototype.utmSource = null;
      InviteeTracking.prototype.utmMedium = null;
      InviteeTracking.prototype.utmContent = null;
      InviteeTracking.prototype.utmTerm = null;
      InviteeTracking.prototype.salesforceUuid = null;

      return InviteeTracking;
    })();

    v1.Payment = (() => {
      class Payment {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Payment(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.externalId != null &&
            Object.hasOwnProperty.call(message, 'externalId')
          ) {
            writer.uint32(10).string(message.externalId);
          }
          if (
            message.provider != null &&
            Object.hasOwnProperty.call(message, 'provider')
          ) {
            writer.uint32(18).string(message.provider);
          }
          if (
            message.amount != null &&
            Object.hasOwnProperty.call(message, 'amount')
          ) {
            writer.uint32(25).double(message.amount);
          }
          if (
            message.currency != null &&
            Object.hasOwnProperty.call(message, 'currency')
          ) {
            writer.uint32(34).string(message.currency);
          }
          if (
            message.terms != null &&
            Object.hasOwnProperty.call(message, 'terms')
          ) {
            writer.uint32(42).string(message.terms);
          }
          if (
            message.successful != null &&
            Object.hasOwnProperty.call(message, 'successful')
          ) {
            writer.uint32(48).bool(message.successful);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Payment();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.externalId = reader.string();
                break;
              }
              case 2: {
                message.provider = reader.string();
                break;
              }
              case 3: {
                message.amount = reader.double();
                break;
              }
              case 4: {
                message.currency = reader.string();
                break;
              }
              case 5: {
                message.terms = reader.string();
                break;
              }
              case 6: {
                message.successful = reader.bool();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.Payment) {
            return object;
          }
          const message = new $root.calendly.v1.Payment();
          if (object.externalId != null) {
            message.externalId = String(object.externalId);
          }
          if (object.provider != null) {
            message.provider = String(object.provider);
          }
          if (object.amount != null) {
            message.amount = Number(object.amount);
          }
          if (object.currency != null) {
            message.currency = String(object.currency);
          }
          if (object.terms != null) {
            message.terms = String(object.terms);
          }
          if (object.successful != null) {
            message.successful = Boolean(object.successful);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.externalId = '';
            object.provider = '';
            object.amount = 0;
            object.currency = '';
            object.successful = false;
          }
          let keys;
          if (
            message.externalId != null &&
            message.hasOwnProperty('externalId')
          ) {
            object.externalId = message.externalId;
          }
          if (message.provider != null && message.hasOwnProperty('provider')) {
            object.provider = message.provider;
          }
          if (message.amount != null && message.hasOwnProperty('amount')) {
            object.amount =
              options.json && !isFinite(message.amount)
                ? String(message.amount)
                : message.amount;
          }
          if (message.currency != null && message.hasOwnProperty('currency')) {
            object.currency = message.currency;
          }
          if (message.terms != null && message.hasOwnProperty('terms')) {
            object.terms = message.terms;
          }
          if (
            message.successful != null &&
            message.hasOwnProperty('successful')
          ) {
            object.successful = message.successful;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.Payment';
        }
      }

      Payment.prototype.externalId = '';
      Payment.prototype.provider = '';
      Payment.prototype.amount = 0;
      Payment.prototype.currency = '';
      Payment.prototype.terms = null;
      Payment.prototype.successful = false;

      return Payment;
    })();

    v1.NoShow = (() => {
      class NoShow {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new NoShow(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.uri != null &&
            Object.hasOwnProperty.call(message, 'uri')
          ) {
            writer.uint32(10).string(message.uri);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new NoShow();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.uri = reader.string();
                break;
              }
              case 2: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.NoShow) {
            return object;
          }
          const message = new $root.calendly.v1.NoShow();
          if (object.uri != null) {
            message.uri = String(object.uri);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.NoShow.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.uri = '';
            object.createdAt = null;
          }
          let keys;
          if (message.uri != null && message.hasOwnProperty('uri')) {
            object.uri = message.uri;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.NoShow';
        }
      }

      NoShow.prototype.uri = '';
      NoShow.prototype.createdAt = null;

      return NoShow;
    })();

    v1.Reconfirmation = (() => {
      class Reconfirmation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Reconfirmation(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(10).fork()
            ).ldelim();
          }
          if (
            message.confirmedAt != null &&
            Object.hasOwnProperty.call(message, 'confirmedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.confirmedAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Reconfirmation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 2: {
                message.confirmedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.Reconfirmation) {
            return object;
          }
          const message = new $root.calendly.v1.Reconfirmation();
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Reconfirmation.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.confirmedAt != null) {
            if (typeof object.confirmedAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Reconfirmation.confirmedAt: object expected, but got ' +
                  typeof object.confirmedAt
              );
            }
            message.confirmedAt = timing$1.v1.Timestamp.fromObject(
              object.confirmedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.createdAt = null;
          }
          let keys;
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.confirmedAt != null &&
            message.hasOwnProperty('confirmedAt')
          ) {
            object.confirmedAt = timing$1.v1.Timestamp.toObject(
              message.confirmedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.Reconfirmation';
        }
      }

      Reconfirmation.prototype.createdAt = null;
      Reconfirmation.prototype.confirmedAt = null;

      return Reconfirmation;
    })();

    v1.ScheduledEvent = (() => {
      class ScheduledEvent {
        constructor(properties) {
          this.eventMemberships = [];
          this.eventGuests = [];
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new ScheduledEvent(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.uri != null &&
            Object.hasOwnProperty.call(message, 'uri')
          ) {
            writer.uint32(10).string(message.uri);
          }
          if (
            message.name != null &&
            Object.hasOwnProperty.call(message, 'name')
          ) {
            writer.uint32(18).string(message.name);
          }
          if (
            message.meetingNotesPlain != null &&
            Object.hasOwnProperty.call(message, 'meetingNotesPlain')
          ) {
            writer.uint32(26).string(message.meetingNotesPlain);
          }
          if (
            message.meetingNotesHtml != null &&
            Object.hasOwnProperty.call(message, 'meetingNotesHtml')
          ) {
            writer.uint32(34).string(message.meetingNotesHtml);
          }
          if (
            message.status != null &&
            Object.hasOwnProperty.call(message, 'status')
          ) {
            writer.uint32(42).string(message.status);
          }
          if (
            message.startTime != null &&
            Object.hasOwnProperty.call(message, 'startTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.startTime,
              writer.uint32(50).fork()
            ).ldelim();
          }
          if (
            message.endTime != null &&
            Object.hasOwnProperty.call(message, 'endTime')
          ) {
            timing$1.v1.Timestamp.encode(
              message.endTime,
              writer.uint32(58).fork()
            ).ldelim();
          }
          if (
            message.eventType != null &&
            Object.hasOwnProperty.call(message, 'eventType')
          ) {
            writer.uint32(66).string(message.eventType);
          }
          if (
            message.location != null &&
            Object.hasOwnProperty.call(message, 'location')
          ) {
            $root.calendly.v1.Location.encode(
              message.location,
              writer.uint32(74).fork()
            ).ldelim();
          }
          if (
            message.inviteesCounter != null &&
            Object.hasOwnProperty.call(message, 'inviteesCounter')
          ) {
            $root.calendly.v1.InviteesCounter.encode(
              message.inviteesCounter,
              writer.uint32(82).fork()
            ).ldelim();
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(90).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(98).fork()
            ).ldelim();
          }
          if (
            message.eventMemberships != null &&
            Object.hasOwnProperty.call(message, 'eventMemberships')
          ) {
            for (const element of message.eventMemberships) {
              $root.calendly.v1.EventMembership.encode(
                element,
                writer.uint32(106).fork()
              ).ldelim();
            }
          }
          if (
            message.eventGuests != null &&
            Object.hasOwnProperty.call(message, 'eventGuests')
          ) {
            for (const element of message.eventGuests) {
              $root.calendly.v1.Guest.encode(
                element,
                writer.uint32(114).fork()
              ).ldelim();
            }
          }
          if (
            message.cancellation != null &&
            Object.hasOwnProperty.call(message, 'cancellation')
          ) {
            $root.calendly.v1.Cancellation.encode(
              message.cancellation,
              writer.uint32(122).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new ScheduledEvent();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.uri = reader.string();
                break;
              }
              case 2: {
                message.name = reader.string();
                break;
              }
              case 3: {
                message.meetingNotesPlain = reader.string();
                break;
              }
              case 4: {
                message.meetingNotesHtml = reader.string();
                break;
              }
              case 5: {
                message.status = reader.string();
                break;
              }
              case 6: {
                message.startTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 7: {
                message.endTime = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 8: {
                message.eventType = reader.string();
                break;
              }
              case 9: {
                message.location = $root.calendly.v1.Location.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 10: {
                message.inviteesCounter =
                  $root.calendly.v1.InviteesCounter.decode(
                    reader,
                    reader.uint32()
                  );
                break;
              }
              case 11: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 12: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 13: {
                if (
                  !message.eventMemberships ||
                  !message.eventMemberships.length
                ) {
                  message.eventMemberships = [];
                }
                message.eventMemberships.push(
                  $root.calendly.v1.EventMembership.decode(
                    reader,
                    reader.uint32()
                  )
                );
                break;
              }
              case 14: {
                if (!message.eventGuests || !message.eventGuests.length) {
                  message.eventGuests = [];
                }
                message.eventGuests.push(
                  $root.calendly.v1.Guest.decode(reader, reader.uint32())
                );
                break;
              }
              case 15: {
                message.cancellation = $root.calendly.v1.Cancellation.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.ScheduledEvent) {
            return object;
          }
          const message = new $root.calendly.v1.ScheduledEvent();
          if (object.uri != null) {
            message.uri = String(object.uri);
          }
          if (object.name != null) {
            message.name = String(object.name);
          }
          if (object.meetingNotesPlain != null) {
            message.meetingNotesPlain = String(object.meetingNotesPlain);
          }
          if (object.meetingNotesHtml != null) {
            message.meetingNotesHtml = String(object.meetingNotesHtml);
          }
          if (object.status != null) {
            message.status = String(object.status);
          }
          if (object.startTime != null) {
            if (typeof object.startTime !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.startTime: object expected, but got ' +
                  typeof object.startTime
              );
            }
            message.startTime = timing$1.v1.Timestamp.fromObject(
              object.startTime
            );
          }
          if (object.endTime != null) {
            if (typeof object.endTime !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.endTime: object expected, but got ' +
                  typeof object.endTime
              );
            }
            message.endTime = timing$1.v1.Timestamp.fromObject(object.endTime);
          }
          if (object.eventType != null) {
            message.eventType = String(object.eventType);
          }
          if (object.location != null) {
            if (typeof object.location !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.location: object expected, but got ' +
                  typeof object.location
              );
            }
            message.location = $root.calendly.v1.Location.fromObject(
              object.location
            );
          }
          if (object.inviteesCounter != null) {
            if (typeof object.inviteesCounter !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.inviteesCounter: object expected, but got ' +
                  typeof object.inviteesCounter
              );
            }
            message.inviteesCounter =
              $root.calendly.v1.InviteesCounter.fromObject(
                object.inviteesCounter
              );
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          if (object.eventMemberships) {
            if (!Array.isArray(object.eventMemberships)) {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.eventMemberships: array type expected, but got ' +
                  typeof object.eventMemberships
              );
            }
            message.eventMemberships = new Array(
              object.eventMemberships.length
            );
            for (let i = 0; i < object.eventMemberships.length; ++i) {
              if (typeof object.eventMemberships[i] !== 'object') {
                throw new TypeError(
                  '.calendly.v1.ScheduledEvent.eventMemberships: object expected, but got ' +
                    typeof object.eventMemberships[i]
                );
              }
              message.eventMemberships[i] =
                $root.calendly.v1.EventMembership.fromObject(
                  object.eventMemberships[i]
                );
            }
          }
          if (object.eventGuests) {
            if (!Array.isArray(object.eventGuests)) {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.eventGuests: array type expected, but got ' +
                  typeof object.eventGuests
              );
            }
            message.eventGuests = new Array(object.eventGuests.length);
            for (let i = 0; i < object.eventGuests.length; ++i) {
              if (typeof object.eventGuests[i] !== 'object') {
                throw new TypeError(
                  '.calendly.v1.ScheduledEvent.eventGuests: object expected, but got ' +
                    typeof object.eventGuests[i]
                );
              }
              message.eventGuests[i] = $root.calendly.v1.Guest.fromObject(
                object.eventGuests[i]
              );
            }
          }
          if (object.cancellation != null) {
            if (typeof object.cancellation !== 'object') {
              throw new TypeError(
                '.calendly.v1.ScheduledEvent.cancellation: object expected, but got ' +
                  typeof object.cancellation
              );
            }
            message.cancellation = $root.calendly.v1.Cancellation.fromObject(
              object.cancellation
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
            object.eventMemberships = [];
            object.eventGuests = [];
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.uri = '';
            object.status = '';
            object.startTime = null;
            object.endTime = null;
            object.eventType = '';
            object.location = null;
            object.inviteesCounter = null;
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.uri != null && message.hasOwnProperty('uri')) {
            object.uri = message.uri;
          }
          if (message.name != null && message.hasOwnProperty('name')) {
            object.name = message.name;
          }
          if (
            message.meetingNotesPlain != null &&
            message.hasOwnProperty('meetingNotesPlain')
          ) {
            object.meetingNotesPlain = message.meetingNotesPlain;
          }
          if (
            message.meetingNotesHtml != null &&
            message.hasOwnProperty('meetingNotesHtml')
          ) {
            object.meetingNotesHtml = message.meetingNotesHtml;
          }
          if (message.status != null && message.hasOwnProperty('status')) {
            object.status = message.status;
          }
          if (
            message.startTime != null &&
            message.hasOwnProperty('startTime')
          ) {
            object.startTime = timing$1.v1.Timestamp.toObject(
              message.startTime,
              options
            );
          }
          if (message.endTime != null && message.hasOwnProperty('endTime')) {
            object.endTime = timing$1.v1.Timestamp.toObject(
              message.endTime,
              options
            );
          }
          if (
            message.eventType != null &&
            message.hasOwnProperty('eventType')
          ) {
            object.eventType = message.eventType;
          }
          if (message.location != null && message.hasOwnProperty('location')) {
            object.location = $root.calendly.v1.Location.toObject(
              message.location,
              options
            );
          }
          if (
            message.inviteesCounter != null &&
            message.hasOwnProperty('inviteesCounter')
          ) {
            object.inviteesCounter = $root.calendly.v1.InviteesCounter.toObject(
              message.inviteesCounter,
              options
            );
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          if (message.eventMemberships && message.eventMemberships.length) {
            object.eventMemberships = new Array(
              message.eventMemberships.length
            );
            for (let i = 0; i < message.eventMemberships.length; ++i) {
              object.eventMemberships[i] =
                $root.calendly.v1.EventMembership.toObject(
                  message.eventMemberships[i],
                  options
                );
            }
          }
          if (message.eventGuests && message.eventGuests.length) {
            object.eventGuests = new Array(message.eventGuests.length);
            for (let i = 0; i < message.eventGuests.length; ++i) {
              object.eventGuests[i] = $root.calendly.v1.Guest.toObject(
                message.eventGuests[i],
                options
              );
            }
          }
          if (
            message.cancellation != null &&
            message.hasOwnProperty('cancellation')
          ) {
            object.cancellation = $root.calendly.v1.Cancellation.toObject(
              message.cancellation,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.ScheduledEvent';
        }
      }

      ScheduledEvent.prototype.uri = '';
      ScheduledEvent.prototype.name = null;
      ScheduledEvent.prototype.meetingNotesPlain = null;
      ScheduledEvent.prototype.meetingNotesHtml = null;
      ScheduledEvent.prototype.status = '';
      ScheduledEvent.prototype.startTime = null;
      ScheduledEvent.prototype.endTime = null;
      ScheduledEvent.prototype.eventType = '';
      ScheduledEvent.prototype.location = null;
      ScheduledEvent.prototype.inviteesCounter = null;
      ScheduledEvent.prototype.createdAt = null;
      ScheduledEvent.prototype.updatedAt = null;
      ScheduledEvent.prototype.eventMemberships = $util.emptyArray;
      ScheduledEvent.prototype.eventGuests = $util.emptyArray;
      ScheduledEvent.prototype.cancellation = null;

      return ScheduledEvent;
    })();

    v1.Location = (() => {
      class Location {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Location(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.type != null &&
            Object.hasOwnProperty.call(message, 'type')
          ) {
            writer.uint32(10).string(message.type);
          }
          if (
            message.location != null &&
            Object.hasOwnProperty.call(message, 'location')
          ) {
            writer.uint32(18).string(message.location);
          }
          if (
            message.additionalInfo != null &&
            Object.hasOwnProperty.call(message, 'additionalInfo')
          ) {
            writer.uint32(26).string(message.additionalInfo);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Location();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.type = reader.string();
                break;
              }
              case 2: {
                message.location = reader.string();
                break;
              }
              case 3: {
                message.additionalInfo = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.Location) {
            return object;
          }
          const message = new $root.calendly.v1.Location();
          if (object.type != null) {
            message.type = String(object.type);
          }
          if (object.location != null) {
            message.location = String(object.location);
          }
          if (object.additionalInfo != null) {
            message.additionalInfo = String(object.additionalInfo);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.type = '';
            object.location = '';
            object.additionalInfo = '';
          }
          let keys;
          if (message.type != null && message.hasOwnProperty('type')) {
            object.type = message.type;
          }
          if (message.location != null && message.hasOwnProperty('location')) {
            object.location = message.location;
          }
          if (
            message.additionalInfo != null &&
            message.hasOwnProperty('additionalInfo')
          ) {
            object.additionalInfo = message.additionalInfo;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.Location';
        }
      }

      Location.prototype.type = '';
      Location.prototype.location = '';
      Location.prototype.additionalInfo = '';

      return Location;
    })();

    v1.InviteesCounter = (() => {
      class InviteesCounter {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new InviteesCounter(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.total != null &&
            Object.hasOwnProperty.call(message, 'total')
          ) {
            writer.uint32(8).int32(message.total);
          }
          if (
            message.active != null &&
            Object.hasOwnProperty.call(message, 'active')
          ) {
            writer.uint32(16).int32(message.active);
          }
          if (
            message.limit != null &&
            Object.hasOwnProperty.call(message, 'limit')
          ) {
            writer.uint32(24).int32(message.limit);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new InviteesCounter();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.total = reader.int32();
                break;
              }
              case 2: {
                message.active = reader.int32();
                break;
              }
              case 3: {
                message.limit = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.InviteesCounter) {
            return object;
          }
          const message = new $root.calendly.v1.InviteesCounter();
          if (object.total != null) {
            message.total = object.total | 0;
          }
          if (object.active != null) {
            message.active = object.active | 0;
          }
          if (object.limit != null) {
            message.limit = object.limit | 0;
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.total = 0;
            object.active = 0;
            object.limit = 0;
          }
          let keys;
          if (message.total != null && message.hasOwnProperty('total')) {
            object.total = message.total;
          }
          if (message.active != null && message.hasOwnProperty('active')) {
            object.active = message.active;
          }
          if (message.limit != null && message.hasOwnProperty('limit')) {
            object.limit = message.limit;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.InviteesCounter';
        }
      }

      InviteesCounter.prototype.total = 0;
      InviteesCounter.prototype.active = 0;
      InviteesCounter.prototype.limit = 0;

      return InviteesCounter;
    })();

    v1.EventMembership = (() => {
      class EventMembership {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new EventMembership(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.user != null &&
            Object.hasOwnProperty.call(message, 'user')
          ) {
            writer.uint32(10).string(message.user);
          }
          if (
            message.userEmail != null &&
            Object.hasOwnProperty.call(message, 'userEmail')
          ) {
            writer.uint32(18).string(message.userEmail);
          }
          if (
            message.userName != null &&
            Object.hasOwnProperty.call(message, 'userName')
          ) {
            writer.uint32(26).string(message.userName);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new EventMembership();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.user = reader.string();
                break;
              }
              case 2: {
                message.userEmail = reader.string();
                break;
              }
              case 3: {
                message.userName = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.EventMembership) {
            return object;
          }
          const message = new $root.calendly.v1.EventMembership();
          if (object.user != null) {
            message.user = String(object.user);
          }
          if (object.userEmail != null) {
            message.userEmail = String(object.userEmail);
          }
          if (object.userName != null) {
            message.userName = String(object.userName);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.user = '';
            object.userEmail = '';
            object.userName = '';
          }
          let keys;
          if (message.user != null && message.hasOwnProperty('user')) {
            object.user = message.user;
          }
          if (
            message.userEmail != null &&
            message.hasOwnProperty('userEmail')
          ) {
            object.userEmail = message.userEmail;
          }
          if (message.userName != null && message.hasOwnProperty('userName')) {
            object.userName = message.userName;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.EventMembership';
        }
      }

      EventMembership.prototype.user = '';
      EventMembership.prototype.userEmail = '';
      EventMembership.prototype.userName = '';

      return EventMembership;
    })();

    v1.Guest = (() => {
      class Guest {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Guest(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.email != null &&
            Object.hasOwnProperty.call(message, 'email')
          ) {
            writer.uint32(10).string(message.email);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(18).fork()
            ).ldelim();
          }
          if (
            message.updatedAt != null &&
            Object.hasOwnProperty.call(message, 'updatedAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.updatedAt,
              writer.uint32(26).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Guest();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.email = reader.string();
                break;
              }
              case 2: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              case 3: {
                message.updatedAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.Guest) {
            return object;
          }
          const message = new $root.calendly.v1.Guest();
          if (object.email != null) {
            message.email = String(object.email);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Guest.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          if (object.updatedAt != null) {
            if (typeof object.updatedAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Guest.updatedAt: object expected, but got ' +
                  typeof object.updatedAt
              );
            }
            message.updatedAt = timing$1.v1.Timestamp.fromObject(
              object.updatedAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.email = '';
            object.createdAt = null;
            object.updatedAt = null;
          }
          let keys;
          if (message.email != null && message.hasOwnProperty('email')) {
            object.email = message.email;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          if (
            message.updatedAt != null &&
            message.hasOwnProperty('updatedAt')
          ) {
            object.updatedAt = timing$1.v1.Timestamp.toObject(
              message.updatedAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.Guest';
        }
      }

      Guest.prototype.email = '';
      Guest.prototype.createdAt = null;
      Guest.prototype.updatedAt = null;

      return Guest;
    })();

    v1.Cancellation = (() => {
      class Cancellation {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new Cancellation(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.canceledBy != null &&
            Object.hasOwnProperty.call(message, 'canceledBy')
          ) {
            writer.uint32(10).string(message.canceledBy);
          }
          if (
            message.reason != null &&
            Object.hasOwnProperty.call(message, 'reason')
          ) {
            writer.uint32(18).string(message.reason);
          }
          if (
            message.cancelerType != null &&
            Object.hasOwnProperty.call(message, 'cancelerType')
          ) {
            writer.uint32(26).string(message.cancelerType);
          }
          if (
            message.createdAt != null &&
            Object.hasOwnProperty.call(message, 'createdAt')
          ) {
            timing$1.v1.Timestamp.encode(
              message.createdAt,
              writer.uint32(34).fork()
            ).ldelim();
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new Cancellation();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.canceledBy = reader.string();
                break;
              }
              case 2: {
                message.reason = reader.string();
                break;
              }
              case 3: {
                message.cancelerType = reader.string();
                break;
              }
              case 4: {
                message.createdAt = timing$1.v1.Timestamp.decode(
                  reader,
                  reader.uint32()
                );
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.calendly.v1.Cancellation) {
            return object;
          }
          const message = new $root.calendly.v1.Cancellation();
          if (object.canceledBy != null) {
            message.canceledBy = String(object.canceledBy);
          }
          if (object.reason != null) {
            message.reason = String(object.reason);
          }
          if (object.cancelerType != null) {
            message.cancelerType = String(object.cancelerType);
          }
          if (object.createdAt != null) {
            if (typeof object.createdAt !== 'object') {
              throw new TypeError(
                '.calendly.v1.Cancellation.createdAt: object expected, but got ' +
                  typeof object.createdAt
              );
            }
            message.createdAt = timing$1.v1.Timestamp.fromObject(
              object.createdAt
            );
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.canceledBy = '';
            object.cancelerType = '';
            object.createdAt = null;
          }
          let keys;
          if (
            message.canceledBy != null &&
            message.hasOwnProperty('canceledBy')
          ) {
            object.canceledBy = message.canceledBy;
          }
          if (message.reason != null && message.hasOwnProperty('reason')) {
            object.reason = message.reason;
          }
          if (
            message.cancelerType != null &&
            message.hasOwnProperty('cancelerType')
          ) {
            object.cancelerType = message.cancelerType;
          }
          if (
            message.createdAt != null &&
            message.hasOwnProperty('createdAt')
          ) {
            object.createdAt = timing$1.v1.Timestamp.toObject(
              message.createdAt,
              options
            );
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'calendly.v1.Cancellation';
        }
      }

      Cancellation.prototype.canceledBy = '';
      Cancellation.prototype.reason = null;
      Cancellation.prototype.cancelerType = '';
      Cancellation.prototype.createdAt = null;

      return Cancellation;
    })();

    v1.CalendlyEventType = (function () {
      const valuesById = {};
      const values = Object.create(valuesById);
      values[(valuesById[0] = 'UNSPECIFIED_ILLEGAL_CALENDLY_EVENT_TYPE')] = 0;
      values[(valuesById[1] = 'CALENDLY_INVITEE_CREATED')] = 1;
      values[(valuesById[2] = 'CALENDLY_INVITEE_CANCELED')] = 2;
      values[(valuesById[3] = 'CALENDLY_INVITEE_NO_SHOW_CREATED')] = 3;
      values[(valuesById[4] = 'CALENDLY_INVITEE_NO_SHOW_DELETED')] = 4;
      values[(valuesById[5] = 'CALENDLY_ROUTING_FORM_SUBMISSION_CREATED')] = 5;
      return values;
    })();

    return v1;
  })();

  return calendly;
})();

export const calendly = $root.calendly;
