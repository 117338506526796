import { ActionItem } from './ActionList/types';
import { NodeType } from '../../../types';
import { defaultParameterMissingPolicy } from './defaultParameterMissingPolicy';
import { defaultRetryPolicy } from './defaultRetryPolicy';
import { healthie } from '@morf/proto/healthie_v1_ts_proto';
import { newNodeData } from './types';

export const addFetchActionNode = (
  action: ActionItem,
  isBranch: boolean
): newNodeData => {
  return {
    configuration: {
      type: action.application,
      retryPolicy: defaultRetryPolicy,
      parameterMissingPolicy: defaultParameterMissingPolicy,
      [action.application]: {
        type: action.action,
        [action.action]: {
          params: action.params,
          configs: action.configs,
          ...(action.application === 'healthieV1' && {
            healthieEnvironment: healthie.v1.HealthieEnvironment.SANDBOX,
          }),
        },
      },
    },
    integrationIconName: action.integrationIconName,
    isBranchNode: isBranch,
    name: action.name,
    type: NodeType.FetchActionNode,
  };
};
