import _ from 'lodash';
import { Button } from '../../Button';
import { Flexbox } from '../../Flexbox';
import { Fragment, memo, useState } from 'react';
import { SkeletonLoadable } from '../../Skeleton';
import { TableBodyProps } from './types';
import { TableRow } from './TableRow';
import { Text } from '../../Typography';

const Body: <RowData>(props: TableBodyProps<RowData>) => JSX.Element = ({
  cellBackgroundColor,
  cellHeight,
  cellHoveredBackgroundColor,
  cellHoveredBorderColor,
  cellSelectedBackgroundColor,
  cellSelectedBorderColor,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoading,
  numberOfColumns,
  onColumnClick,
  onRowClick,
  onRowHover,
  onRowRemove,
  onSubRowAdd,
  onSubRowClick,
  onSubRowRemove,
  parentCellHeight,
  rows,
  subCellHeight,
  subRowComponent,
}) => {
  const [hoveredRowId, setHoveredRowId] = useState<number | null>(null);

  const handleRowMouseEnter = (rowId: number) => {
    onRowHover && onRowHover(rowId);
    setHoveredRowId(rowId);
  };

  const handleRowMouseLeave = () => {
    onRowHover && onRowHover(null);
    setHoveredRowId(null);
  };

  return (
    <tbody data-testid='body-group'>
      {rows.map((row, index) => {
        const rowId = parseInt(row.id);
        const isSubRow = row.getParentRow();
        const isExpanded = row.getIsExpanded();
        const isHovered = hoveredRowId === rowId;
        const commonProps = {
          cellBackgroundColor,
          cellHeight,
          cellHoveredBackgroundColor,
          cellHoveredBorderColor,
          cellSelectedBackgroundColor,
          cellSelectedBorderColor,
          firstRowId: rows[0].id,
          isHovered,
          isLoading,
          onColumnClick,
          onMouseEnter: () => handleRowMouseEnter(rowId),
          onMouseLeave: handleRowMouseLeave,
          onRowClick,
          onRowRemove,
          onSubRowAdd,
          onSubRowClick,
          onSubRowRemove,
          parentCellHeight,
          row,
          subCellHeight,
        };

        return (
          <Fragment key={index}>
            {subRowComponent ? (
              <>
                {!isSubRow && <TableRow {...commonProps} />}
                {isExpanded && subRowComponent(row)}
              </>
            ) : (
              <TableRow {...commonProps} />
            )}
          </Fragment>
        );
      })}

      {fetchNextPage && hasNextPage ? (
        isFetching || isLoading ? (
          <tr>
            {Array.from({ length: numberOfColumns }).map((_, index) => (
              <td key={index}>
                <Flexbox
                  justifyContent='flex-start'
                  alignItems='center'
                  borderType='borderTop'
                  p={0.5}
                >
                  <SkeletonLoadable isLoading height='2rem' />
                </Flexbox>
              </td>
            ))}
          </tr>
        ) : (
          <tr>
            <td colSpan={numberOfColumns}>
              <Flexbox
                justifyContent='center'
                alignItems='center'
                borderType='borderTop'
                p={0.5}
              >
                <Button
                  size='base'
                  variant='primary'
                  onClick={fetchNextPage}
                  isDisabled={isFetching || isLoading}
                >
                  <Text tag='h5' color='inherit'>
                    Load More...
                  </Text>
                </Button>
              </Flexbox>
            </td>
          </tr>
        )
      ) : null}
    </tbody>
  );
};

export const TableBody = memo(Body) as typeof Body;
