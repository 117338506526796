import { Controller, FieldValues } from 'react-hook-form';
import { Dropdown } from '../Dropdown';
import { DropdownFieldProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { memo } from 'react';
import { useTheme } from '@morf/theming';

const DropdownField = <T extends FieldValues>({
  control,
  id,
  label,
  required,
  ...props
}: DropdownFieldProps<T>) => {
  const theme = useTheme();

  return (
    <Flexbox direction='column' gap={0.5} height='auto'>
      <Flexbox direction='row' justifyContent='flex-start' gap={0.25}>
        <Text tag='h5'>{label}</Text>
        {required && (
          <Text tag='h5' color={theme.colors.support.red.darkest}>
            *
          </Text>
        )}
      </Flexbox>

      <Controller
        name={id}
        control={control}
        rules={{ required: required }}
        render={({ field }) => {
          return (
            <Dropdown
              {...props}
              height='2.25rem'
              onChange={(selectedOptions) => {
                const selectedValues = selectedOptions.map(
                  (option) => option.value
                );
                field.onChange(selectedValues);
              }}
            />
          );
        }}
      />
    </Flexbox>
  );
};

export const MemoizedDropdownField = memo(
  DropdownField
) as typeof DropdownField;
