import { formatEventPayloadStubObjectType } from './formatEventPayloadStubObjectType';
import { formatSourceApplication } from '../../../../../apps/admin/components/helpers';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const formatTriggerName = (
  trigger: workflows.v1.Trigger | null | undefined
): string => {
  if (!trigger) {
    return '';
  }

  const { sourceApplication, eventPayloadStub } = trigger;

  const formattedEventPayloadStubObjectType =
    formatEventPayloadStubObjectType(eventPayloadStub);

  return `${formatSourceApplication(
    sourceApplication
  )} - ${formattedEventPayloadStubObjectType}`;
};
