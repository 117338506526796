import _ from 'lodash';
import { FC, memo, useLayoutEffect, useRef, useState } from 'react';
import { FilterChip } from './FilterChip';
import { FilterDropdown } from './FilterDropdown';
import { FilterOption } from './FilterDropdown/FilterOption/types';
import { FilterProps } from './types';
import { Flexbox } from '../Flexbox';
import { SkeletonLoadable } from '../Skeleton';
import { WrapperModal } from '../WrapperModal';
import { useUpdateUrlParam } from '../Hooks/useUpdateUrlParam';

const Filter: FC<FilterProps> = ({
  allOptions,
  isLoading,
  name,
  options,
  queryName,
  setIsFilterSelected,
  value,
}) => {
  const updateUrlParams = useUpdateUrlParam();
  const containerRef = useRef<HTMLDivElement>(null);

  const initialShowOptions = !isLoading && !options.length;
  const [showOptions, setShowOptions] = useState<boolean>(initialShowOptions);
  const [position, setPosition] = useState({
    top: '0rem',
    left: '0rem',
  });

  const size = _.size(options);

  let updatedValue;

  if (value) {
    updatedValue = value;
  } else if (size === 0) {
    updatedValue = '';
  } else if (size === 1) {
    updatedValue = options[0].name;
  } else {
    updatedValue = `${size} ${
      name.toLowerCase().endsWith('y')
        ? name.toLowerCase().slice(0, -1) + 'ies'
        : name.toLowerCase() + 's'
    }`;
  }

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleCloseOptions = () => setShowOptions(false);

  const handleApplyClick = (options: FilterOption[]) => {
    updateUrlParams({ [queryName]: options.map(({ id }) => id) });
    setShowOptions(false);
  };

  const handleResetClick = () => {
    updateUrlParams({ [queryName]: null });
    setShowOptions(false);
    setIsFilterSelected(false);
  };

  useLayoutEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top / 16 + 1.75 + 'rem',
        left: rect.left / 16 + 'rem',
      });
    }
  }, []);

  return (
    <Flexbox
      containerRef={containerRef}
      data-testid={_.toLower(name) + '-filter'}
      width='auto'
      height='auto'
    >
      {isLoading ? (
        <SkeletonLoadable isLoading width='5rem' height='1.125rem' />
      ) : (
        <>
          <FilterChip
            name={name}
            onClick={handleToggleOptions}
            showOptions={showOptions}
            value={updatedValue}
          />

          {showOptions && (
            <WrapperModal
              {...position}
              backdrop={{
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
              width='13.125rem'
              onClose={handleCloseOptions}
            >
              <FilterDropdown
                allOptions={allOptions}
                name={name}
                onApplyClick={handleApplyClick}
                onResetClick={handleResetClick}
                options={options}
              />
            </WrapperModal>
          )}
        </>
      )}
    </Flexbox>
  );
};

export const MemoizedFilter = memo(Filter);
