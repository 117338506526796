import { Container, Link } from '@morf/ui';
import { DocumentationIconProps } from './types';
import { FC, memo } from 'react';
import { Icon } from '@morf/ui/Icon';
import { Tooltip } from '@morf/ui/Tooltip';
import { useTheme } from '@morf/theming';

const DocumentationIcon: FC<DocumentationIconProps> = () => {
  const theme = useTheme();
  const documentationLink = 'https://docs.morf.health';

  return (
    <Link href={documentationLink} target='_blank'>
      <Tooltip tooltipText='Open Documentation' tooltipDirection='right'>
        <Container
          borderRadius={0.5}
          height='auto'
          hoverBackgroundColor={theme.colors.ui.body}
          p={0.5}
          width='auto'
        >
          <Icon
            name='help'
            stroke={theme.colors.main.dark.darkest}
            cursor='pointer'
          />
        </Container>
      </Tooltip>
    </Link>
  );
};

export const MemoizedDocumentationIcon = memo(DocumentationIcon);
