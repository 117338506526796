import { LocalStorage } from '@morf/ui';
import { MORF_USER } from '@morf/constants';
import { MorfUser } from '../../privateRoute/types';
import { decodeStringBase64 } from '../../helpers';

export const getDecodedUser = (): MorfUser | undefined => {
  const encodedUser = LocalStorage.get<string>(MORF_USER);

  if (!encodedUser) {
    return undefined;
  }

  try {
    const decodedString = decodeStringBase64(encodedUser);
    return JSON.parse(decodedString);
  } catch (error) {
    return undefined;
  }
};
