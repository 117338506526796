import axios, { AxiosResponse } from 'axios';
import {
  B2BSessionsRevokeRequest,
  B2BSessionsRevokeResponse,
} from 'stytch/types/lib/b2b/sessions';

export const revokeStytchSession = async (
  requestData: B2BSessionsRevokeRequest
): Promise<AxiosResponse<B2BSessionsRevokeResponse>> => {
  try {
    return await axios.post<B2BSessionsRevokeResponse>(
      '/api/revokeStytchSession',
      requestData
    );
  } catch (error) {
    throw new Error('revokeStytchSession: ' + error);
  }
};
