import { FC, memo } from 'react';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { WrapperModalHeaderProps } from './types';
import { useTheme } from '@morf/theming';
import { Icon } from '../Icon';

const WrapperModalHeader: FC<WrapperModalHeaderProps> = ({
  title,
  description,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Flexbox
      data-testid='wrapper-modal-header'
      direction='column'
      justifyContent='flex-start'
      alignItems='flex-start'
      height='auto'
      gap={0.5}
    >
      <Flexbox
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='auto'
      >
        <Text tag='h3'>{title}</Text>
        <Icon
          name='close'
          stroke={theme.colors.text.muted}
          onClick={onClose}
          cursor='pointer'
        />
      </Flexbox>
      <Text tag='p2'> {description}</Text>
    </Flexbox>
  );
};

export const MemoizedWrapperModalHeader = memo(WrapperModalHeader);
