import { CustomNodeLabel } from '../CustomNodeLabel';
import { FC, memo } from 'react';
import { FetchActionNodeProps } from './types';
import { Handle, Position } from 'reactflow';

const FetchActionNode: FC<FetchActionNodeProps> = ({ data, id }) => {
  const { integrationIconName, isRequired, name, status, title } = data.value;
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel
        id={id}
        integrationIconName={integrationIconName}
        isRequired={isRequired}
        name={name}
        status={status}
        title={title}
      />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedFetchActionNode = memo(FetchActionNode);
