import { CustomNodeLabel } from '../CustomNodeLabel';
import { FC, memo } from 'react';
import { Handle, Position } from 'reactflow';
import { ProfileUpdateNodeProps } from './types';

const ProfileUpdateNode: FC<ProfileUpdateNodeProps> = ({ data, id }) => {
  const { integrationIconName, isRequired, name, status, title } = data.value;
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel
        id={id}
        integrationIconName={integrationIconName}
        isRequired={isRequired}
        name={name}
        status={status}
        title={title}
      />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedProfileUpdateNode = memo(ProfileUpdateNode);
