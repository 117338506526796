import { NodeType } from '../types';
import { RestartNodeProps } from './types';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const restartNode: RestartNodeProps = {
  id: '1d35449d-2dc4-4dd2-a22f-5f137e8331ed',
  type: NodeType.RestartNode,
  data: {
    value: {
      configuration: new workflows.v1.WorkflowRestart({}),
      name: 'Restart Workflow',
      title: 'Restart workflow',
    },
  },
  position: { x: 0, y: 0 },
};
