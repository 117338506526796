'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.tiktok = (() => {
  const tiktok = {};
  tiktok.v1 = (() => {
    const v1 = {};
    v1.SendEventResponse = (() => {
      class SendEventResponse {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SendEventResponse(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.code != null &&
            Object.hasOwnProperty.call(message, 'code')
          ) {
            writer.uint32(8).int32(message.code);
          }
          if (
            message.message != null &&
            Object.hasOwnProperty.call(message, 'message')
          ) {
            writer.uint32(18).string(message.message);
          }
          if (
            message.requestId != null &&
            Object.hasOwnProperty.call(message, 'requestId')
          ) {
            writer.uint32(26).string(message.requestId);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SendEventResponse();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.code = reader.int32();
                break;
              }
              case 2: {
                message.message = reader.string();
                break;
              }
              case 3: {
                message.requestId = reader.string();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.tiktok.v1.SendEventResponse) {
            return object;
          }
          const message = new $root.tiktok.v1.SendEventResponse();
          if (object.code != null) {
            message.code = object.code | 0;
          }
          if (object.message != null) {
            message.message = String(object.message);
          }
          if (object.requestId != null) {
            message.requestId = String(object.requestId);
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.code = 0;
            object.message = '';
            object.requestId = '';
          }
          let keys;
          if (message.code != null && message.hasOwnProperty('code')) {
            object.code = message.code;
          }
          if (message.message != null && message.hasOwnProperty('message')) {
            object.message = message.message;
          }
          if (
            message.requestId != null &&
            message.hasOwnProperty('requestId')
          ) {
            object.requestId = message.requestId;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'tiktok.v1.SendEventResponse';
        }
      }

      SendEventResponse.prototype.code = 0;
      SendEventResponse.prototype.message = '';
      SendEventResponse.prototype.requestId = '';

      return SendEventResponse;
    })();

    return v1;
  })();

  return tiktok;
})();

export const tiktok = $root.tiktok;
