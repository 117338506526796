import { NodeType } from '../types';
import { ProfileConvertNodeProps } from './types';
import { defaultParameterMissingPolicy } from '../../CustomEdge/AddEdge/AddEdgeModal/defaultParameterMissingPolicy';
import { defaultRetryPolicy } from '../../CustomEdge/AddEdge/AddEdgeModal/defaultRetryPolicy';
import { destinationactions } from '@morf/proto/destinationactions/morf/v1_ts_proto';
import { workflows } from '@morf/proto/workflows_v1_ts_proto';

export const mockConvertProfileToPatientAction =
  new workflows.v1.WorkflowDestinationAction({
    retryPolicy: defaultRetryPolicy,
    parameterMissingPolicy: defaultParameterMissingPolicy,
    morfV1: new destinationactions.morf.v1.DestinationAction({
      convertProfileToPatient:
        new destinationactions.morf.v1.ConvertProfileToPatient({
          mergeIfEmailAddressAlreadyExists: true,
          mergeIfPhoneNumberAlreadyExists: false,
        }),
    }),
  });

export const profileConvertNode: ProfileConvertNodeProps = {
  id: '323e9a24-38dd-493c-9ec2-4f2f5c3be8b2',
  type: NodeType.ProfileConvertNode,
  data: {
    value: {
      configuration: mockConvertProfileToPatientAction,
      integrationIconName: 'morf',
      name: 'Convert Profile to Patient',
      title: 'Profile convert',
    },
  },
  position: { x: 0, y: 0 },
};
