'use strict';

import * as $protobuf from 'protobufjs/minimal';

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = {};
$root.source_application = (() => {
  const source_application = {};
  source_application.v2 = (() => {
    const v2 = {};
    v2.SourceApplication = (() => {
      class SourceApplication {
        constructor(properties) {
          if (properties) {
            for (let key of Object.keys(properties)) {
              if (properties[key] != null) this[key] = properties[key];
            }
          }
        }

        static create(properties) {
          return new SourceApplication(properties);
        }

        static encode(message, writer) {
          if (!writer) writer = $Writer.create();
          if (
            message.application != null &&
            Object.hasOwnProperty.call(message, 'application')
          ) {
            writer.uint32(8).int32(message.application);
          }
          return writer;
        }

        static decode(reader, length) {
          if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
          let end = length === undefined ? reader.len : reader.pos + length;
          let message = new SourceApplication();
          let key, value;
          while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
              case 1: {
                message.application = reader.int32();
                break;
              }
              default: {
                reader.skipType(tag & 7);
                break;
              }
            }
          }
          return message;
        }

        static fromObject(object) {
          if (object instanceof $root.source_application.v2.SourceApplication) {
            return object;
          }
          const message = new $root.source_application.v2.SourceApplication();
          if (object.application != null) {
            switch (object.application) {
              case 'UNSPECIFIED_SOURCE_APPLICATION':
              case 0: {
                message.application = 0;
                break;
              }
              case 'FORMSORT':
              case 1: {
                message.application = 1;
                break;
              }
              case 'HEALTHIE':
              case 2: {
                message.application = 2;
                break;
              }
              case 'AXLE_HEALTH':
              case 3: {
                message.application = 3;
                break;
              }
              case 'BUTTERFLY_LABS':
              case 4: {
                message.application = 4;
                break;
              }
              case 'MORF':
              case 5: {
                message.application = 5;
                break;
              }
              case 'PUSH_JSON':
              case 6: {
                message.application = 6;
                break;
              }
              case 'ACUITY':
              case 7: {
                message.application = 7;
                break;
              }
              case 'VITAL':
              case 8: {
                message.application = 8;
                break;
              }
              case 'SEGMENT':
              case 9: {
                message.application = 9;
                break;
              }
              case 'ACTIVE_CAMPAIGN':
              case 10: {
                message.application = 10;
                break;
              }
              case 'INTAKEQ':
              case 11: {
                message.application = 11;
                break;
              }
              case 'CUSTOMER_IO':
              case 12: {
                message.application = 12;
                break;
              }
              case 'HUBSPOT':
              case 13: {
                message.application = 13;
                break;
              }
              case 'CUSTOMER':
              case 14: {
                message.application = 14;
                break;
              }
              case 'CALENDLY':
              case 15: {
                message.application = 15;
                break;
              }
              default: {
                if (typeof object.application == 'number') {
                  message.application = object.application;
                  break;
                }
                break;
              }
            }
          }
          return message;
        }

        static toObject(message, options = {}) {
          const object = {};
          if (options.arrays || options.defaults) {
          }
          if (options.objects || options.defaults) {
          }
          if (options.defaults) {
            object.application =
              options.enums === String ? 'UNSPECIFIED_SOURCE_APPLICATION' : 0;
          }
          let keys;
          if (
            message.application != null &&
            message.hasOwnProperty('application')
          ) {
            object.application =
              options.enums === String
                ? $root.source_application.v2.SourceApplication.Application[
                    message.application
                  ] === undefined
                  ? message.application
                  : $root.source_application.v2.SourceApplication.Application[
                      message.application
                    ]
                : message.application;
          }
          return object;
        }

        static getTypeUrl(typeUrlPrefix = 'type.googleapis.com') {
          return typeUrlPrefix + 'source_application.v2.SourceApplication';
        }
      }

      SourceApplication.prototype.application = 0;
      SourceApplication.Application = (function () {
        const valuesById = {};
        const values = Object.create(valuesById);
        values[(valuesById[0] = 'UNSPECIFIED_SOURCE_APPLICATION')] = 0;
        values[(valuesById[1] = 'FORMSORT')] = 1;
        values[(valuesById[2] = 'HEALTHIE')] = 2;
        values[(valuesById[3] = 'AXLE_HEALTH')] = 3;
        values[(valuesById[4] = 'BUTTERFLY_LABS')] = 4;
        values[(valuesById[5] = 'MORF')] = 5;
        values[(valuesById[6] = 'PUSH_JSON')] = 6;
        values[(valuesById[7] = 'ACUITY')] = 7;
        values[(valuesById[8] = 'VITAL')] = 8;
        values[(valuesById[9] = 'SEGMENT')] = 9;
        values[(valuesById[10] = 'ACTIVE_CAMPAIGN')] = 10;
        values[(valuesById[11] = 'INTAKEQ')] = 11;
        values[(valuesById[12] = 'CUSTOMER_IO')] = 12;
        values[(valuesById[13] = 'HUBSPOT')] = 13;
        values[(valuesById[14] = 'CUSTOMER')] = 14;
        values[(valuesById[15] = 'CALENDLY')] = 15;
        return values;
      })();

      return SourceApplication;
    })();

    return v2;
  })();

  return source_application;
})();

export const source_application = $root.source_application;
