import rpcService from '@morf/server/browser-server';
import { FC } from 'react';
import { PermissionsContext } from './PermissionsContext';
import { PermissionsProviderProps } from './types';
import { getDecodedUser } from './getDecodedUser';
import { isMorfInternalUser } from './isMorfInternalUser';
import { organization } from '@morf/proto/organization_v1_ts_proto';
import { useQuery } from '@tanstack/react-query';

export const PermissionsProvider: FC<PermissionsProviderProps> = ({
  children,
  organizationId,
}) => {
  const user = getDecodedUser();
  const isMorfUser = isMorfInternalUser(user);
  const organizationPermission = user?.organizationPermissions?.find(
    (o) => o.organization.id === organizationId
  );

  const { data } = useQuery<organization.v1.ListAllowedRpcsResponse>(
    ['/organization.v1.OrganizationService/ListAllowedRpcs', organizationId],
    async () => {
      return await rpcService.organizationV1Service.listAllowedRpcs({
        stub: '',
      });
    }
  );
  const hasRpcPermission = (names: string[]): boolean => {
    return names.every((name) => data?.allowedRpcs?.includes(name));
  };

  const canCreateThirdPartySecret = hasRpcPermission([
    '/organization.v1.OrganizationService/CreateThirdPartySecret',
  ]);

  const canCreateUser = hasRpcPermission([
    '/accounts.v1.AccountsService/CreateUser',
  ]);

  const canCreateWorkflow = hasRpcPermission([
    '/workflows.v1.WorkflowsService/Create',
  ]);

  const canEditWorkflow = hasRpcPermission([
    '/workflows.v1.WorkflowsService/Update',
    '/workflows.v1.WorkflowsService/UpdateWorkflowVersion',
    '/workflows.v1.WorkflowsService/UpdateWorkflowVersionNode',
  ]);

  const canUpdateUser = hasRpcPermission([
    '/accounts.v1.AccountsService/UpdateUser',
  ]);

  const canUpsertProvider = hasRpcPermission([
    '/scheduling.v1.SchedulingService/UpsertProvider',
  ]);

  const canDeleteProvider = hasRpcPermission([
    '/scheduling.v1.SchedulingService/DeleteProvider',
  ]);

  const canRetryEventSnapshot = hasRpcPermission([
    '/workflows.v1.WorkflowsService/RetryEventSnapshot',
  ]);

  const canRetryWorkflowExecution = hasRpcPermission([
    '/workflows.v1.WorkflowsService/RetryWorkflowExecution',
  ]);

  const canMerge = hasRpcPermission(['/profiles.v1.ProfilesService/Merge']);

  const canAssociateThirdPartyIds = hasRpcPermission([
    '/profiles.v1.ProfilesService/AssociateThirdPartyIds',
  ]);

  const canConvertToPatient = hasRpcPermission([
    '/profiles.v1.ProfilesService/ConvertToPatient',
  ]);

  const canMakeProfileIndexActive = hasRpcPermission([
    '/profiles.v1.ProfilesService/MakeProfileIndexActive',
  ]);

  return (
    <PermissionsContext.Provider
      value={{
        canAssociateThirdPartyIds,
        canConvertToPatient,
        canCreateThirdPartySecret,
        canCreateUser,
        canCreateWorkflow,
        canDeleteProvider,
        canEditWorkflow,
        canMakeProfileIndexActive,
        canMerge,
        canRetryEventSnapshot,
        canRetryWorkflowExecution,
        canUpdateUser,
        canUpsertProvider,
        isMorfInternalUser: isMorfUser,
        organizationPermission,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};
