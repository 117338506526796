import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import { FC, Fragment, memo, useEffect } from 'react';
import { Layout } from './Layout';
import { PrivateRouteProps } from './types';
import { STYTCH_SESSION_JWT } from '@morf/constants';
import { getDecodedUser } from '../context/permissions/getDecodedUser';
import { useRouter } from 'next/router';

const PrivateRoute: FC<PrivateRouteProps> = ({ children, organizationId }) => {
  const router = useRouter();
  const morfUser = getDecodedUser();
  const { asPath } = router;
  const redirectPath = morfUser ? '/sessionExpired' : '/login';
  const stytch_session_jwt = Cookies.get(STYTCH_SESSION_JWT);

  useEffect(() => {
    if (!stytch_session_jwt || !morfUser) {
      router.push({
        pathname: redirectPath,
        query: {
          from: asPath,
        },
      });
    }
  }, [stytch_session_jwt, morfUser, router]);

  useEffect(() => {
    if (morfUser) {
      Sentry.setUser({ id: morfUser.id, email: morfUser.email });
    }
  }, [morfUser]);

  return (
    <Fragment>
      {stytch_session_jwt && morfUser && (
        <Layout
          organizationPermissions={morfUser.organizationPermissions}
          userImage={morfUser.profilePictureUrl}
          organizationId={organizationId}
        >
          {children}
        </Layout>
      )}
    </Fragment>
  );
};

export const MemoizedPrivateRoute = memo(PrivateRoute);
