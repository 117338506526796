import { ReactNode, useEffect, useState } from 'react';
import { WorkflowContext } from './workflowContext';
import { WorkflowContextProps } from './types';
import { useRouter } from 'next/router';
import { useUrlParams } from '../../../components/helpers/useUrlParams';

export const WorkflowProvider = (props: { children: ReactNode }) => {
  const router = useRouter();
  const { panelNodeId } = useUrlParams();

  const [nodes, setNodes] = useState<WorkflowContextProps['nodes']>([]);
  const [edges, setEdges] = useState<WorkflowContextProps['edges']>([]);
  const [selectedNode, setSelectedNode] =
    useState<WorkflowContextProps['selectedNode']>();
  const [destinationActions, setDestinationActions] = useState<
    WorkflowContextProps['destinationActions']
  >([]);
  const [fetchActions, setFetchActions] = useState<
    WorkflowContextProps['fetchActions']
  >([]);
  const [defaultProperties, setDefaultProperties] = useState<
    WorkflowContextProps['defaultProperties']
  >({});
  const [customProperties, setCustomProperties] = useState<
    WorkflowContextProps['customProperties']
  >({});
  const [customPropertySchemas, setCustomPropertySchemas] = useState<
    WorkflowContextProps['customPropertySchemas']
  >([]);

  useEffect(() => {
    const nodeMatchingPanelNodeId = nodes.find(({ id }) => id === panelNodeId);
    setSelectedNode(nodeMatchingPanelNodeId);
  }, [nodes, panelNodeId, router]);

  const value = {
    nodes,
    edges,
    setNodes,
    setEdges,
    selectedNode,
    setSelectedNode,
    destinationActions,
    setDestinationActions,
    fetchActions,
    setFetchActions,
    defaultProperties,
    setDefaultProperties,
    customProperties,
    setCustomProperties,
    customPropertySchemas,
    setCustomPropertySchemas,
  };

  return (
    <WorkflowContext.Provider value={value}>
      {props.children}
    </WorkflowContext.Provider>
  );
};
