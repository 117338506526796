import { FC } from 'react';
import { InfoBlockProps } from './types';
import { Flexbox } from '../Flexbox';
import { Text } from '../Typography';
import { useTheme } from '@morf/theming';
import { Container } from '../Container';
import { CopyToClipboard } from '../CopyToClipboard';
import { SkeletonLoadable } from '../Skeleton';

export const InfoBlock: FC<InfoBlockProps> = ({
  children,
  copyIcon = false,
  copyText,
  direction = 'row',
  isLoading = false,
  label,
}) => {
  const theme = useTheme();
  const width = direction === 'row' ? '50%' : '100%';
  return (
    <Flexbox
      data-testid='info-block'
      direction={direction}
      gap={0.25}
      height='auto'
      maxHeight='auto'
      backgroundColor={theme.colors.ui.card}
    >
      <Container width={width} height='auto'>
        <Text tag='p2' color={theme.colors.text.muted} whiteSpace='nowrap'>
          {label}
        </Text>
      </Container>
      <SkeletonLoadable isLoading={isLoading} height='1.5rem'>
        <Container width={width} height='auto'>
          <Flexbox direction='row' gap={0}>
            <Container width={copyIcon ? '100% - 2rem' : '100%'}>
              {children}
            </Container>
            {copyIcon && copyText && (
              <CopyToClipboard
                label={`Copy ${label.toLowerCase()} to clipboard`}
                text={copyText}
                tooltipDirection='left'
                tooltipWidth='8rem'
              />
            )}
          </Flexbox>
        </Container>
      </SkeletonLoadable>
    </Flexbox>
  );
};
