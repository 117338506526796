export const Morf = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 21.5495L8 18.4495C8 17.4429 8.775 16.6232 9.728 16.6232L14.456 16.6232L12.091 12.2938C11.626 11.415 11.91 10.3007 12.725 9.7979L15.272 8.2463C15.67 8.00125 16.138 7.93577 16.587 8.06463C17.03 8.19243 17.402 8.49451 17.634 8.91595L20 13.2401L22.364 8.91912C22.598 8.49557 22.97 8.19349 23.413 8.06568C23.861 7.93683 24.33 8.00231 24.731 8.24947L27.271 9.79684C28.088 10.3017 28.374 11.4182 27.912 12.2874L25.543 16.6243L30.272 16.6243C31.225 16.6243 32 17.4439 32 18.4505L32 21.5505C32 22.5571 31.225 23.3768 30.272 23.3768L25.544 23.3768L27.909 27.7062C28.375 28.5818 28.09 29.6993 27.275 30.2021L24.728 31.7537C24.33 31.9987 23.862 32.0642 23.413 31.9354C22.97 31.8076 22.598 31.5055 22.366 31.0841L20 26.7599L17.636 31.0809C17.402 31.5044 17.03 31.8065 16.587 31.9343C16.138 32.0632 15.67 31.9987 15.269 31.7505L12.728 30.2032C11.91 29.6983 11.624 28.5829 12.087 27.7126L14.456 23.3757L9.728 23.3757C8.775 23.3757 8 22.5561 8 21.5495Z'
        fill='#3C4CEC'
      />
    </svg>
  );
};

export const MorfBlack = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 21.5495L8 18.4495C8 17.4429 8.775 16.6232 9.728 16.6232L14.456 16.6232L12.091 12.2938C11.626 11.415 11.91 10.3007 12.725 9.7979L15.272 8.2463C15.67 8.00125 16.138 7.93577 16.587 8.06463C17.03 8.19243 17.402 8.49451 17.634 8.91595L20 13.2401L22.364 8.91912C22.598 8.49557 22.97 8.19349 23.413 8.06568C23.861 7.93683 24.33 8.00231 24.731 8.24947L27.271 9.79684C28.088 10.3017 28.374 11.4182 27.912 12.2874L25.543 16.6243L30.272 16.6243C31.225 16.6243 32 17.4439 32 18.4505L32 21.5505C32 22.5571 31.225 23.3768 30.272 23.3768L25.544 23.3768L27.909 27.7062C28.375 28.5818 28.09 29.6993 27.275 30.2021L24.728 31.7537C24.33 31.9987 23.862 32.0642 23.413 31.9354C22.97 31.8076 22.598 31.5055 22.366 31.0841L20 26.7599L17.636 31.0809C17.402 31.5044 17.03 31.8065 16.587 31.9343C16.138 32.0632 15.67 31.9987 15.269 31.7505L12.728 30.2032C11.91 29.6983 11.624 28.5829 12.087 27.7126L14.456 23.3757L9.728 23.3757C8.775 23.3757 8 22.5561 8 21.5495Z'
        fill='#000000'
      />
    </svg>
  );
};

export const MorfLavender = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 21.5495L8 18.4495C8 17.4429 8.775 16.6232 9.728 16.6232L14.456 16.6232L12.091 12.2938C11.626 11.415 11.91 10.3007 12.725 9.7979L15.272 8.2463C15.67 8.00125 16.138 7.93577 16.587 8.06463C17.03 8.19243 17.402 8.49451 17.634 8.91595L20 13.2401L22.364 8.91912C22.598 8.49557 22.97 8.19349 23.413 8.06568C23.861 7.93683 24.33 8.00231 24.731 8.24947L27.271 9.79684C28.088 10.3017 28.374 11.4182 27.912 12.2874L25.543 16.6243L30.272 16.6243C31.225 16.6243 32 17.4439 32 18.4505L32 21.5505C32 22.5571 31.225 23.3768 30.272 23.3768L25.544 23.3768L27.909 27.7062C28.375 28.5818 28.09 29.6993 27.275 30.2021L24.728 31.7537C24.33 31.9987 23.862 32.0642 23.413 31.9354C22.97 31.8076 22.598 31.5055 22.366 31.0841L20 26.7599L17.636 31.0809C17.402 31.5044 17.03 31.8065 16.587 31.9343C16.138 32.0632 15.67 31.9987 15.269 31.7505L12.728 30.2032C11.91 29.6983 11.624 28.5829 12.087 27.7126L14.456 23.3757L9.728 23.3757C8.775 23.3757 8 22.5561 8 21.5495Z'
        fill='#823BEC'
      />
    </svg>
  );
};

export const MorfGreen = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 21.5495L8 18.4495C8 17.4429 8.775 16.6232 9.728 16.6232L14.456 16.6232L12.091 12.2938C11.626 11.415 11.91 10.3007 12.725 9.7979L15.272 8.2463C15.67 8.00125 16.138 7.93577 16.587 8.06463C17.03 8.19243 17.402 8.49451 17.634 8.91595L20 13.2401L22.364 8.91912C22.598 8.49557 22.97 8.19349 23.413 8.06568C23.861 7.93683 24.33 8.00231 24.731 8.24947L27.271 9.79684C28.088 10.3017 28.374 11.4182 27.912 12.2874L25.543 16.6243L30.272 16.6243C31.225 16.6243 32 17.4439 32 18.4505L32 21.5505C32 22.5571 31.225 23.3768 30.272 23.3768L25.544 23.3768L27.909 27.7062C28.375 28.5818 28.09 29.6993 27.275 30.2021L24.728 31.7537C24.33 31.9987 23.862 32.0642 23.413 31.9354C22.97 31.8076 22.598 31.5055 22.366 31.0841L20 26.7599L17.636 31.0809C17.402 31.5044 17.03 31.8065 16.587 31.9343C16.138 32.0632 15.67 31.9987 15.269 31.7505L12.728 30.2032C11.91 29.6983 11.624 28.5829 12.087 27.7126L14.456 23.3757L9.728 23.3757C8.775 23.3757 8 22.5561 8 21.5495Z'
        fill='#00AA7B'
      />
    </svg>
  );
};
