import { CustomNodeLabel } from '../CustomNodeLabel';
import { DestinationActionNodeProps } from './types';
import { FC, memo } from 'react';
import { Handle, Position } from 'reactflow';

const DestinationActionNode: FC<DestinationActionNodeProps> = ({
  data,
  id,
}) => {
  const { integrationIconName, isRequired, name, status, title } = data.value;
  return (
    <>
      <Handle type='target' position={Position.Top} />
      <CustomNodeLabel
        id={id}
        integrationIconName={integrationIconName}
        isRequired={isRequired}
        name={name}
        status={status}
        title={title}
      />
      <Handle type='source' position={Position.Bottom} />
    </>
  );
};

export const MemoizedDestinationActionNode = memo(DestinationActionNode);
