import { toast } from 'react-toastify';
import { Toast } from '../Toast';

export const copyTextToClipboard = (copyText: string) => {
  navigator.clipboard
    .writeText(copyText)
    .then(() => {
      toast(
        <Toast
          message='Copied successfully!'
          icon='check-circle'
          type='success'
        />
      );
    })
    .catch((_error) => {
      toast(
        <Toast
          message='Failed to copy to clipboard!'
          icon='x-circle'
          type='alert'
        />
      );
    });
};
