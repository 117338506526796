import { LeadLookupConfiguration } from './ProfileLookupSubflow/mockData';
import { NodeType } from '../types';
import { ProfileLookupNodeProps } from './types';

export const profileLookupNode: ProfileLookupNodeProps = {
  id: 'profile-lookup-node',
  type: NodeType.ProfileLookupNode,
  data: {
    value: {
      name: 'Profile Lookup',
      configuration: LeadLookupConfiguration,
      title: 'Profile lookup',
    },
  },
  position: { x: 0, y: 0 },
};
