export const Freshpaint = () => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1355 22.9412V17.2337C10.1355 16.9435 10.3715 16.7113 10.6593 16.7113H17.103C17.394 16.7113 17.6268 16.9467 17.6268 17.2337V22.9412C17.6268 23.2314 17.3907 23.4636 17.103 23.4636H10.6593C10.3683 23.4636 10.1355 23.2282 10.1355 22.9412Z'
        fill='#EE5757'
      />
      <path
        d='M17.63 27.1041V32.9535C17.63 33.2405 17.3972 33.4758 17.1094 33.4758L10.6657 33.5339C10.3748 33.5371 10.1355 33.3017 10.1355 33.0115V27.1009C10.1355 26.8107 10.3715 26.5785 10.6593 26.5785H17.103C17.394 26.5817 17.63 26.8171 17.63 27.1041Z'
        fill='#61DABF'
      />
      <path
        d='M30.0001 7.2214C30.0001 6.93119 29.7641 6.69902 29.4763 6.69902H28.3609H20.9504H17.63H10.6593C10.3683 6.69902 10.1355 6.93441 10.1355 7.2214V13.0675C10.1355 13.3578 10.3715 13.5899 10.6593 13.5899H20.9504H29.4731C29.7641 13.5899 29.9969 13.3545 29.9969 13.0675V7.2214H30.0001Z'
        fill='#EE5757'
      />
      <path
        d='M30.0001 17.2337C30.0001 16.9435 29.7641 16.7113 29.4763 16.7113H28.3609H21.4742C21.1832 16.7113 20.9504 16.9467 20.9504 17.2337V22.9412C20.9504 23.2314 21.1865 23.4636 21.4742 23.4636H28.3576H29.4731C29.7641 23.4636 29.9968 23.2282 29.9968 22.9412V17.2337H30.0001Z'
        fill='#61DABF'
      />
    </svg>
  );
};
