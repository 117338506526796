import { FC, memo } from 'react';
import { HighlightTextProps } from './types';
import { Text } from '..';
import { getHighlightedText } from './getHighlightedText';
import { useTheme } from '@morf/theming';

const HighlightText: FC<HighlightTextProps> = ({
  children,
  highlightWords,
  ...props
}) => {
  const theme = useTheme();
  const style = { backgroundColor: theme.colors.main.light.normal };

  return (
    <Text {...props}>
      {highlightWords.length && typeof children === 'string'
        ? getHighlightedText(children, highlightWords, style)
        : children}
    </Text>
  );
};

export const MemoizedHighlightText = memo(HighlightText);
