import '../public/fonts/fonts.css';
import 'allotment/dist/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'reactflow/dist/base.css';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import FontPreload from '../lib/font-preload';
import { AppProps } from 'next/app';
import { DevTools } from '@/components/shared/DevTools';
import { ErrorBoundary } from '@morf/ui';
import { MORF_ORG_ID } from '@morf/constants';
import { PermissionsProvider } from '../components/context/permissions/PermissionsProvider';
import { PrivateRoute } from '../components/privateRoute';
import { StytchProvider } from '@stytch/nextjs';
import { TimezoneProvider } from '../components/context/timezone/timezoneProvider';
import { ToastContainer } from 'react-toastify';
import { WorkflowProvider } from '../components/context/workflow/workflowProvider';
import { createStytchUIClient } from '@stytch/nextjs/ui';
import { morf, ThemeContainer } from '@morf/theming';
import { mutationCache } from '@/lib/mutationCache';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUrlParams } from '@/components/helpers/useUrlParams';
import { useVisibilityChange } from '@/components/helpers/useVisibilityChange';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const publicRoutes = [
  '/authenticate',
  '/login',
  '/sessionExpired',
  '/unauthorized',
];

const stytchToken = process.env.NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN;
if (!stytchToken) {
  throw new Error(
    'Missing NEXT_PUBLIC_STYTCH_PUBLIC_TOKEN environment variable'
  );
}
const stytch = createStytchUIClient(stytchToken);

export default function AdminApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { organizationId } = useUrlParams();
  const { pathname } = router;
  const isPublicRoute = publicRoutes.includes(pathname);

  useVisibilityChange(organizationId);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        mutationCache: mutationCache,
        defaultOptions: {
          queries: {
            keepPreviousData: true,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
          },
        },
      })
  );

  useEffect(() => {
    const morf_organization_id = Cookies.get(MORF_ORG_ID);
    if (!isPublicRoute && !pathname.includes('organizations')) {
      if (morf_organization_id) {
        router.push(`/organizations/${morf_organization_id}`);
      } else {
        router.push('/login');
      }
    }
  }, []);

  return (
    <StytchProvider stytch={stytch}>
      <div id='modal'></div>
      <ThemeContainer theme={morf}>
        <WorkflowProvider>
          <TimezoneProvider>
            <QueryClientProvider client={queryClient}>
              <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                position='top-center'
              />
              <FontPreload />
              <div id='admin'>
                {isPublicRoute ? (
                  <Component {...pageProps} />
                ) : (
                  organizationId && (
                    <PermissionsProvider organizationId={organizationId}>
                      <DevTools />
                      <PrivateRoute organizationId={organizationId}>
                        <ErrorBoundary logError={Sentry.captureException}>
                          <Component {...pageProps} />
                        </ErrorBoundary>
                      </PrivateRoute>
                    </PermissionsProvider>
                  )
                )}
              </div>
            </QueryClientProvider>
          </TimezoneProvider>
        </WorkflowProvider>
      </ThemeContainer>
    </StytchProvider>
  );
}
